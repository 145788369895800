import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function Users() {
  const colData = {
    widths: [0, 210, 210, 210, 70],
    names: ['ID', 'Name', 'Email', 'Application Role', 'Active']
  };

  // dropdown options
  const [roleOptions, setRoleOptions] = useState([]);
  // collection of API endpoints, property names, data, setData, and whether to fetch only by selected project for each dropdown
  const dropdowns = [['Roles', 'role', roleOptions, setRoleOptions, false]];

  return (
    <div id='Users'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Users</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'UserSystemRoles'}
        colData={colData}
        editPermission={'User.Edit'}
        dataSchema={{
          isActive: false
        }}
        dropdowns={dropdowns}
        addRemoveRows={false}
        nullProperties={['user']}
      >
        <HotColumn data={'id'} className='htLeft' />
        <HotColumn data={'user.displayName'} readOnly={true} className='htLeft' />
        <HotColumn data={'user.mail'} readOnly={true} className='htLeft' />
        <HotColumn
          data={'role.name'}
          type='dropdown'
          source={roleOptions.filter((o) => o.isActive && !o.isProjectSpecific && o.isAssignable).map((o) => o.name)}
          className='htLeft'
        />
        <HotColumn data={'isActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default Users;
