import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function NotificationSettings() {
  const colData = {
    widths: [0, 140, 260, 180, 210, 140],
    names: ['ID', 'Project', 'Project Administrator(s)', 'Role', 'Category', 'Notifications Active']
  };

  return (
    <div id='NotificationSettings'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Notification Settings</h3>
        </div>
      </div>
      <RGHotTable endpoint={'ProjectUsers/MyNotificationSettings'} colData={colData} isEditable={false}>
        <HotColumn data={'id'} />
        <HotColumn data={'projectShortName'} className='htLeft' />
        <HotColumn data={'projectAdmins'} className='htLeft' />
        <HotColumn data={'role'} className='htLeft' />
        <HotColumn data={'category'} className='htLeft' />
        <HotColumn data={'isNotificationActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default NotificationSettings;
