import React, { useEffect, useState, useCallback } from 'react';
import * as rax from 'retry-axios';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest, timeoutSetting } from '../authConfig';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { LoadingCard } from './LoadingCard';

export const LabelledInput = ({
  input: { set, value },
  width,
  type = 'textbox', // 'textbox', 'textarea', 'dropdown', 'date'
  label, // label shown to user
  bold, // whether to bold the value shown (textbox only)
  options = null, // (dropdown) for dropdowns with manually given data, instead of fetching from API
  endpoint = null, // (dropdown) which endpoint to fetch from
  field = 'id', // (dropdown) which field to show the user for each result
  validOnly = false, // adds /Valid to end of API call
  projectId = null, // adds /Project/{projectId} to end of API call - unless validOnly is true, then filters results by projectId
  waitForProjectId = false, // required if this element will be loaded before projectId is populated
  locked = false, // read-only input
  activeOnly = false, // filters to only records with isActive = true
  jobId = null, // filter results by this jobId
  categoryId = null, // adds /{categoryId} to the end of API call
  isAdminPageFilter = false, // adds logic to strikethrough inactive JDE projects & jobs and shows a title message if inactive
  mini = false // combines label and input (using placeholder)
}) => {
  const { instance, accounts } = useMsal();

  const isFetchable = endpoint != null;

  const [list, setList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  // attach retry-axios interceptor to axios (retries 3x by default)
  rax.attach();

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  const fetchList = useCallback(async () => {
    console.log('Get request sent for ' + endpoint);

    let token = await getToken();

    await axios
      .get(
        process.env.REACT_APP_API_URL +
          endpoint +
          (validOnly ? '/Valid' : projectId ? '/Project/' + projectId : categoryId ? '/' + categoryId : ''),
        {
          raxConfig: {
            retry: 5,
            shouldResetTimeout: true
          },
          timeout: timeoutSetting,
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
          }
        }
      )
      .then((resp) => {
        console.log(endpoint + ' loaded: ', resp.data);
        let tempData = resp.data;
        if (validOnly && projectId) {
          // filter based on projectId
          tempData = tempData.filter((r) => r.projectId === projectId);
        }

        if (jobId) {
          // filter based on jobId
          tempData = tempData.filter((r) => r.jobId === jobId);
        }
        
        setList(tempData === "" ? [] : tempData);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [endpoint, getToken, validOnly, projectId, categoryId, jobId]);
  useEffect(() => {
    if (!isLoaded && isFetchable && !(waitForProjectId && !projectId)) {
      fetchList();
    } else if (!isFetchable) {
      setList(options);
      setIsLoaded(true);
    }
  }, [fetchList, isFetchable, isLoaded, options, projectId, waitForProjectId]);

  // force a refetch if project ID, job ID, or category ID changes
  useEffect(() => {
    if (!isFetchable || !isLoaded) return;

    set({});
    setList([]);
    setIsLoaded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchable, projectId, jobId, categoryId, set]);

  // display a different field in dropdown list vs displayed value's field
  const priorityItemRender = (li, itemProps) => {
    return React.cloneElement(li, li.props, itemProps.dataItem.description);
  };

  switch (type) {
    case 'textbox':
      return (
        <>
          <div className={!mini ? 'label-container' : ''} style={width && { width: width, minWidth: 'unset' }}>
            {!mini && <div className={'label'}>{label}</div>}
            <div>
              {value && value.NotLoaded ? (
                <LoadingCard mini={true} />
              ) : (
                <Input
                  className={locked ? 'input-locked' : ''}
                  style={bold ? { backgroundColor: '#e4e4e4', fontWeight: 'bold', color: 'black' } : null}
                  value={value ?? ''}
                  onChange={(e) => set(e.value === '' ? null : e.value)}
                  readOnly={locked}
                  placeholder={mini ? label : undefined}
                />
              )}
            </div>
          </div>
        </>
      );
    case 'textarea':
      return (
        <>
          <div className={!mini ? 'label-comment-container' : ''} style={width && { width: width, minWidth: 'unset' }}>
            {!mini && <div className={'label comment-label'}>{label}</div>}
            {value && value.NotLoaded ? (
              <LoadingCard mini={true} />
            ) : (
              <TextArea
                className={locked ? 'input-locked' : ''}
                value={value ?? ''}
                onChange={(e) => set(e.value === '' ? null : e.value)}
                rows={2}
                autoSize={true}
                readOnly={locked}
                placeholder={mini ? label : undefined}
              />
            )}
          </div>
        </>
      );
    case 'dropdown':
      let inactiveJDE =
        isAdminPageFilter && (value?.jdeProject?.isActive === false || value?.jdeJob?.isActive === false);

      return (
        <div
          className={!mini ? 'label-container' : ''}
          style={width ? { height: 30, width: width, minWidth: 'unset' } : { height: 30 }}
        >
          {!mini && <div className={'label'}>{label}</div>}
          <div>
            {!isLoaded || (value && value.NotLoaded) ? (
              <LoadingCard mini={true} />
            ) : (
              <DropDownList
                className={(inactiveJDE ? 'inactive ' : undefined) + (locked ? 'input-locked' : '')}
                title={
                  inactiveJDE
                    ? endpoint === 'Projects'
                      ? 'JDE Project is inactive'
                      : endpoint === 'Jobs'
                      ? 'JDE Job is inactive'
                      : null
                    : undefined
                }
                data={activeOnly ? list.filter((o) => o.isActive) : list}
                textField={options ? null : field}
                value={value}
                onChange={(e) => set(e.target.value)}
                readOnly={locked}
                defaultItem={mini && label}
                {...(endpoint === 'Priorities' && { itemRender: priorityItemRender })}
              />
            )}
          </div>
        </div>
      );
    case 'date':
      return (
        <>
          <div className={!mini ? 'label-container' : ''} style={width && { width: width, minWidth: 'unset' }}>
            <div className={'label'}>{label}</div>
            <div>
              {value && value.NotLoaded ? (
                <LoadingCard mini={true} />
              ) : (
                <DatePicker
                  className={locked ? 'input-locked' : ''}
                  value={
                    value ? new Date(new Date(value).getTime() + new Date(value).getTimezoneOffset() * 60000) : null
                  } // normalizes timezone - we don't care about time, just date
                  format={'yyyy-MM-dd'}
                  onChange={(e) =>
                    set(
                      e.value.getFullYear() +
                        '-' +
                        String(e.value.getMonth() + 1).padStart(2, '0') +
                        '-' +
                        String(e.value.getDate()).padStart(2, 0)
                    )
                  }
                  readOnly={locked}
                />
              )}
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};
