import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';
import { LabelledInput } from '../components/LabelledInput';

function ProjectsCategories() {
  const [selectedProject, setSelectedProject] = useState({});

  const colData = {
    widths: [0, 260, 70],
    names: ['ID', 'Category', 'Active']
  };

  return (
    <div id='ProjectsCategories'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects & Categories</h3>
        </div>
      </div>
      <div className='card-container'>
        <div className='card-component'>
          <LabelledInput
            type={'dropdown'}
            input={{ set: setSelectedProject, value: selectedProject }}
            label={'Project'}
            endpoint={'Projects'}
            field={'dropdownDisplayName'}
            activeOnly={true}
            isAdminPageFilter={true}
          />
        </div>
      </div>
      {!selectedProject.id ? (
        <></>
      ) : (
        <RGHotTable
          endpoint={'Categories'}
          colData={colData}
          editPermission={'Category.Edit'}
          dataSchema={{
            isActive: false
          }}
          isEditable={selectedProject.jdeProject.isActive}
          projectId={selectedProject.id}
          key={selectedProject.id}
        >
          <HotColumn data={'id'} />
          <HotColumn data={'name'} className='htLeft' />
          <HotColumn data={'isActive'} type='checkbox' />
        </RGHotTable>
      )}
    </div>
  );
}

export default ProjectsCategories;
