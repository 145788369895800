import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { LoadingCard } from '../components/LoadingCard';

function PressureRetainingMaterialNotes() {
  const { instance, accounts } = useMsal();

  const [text, setText] = useState(null);

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  useEffect(() => {
    async function fetchData() {
      if (text) return;

      console.log('Get request sent for SystemTexts');

      let token = await getToken();

      await axios
        .get(
          process.env.REACT_APP_API_URL + 'SystemTexts/' + 4, // 4 = Pressure Retaining Material Notes
          {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + token
            }
          }
        )
        .then((resp) => {
          console.log('SystemText loaded: ', resp.data);
          setText(resp.data.description);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    fetchData();
  }, [getToken, text]);

  return (
    <div id='PressureRetainingMaterialNotes'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Pressure Retaining Material Notes</h3>
        </div>
      </div>
      <div className='card-container' style={{ textAlign: 'left' }}>
        <span style={{ whiteSpace: 'pre-line' }}>{text ?? <LoadingCard />}</span>
      </div>
    </div>
  );
}

export default PressureRetainingMaterialNotes;
