import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LabelledInput } from '../components/LabelledInput';

function NewClone() {
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedJob, setSelectedJob] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedReq, setSelectedReq] = useState({});

  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  // logic for Submit button
  const submit = async () => {
    console.log('Clone request sent for requisition');

    let token = await getToken();

    // send POST request
    await axios
      .get(process.env.REACT_APP_API_URL + 'Requisitions/clone/' + selectedReq.id, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      .then((resp) => {
        console.log(resp);

        // open newly created requisition
        let reqIdentifier = resp.headers.location.split('/').pop();
        navigate('/details/' + reqIdentifier);
      })
      .catch((err) => {
        console.error(err);

        let errorList = [];

        if (typeof err.response.data === 'string') {
          // string error
          errorList.push(err.response.data);
        } else if (err.response.data.length > 0) {
          // array of string errors
          for (const e in err.response.data) {
            errorList.push(err.response.data[e]);
          }
        } else if (err.response.data.errors) {
          // array or object of string errors
          for (const e in err.response.data.errors) {
            errorList.push(err.response.data.errors[e]);
          }
        }

        errorList = errorList.map((e, idx) => <li key={idx}>{e}</li>);

        toast.error(
          <div>
            The requisition was unable to be cloned due to the following error(s):
            <ul style={{ paddingLeft: '1em' }}>{errorList}</ul>
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            theme: 'colored'
          }
        );
      });
  };

  return (
    <div id='NewClone'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>New from Clone</h3>
        </div>
      </div>
      <div className='card-container'>
        <div className='card-component'>
          <LabelledInput
            type={'dropdown'}
            input={{ set: setSelectedProject, value: selectedProject }}
            label={'Project'}
            endpoint={'Projects'}
            field={'projectDropdownDisplayName'}
            validOnly={true}
          />
          {!selectedProject.projectId ? (
            <></>
          ) : (
            <>
              <br />
              <LabelledInput
                type={'dropdown'}
                input={{ set: setSelectedJob, value: selectedJob }}
                label={'Job'}
                endpoint={'Jobs'}
                field={'jobDropdownDisplayName'}
                validOnly={true}
                projectId={selectedProject.projectId}
              />
            </>
          )}
          {!selectedJob.jobId ? (
            <></>
          ) : (
            <>
              <br />
              <LabelledInput
                type={'dropdown'}
                input={{ set: setSelectedCategory, value: selectedCategory }}
                label={'Category'}
                endpoint={'Categories'}
                field={'categoryName'}
                validOnly={true}
                projectId={selectedProject.projectId}
                jobId={selectedJob.jobId}
              />
            </>
          )}
          {!selectedCategory.categoryId ? (
            <></>
          ) : (
            <>
              <br />
              <LabelledInput
                type={'dropdown'}
                input={{ set: setSelectedReq, value: selectedReq }}
                label={'Requisition'}
                endpoint={'Requisitions/clonable'}
                field={'requisitionNumber'}
                jobId={selectedJob.jobId}
                categoryId={selectedCategory.categoryId}
              />
            </>
          )}
          {!selectedReq.id ? (
            <></>
          ) : (
            <div className='new-submit'>
              <Button onClick={() => submit()}>Submit</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewClone;
