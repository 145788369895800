import { LoadingCard } from '../components/LoadingCard';

export const SaveOverlay = () => {
  return (
    <div className='modal'>
      <div className='modal-card save-card'>
        Save in progress. Please do not leave or refresh the page until the save is complete.
        <br />
        <br />
        <br />
        <LoadingCard mini={true} />
      </div>
    </div>
  );
};
