import React, { useState, useEffect } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';
import { LoadingCard } from '../components/LoadingCard';

export const RequisitionDetailsTable = ({
  reqId,
  reqRevNum,
  reqPermissions,
  projectId,
  jobId,
  categoryId,
  linesSaved,
  setLinesSaved,
  setSaveType,
  saveType,
  lineData,
  setLineData,
  linesLoaded,
  setLinesLoaded,
  setResetLines,
  setLinesSelected,
  reqState,
  reqRevision,
  revisionDiff,
  locked,
  setDirty,
  isRevisionHistory
}) => {
  const colData = {
    widths: [0, 25, 35, 75, 100, 95, 60, 60, 315, 50, 70, 85, 85, 95, 95, 115, 85, 70, 50, 50, 50, 105, 105, 315],
    names: [
      'ID',
      '',
      '#',
      'Purpose',
      'Part Number',
      'Commodity<br />Code',
      'Size 1',
      'Size 2',
      'Description',
      'QTY',
      'UoM',
      'Cost Code',
      'Cost Type',
      'RAS Date',
      'End Date',
      'Notes To/From<br />Procurement',
      'R Number',
      'PO<br />Number',
      'PO<br />Line',
      'PO<br />QTY',
      'PO<br />Rev',
      'Description 1',
      'Description 2',
      'Description 3'
    ]
  };

  const [externalSave, setExternalSave] = useState(false);
  const [externalSaveFinished, setExternalSaveFinished] = useState(false);
  const [externalLoaded, setExternalLoaded] = useState(false);
  const [externalHotData, setExternalHotData] = useState(null);
  const [externalResetDone, setExternalResetDone] = useState(false);
  const [externalHasError, setExternalHasError] = useState(false);
  const [externalReset, setExternalReset] = useState(false);

  // dropdown options
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [ccOptions, setCCOptions] = useState([]);
  const [uomOptions, setUomOptions] = useState([]);
  const [costTypeCodeOptions, setCostTypeCodeOptions] = useState([]);
  // collection of API endpoints, property names, 'data', 'setData', whether to fetch only by selected project for each dropdown,
  // optionally: the property used to retrieve it from 'data', plus a top-level property to get the text for the dropdown from instead of a hydrated object like usual
  const dropdowns = [
    ['Purposes', 'purpose', purposeOptions, setPurposeOptions, false],
    ['CommodityCodes', 'commodityCode', ccOptions, setCCOptions, true, 'display', 'commodityCode'],
    ['UnitOfMeasures', 'unitOfMeasure', uomOptions, setUomOptions, false, 'display'],
    ['CostTypeCodes', 'costTypeCode', costTypeCodeOptions, setCostTypeCodeOptions, false, 'display']
  ];

  // set lines that the user has selected (checkboxes)
  useEffect(() => {
    if (linesLoaded && externalHotData) setLinesSelected(externalHotData.filter((r) => r.selected));
  }, [externalHotData, linesLoaded, setLinesSelected]);

  // update lineData prop used by AttachmentList
  useEffect(() => {
    if (externalHotData === null || !externalLoaded) return; // wait for lines to load

    let updated = false;
    let tempLineData = [];

    if (!lineData) {
      // first run
      for (const row in externalHotData) {
        // add any lines
        let tempRow = externalHotData[row];
        tempLineData.push({ lineNumber: tempRow.lineNumber, id: tempRow.id });
      }
      // always updated, as lineData starts as null
      updated = true;
    } else {
      // remove any lines that don't exist in externalHotData any more
      // unless we just saved - then update lineId's
      tempLineData = Array.from(lineData);
      if (!externalSaveFinished) {
        // not just saved
        tempLineData = tempLineData.filter((l) => externalHotData.some((r) => r.id === l.id));

        if (JSON.stringify(tempLineData) !== JSON.stringify(lineData)) updated = true;
      } else {
        // just saved
        let newLines = tempLineData.filter((l) => typeof l.id === 'string' && l.id.includes('N'));

        if (newLines) {
          newLines.forEach((l) => (l.id = externalHotData.find((r) => r.lineNumber === l.lineNumber).id));
          updated = true;
        }
      }

      // check lineIds that already exist in lineData
      // if line number has changed, update it and set updated
      for (const row in externalHotData) {
        let tempRow = externalHotData[row];

        let matchedRow = lineData.find((l) => l.id === tempRow.id);
        if (matchedRow) {
          // matched
          // see if the line number has changed, and update if so
          if (matchedRow.lineNumber !== tempRow.lineNumber) {
            matchedRow.lineNumber = tempRow.lineNumber;
            updated = true;
          }
        } else {
          // not found
          // check if the line has an oldId, and thus is a new line just given a real ID after saving
          if (tempRow.oldId) {
            // update the ID in lineData
            let matchedNewRow = lineData.find((l) => l.id === tempRow.oldId);
            matchedNewRow.id = tempRow.id;
            tempRow.oldId = null;
          } else {
            // otherwise, add to lineData
            tempLineData.push({ lineNumber: tempRow.lineNumber, id: tempRow.id });
          }

          updated = true;
        }
      }
    }

    if (updated && setLineData && tempLineData.length === externalHotData.length) {
      setLineData(tempLineData);
    }

    if (!linesLoaded && setLinesLoaded) setLinesLoaded(true);
  }, [externalHotData, externalLoaded, externalSaveFinished, lineData, linesLoaded, setLineData, setLinesLoaded]);

  // save
  useEffect(() => {
    if (saveType && !linesSaved) setExternalSave(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveType]);
  // update save variables for parent
  useEffect(() => {
    if (externalSaveFinished) {
      if (!externalHasError) {
        // save finished, let parent know
        setLinesSaved(true);
      } else {
        // error present, so stop further saves
        setSaveType(0);
        setExternalHasError(false);
      }
      setExternalSaveFinished(false);
    }
  }, [externalHasError, externalSaveFinished, setLinesSaved, setSaveType]);

  // triggers when 'Undo All' is pressed
  useEffect(() => {
    if (externalResetDone) {
      setLinesLoaded(false);
      setLineData(null);
      setResetLines(true);
      setExternalResetDone(false);
    }
  }, [externalResetDone, setLineData, setLinesLoaded, setResetLines]);

  // new requisition+revision number was passed
  useEffect(() => {
    setExternalLoaded(false);
    setLinesLoaded(false);
    setLineData(null);
  }, [reqRevNum, setLineData, setLinesLoaded]);

  // reload table to fetch any changes
  useEffect(() => {
    setExternalReset(true);
  }, [reqState]);
  // reset externalReset
  useEffect(() => {
    if (externalReset) setExternalReset(false);
  }, [externalReset]);

  // change the style of column headers 15 to 23:
  const styles = `
  .handsontable th:nth-child(n+15):nth-child(-n+23){
    background-color:#e4e4e4;
  }`;

  // wait for IDs before loading table
  if (reqId === -1 || jobId === -1 || categoryId === -1) {
    return <LoadingCard />;
  } else {
    return (
      <>
        <style>{styles}</style>
        <RGHotTable
          endpoint={'Lines'}
          colData={colData}
          reqPermissions={reqPermissions}
          reqState={reqState}
          reqRevision={reqRevision}
          dataSchema={{
            lineStateId: 0,
            selected: false
          }}
          addProperties={[['selected', false]]}
          dropdowns={projectId ? dropdowns : []}
          headerHeight={2}
          reqId={reqId}
          reqRevNum={reqRevNum}
          revisionDiff={revisionDiff}
          projectId={projectId}
          jobId={jobId}
          categoryId={categoryId}
          minHeight={'fullscreen'}
          isEditable={!locked}
          nullProperties={['lineState', 'unitOfMeasure']}
          externalSave={{ get: externalSave, set: setExternalSave }}
          setExternalSaveFinished={setExternalSaveFinished}
          setExternalLoaded={setExternalLoaded}
          setExternalHotData={setExternalHotData}
          setExternalResetDone={setExternalResetDone}
          setExternalHasError={setExternalHasError}
          setExternalDirty={setDirty}
          externalReset={externalReset}
          showSaveOverlay={false}
          showDirtyPrompt={false}
          isRevisionHistory={isRevisionHistory}
        >
          <HotColumn data={'id'} />
          <HotColumn data={'selected'} type='checkbox' />
          <HotColumn data={'lineNumber'} type='numeric' className='htLeft' readOnly={true} />
          <HotColumn
            data={'purpose.name'}
            type='dropdown'
            source={purposeOptions.filter((o) => o.isActive).map((o) => o.name)}
            className='htLeft'
          />
          <HotColumn data={'partNumber'} className='htLeft' />
          <HotColumn
            data={'commodityCode'}
            type='autocomplete'
            validator={null}
            strict={false}
            source={ccOptions.filter((o) => o.isActive).map((o) => o.display)}
            className='htLeft'
          />
          <HotColumn data={'size1'} type='numeric' className='htLeft' />
          <HotColumn data={'size2'} type='numeric' className='htLeft' />
          <HotColumn data={'description'} className='htLeft' />
          <HotColumn data={'quantity'} type='numeric' className='htLeft' />
          <HotColumn
            data={'unitOfMeasure.unit'}
            type='dropdown'
            validator={null}
            source={uomOptions.filter((o) => o.isActive).map((o) => o.display)}
            className='htLeft'
          />
          <HotColumn data={'costCodeNumber'} type='dropdown' validator={null} className='htLeft' />
          <HotColumn data={'costTypeNumber'} type='dropdown' validator={null} className='htLeft' />
          <HotColumn data={'rasDate'} type='date' dateFormat='YYYY-MM-DD' datePickerConfig={{ minDate: new Date() }} />
          <HotColumn data={'endDate'} type='date' dateFormat='YYYY-MM-DD' datePickerConfig={{ minDate: new Date() }} />
          <HotColumn data={'purchaseDetails'} className='htLeft' />
          <HotColumn data={'rentalNumber'} className='htLeft' />
          <HotColumn data={'poNumber'} className='htLeft' />
          <HotColumn data={'poLine'} className='htLeft' />
          <HotColumn data={'poQuantity'} type='numeric' className='htLeft' />
          <HotColumn data={'poVersion'} className='htLeft' />
          <HotColumn data={'description1'} className='htLeft' />
          <HotColumn data={'description2'} className='htLeft' />
          <HotColumn data={'description3'} className='htLeft' />
        </RGHotTable>
      </>
    );
  }
};
