import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';
import { LabelledInput } from '../components/LabelledInput';

function ProjectsDeliveryLocations() {
  const [selectedProject, setSelectedProject] = useState({});

  const colData = {
    widths: [0, 160, 70],
    names: ['ID', 'Location', 'Active']
  };

  return (
    <div id='ProjectsDeliveryLocations'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects & Delivery Locations</h3>
        </div>
      </div>

      <div className='note warning'>
        <span>
          Caution: Please note that modifying any delivery location will change it for all previous reqs where it has
          already been used.
        </span>
      </div>

      <div className='card-container'>
        <div className='card-component'>
          <LabelledInput
            type={'dropdown'}
            input={{ set: setSelectedProject, value: selectedProject }}
            label={'Project'}
            endpoint={'Projects'}
            field={'dropdownDisplayName'}
            activeOnly={true}
            isAdminPageFilter={true}
          />
        </div>
      </div>
      {!selectedProject.id ? (
        <></>
      ) : (
        <RGHotTable
          endpoint={'DeliveryLocations'}
          colData={colData}
          editPermission={'DeliveryLocation.Edit'}
          dataSchema={{
            isActive: false
          }}
          isEditable={selectedProject.jdeProject.isActive}
          projectId={selectedProject.id}
          key={selectedProject.id}
        >
          <HotColumn data={'id'} />
          <HotColumn data={'name'} className='htLeft' />
          <HotColumn data={'isActive'} type='checkbox' />
        </RGHotTable>
      )}
    </div>
  );
}

export default ProjectsDeliveryLocations;
