import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function LinePurposeConstraints() {
  const colData = {
    widths: [0, 160, 160, 120, 100, 70],
    names: ['ID', 'Purpose*', 'Line Field*', 'Constraint*', 'Fixed Value~', 'Active']
  };

  // dropdown options
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [lineFieldOptions, setLineFieldOptions] = useState([]);
  const [constraintOptions, setConstraintOptions] = useState(['Mandatory', 'Optional', 'NonEditable', 'Fixed']);
  // collection of API endpoints, property names, data, setData, and whether to fetch only by selected project for each dropdown
  const dropdowns = [
    ['Purposes', 'purpose', purposeOptions, setPurposeOptions, false],
    ['LineFields', 'lineField', lineFieldOptions, setLineFieldOptions, false],
    ['enum', 'uiState', constraintOptions, setConstraintOptions, false]
  ];

  return (
    <div id='LinePurposeConstraints'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Line Purpose Constraints</h3>
        </div>
      </div>

      <p className='note'>
        Be careful when setting or changing a 'Fixed Value' cell. It is recommended to reach out to and work with a
        development resource when this field is required.
      </p>

      <RGHotTable
        endpoint={'LineFieldPurposes'}
        colData={colData}
        editPermission={'LineField.Edit'}
        dataSchema={{
          isActive: false
        }}
        dropdowns={dropdowns}
        isEditable={true}
      >
        <HotColumn data={'id'} />
        <HotColumn
          data={'purpose.name'}
          type='dropdown'
          source={purposeOptions.filter((o) => o.isActive).map((o) => o.name)}
          className='htLeft'
        />
        <HotColumn
          data={'lineField.name'}
          type='dropdown'
          source={lineFieldOptions.filter((o) => o.isActive).map((o) => o.name)}
          className='htLeft'
        />
        <HotColumn data={'uiState'} type='dropdown' source={constraintOptions} className='htLeft' />
        <HotColumn data={'fieldValue'} className='htLeft' />
        <HotColumn data={'isActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default LinePurposeConstraints;
