import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function ProjectsJobs() {
  const colData = {
    widths: [0, 250, 190, 150, 130, 250, 150, 150, 250, 70],
    names: [
      'ID',
      'ReqGen Project Description',
      'ReqGen Project Short Name',
      'JDE Project Number',
      'JDE Job Number',
      'JDE Job Description',
      'JDE Business Entity',
      'JDE Client Name',
      'ReqGen Job Name*',
      'Active'
    ]
  };

  return (
    <div id='ProjectsJobs'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects & Jobs</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'Jobs'}
        colData={colData}
        editPermission={'Job.Edit'}
        activeProperty={'project'}
        constraints={[{ required: ['description'], reliant: ['isActive'] }]}
        addRemoveRows={false}
        nullProperties={['jdeJob']}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'project.description'} readOnly={true} className='htLeft' />
        <HotColumn data={'project.shortName'} readOnly={true} className='htLeft' />
        <HotColumn data={'jdeProjectNumber'} readOnly={true} className='htLeft' />
        <HotColumn data={'jdeJob.job'} readOnly={true} className='htLeft' />
        <HotColumn data={'jdeJob.description'} readOnly={true} className='htLeft' />
        <HotColumn data={'jdeJob.businessEntity'} readOnly={true} className='htLeft' />
        <HotColumn data={'jdeJob.clientName'} readOnly={true} className='htLeft' />
        <HotColumn data={'description'} className='htLeft' />
        <HotColumn data={'isActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default ProjectsJobs;
