import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from './RGHotTable';

export const ApprovalsHistory = ({ reqRevNum }) => {
  const colData = {
    widths: [0, 60, 140, 110, 260, 90, 260],
    names: ['ID', 'Rev #', 'Date', 'Review Type', 'Reviewer', 'Result', 'Comment']
  };

  const ApprovalText = (props) => {
    return <div style={{ textAlign: 'left' }}>{props.value ? 'Approved' : 'Rejected'}</div>;
  };

  return (
    <RGHotTable
      endpoint={'RequisitionReviews'}
      colData={colData}
      reqRevNum={reqRevNum}
      convertDateProps={['updatedAt']}
      initialSort={[
        {
          column: 2,
          sortOrder: 'desc'
        }
      ]}
      isEditable={false}
    >
      <HotColumn data={'id'} />
      <HotColumn data={'revisionNumber'} className='htLeft' />
      <HotColumn data={'updatedAt'} />
      <HotColumn data={'reviewType'} className='htLeft' />
      <HotColumn data={'updatedBy'} className='htLeft' />
      <HotColumn data={'isApproved'} className='htLeft'>
        <ApprovalText hot-renderer />
      </HotColumn>
      <HotColumn data={'comments'} className='htLeft' />
    </RGHotTable>
  );
};
