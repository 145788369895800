import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function Projects() {
  const colData = {
    widths: [0, 210, 260, 210, 210, 70],
    names: [
      'ID',
      'JDE Project Number',
      'JDE Project Description',
      'ReqGen Project Description*',
      'ReqGen Project Short Name*',
      'Active'
    ]
  };
  // dropdown options
  const [jdeProjectOptions, setJdeProjectOptions] = useState([]);
  // collection of API endpoints, property names, data, setData, and whether to fetch only by selected project for each dropdown
  const dropdowns = [['JdeProjects', 'jdeProject', jdeProjectOptions, setJdeProjectOptions, false]];

  return (
    <div id='Projects'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'Projects'}
        colData={colData}
        editPermission={'Project.Edit'}
        dropdowns={dropdowns}
        constraints={[{ required: ['description', 'shortName'], reliant: ['isActive'] }]}
        addRemoveRows={false}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'jdeProject.project'} readOnly={true} className='htLeft' />
        <HotColumn data={'jdeProject.description'} readOnly={true} className='htLeft' />
        <HotColumn data={'description'} className='htLeft' />
        <HotColumn data={'shortName'} className='htLeft' />
        <HotColumn data={'isActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default Projects;
