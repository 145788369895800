import React, { useState, useRef, useEffect, useCallback } from 'react';
import * as rax from 'retry-axios';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest, timeoutSetting } from '../authConfig';
import { usePrompt } from '../functions/promptBlocker';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { LoadingCard } from '../components/LoadingCard';
import { SaveOverlay } from '../components/SaveOverlay';
import { HOT_LICENSE_KEY } from '../constants';
const resolvePath = require('object-resolve-path');

//                                           //
// important note when using this component: //
// first column specified for the table MUST //
// be ID, which will be automatically hidden //
//                                           //

// TODO: discuss renaming 'Undo All' button to 'Reset' to more accurately describe its function

// register Handsontable modules
registerAllModules();

// attach retry-axios interceptor to axios (retries 3x by default)
rax.attach();

// durations used for toast pop-up messages, in ms
const TOAST_DURATIONS = {
  short: 1500,
  medium: 2500,
  long: 5000
};

export const RGHotTable = ({
  children = <></>,
  endpoint, // primary API endpoint for the instance
  colData, // column data (widths & header names)
  editPermission = null, // permission required to edit this table
  cellsFunc, // cells function for Handsontable
  initialSort, // initial column sorting (multiColumnSorting.initialConfig object for Handsontable)
  dataSchema = null, // dataSchema object (default values) for HotTable
  dropdowns = [], // data for dropdown columns
  linkProps = [], // columns to convert into links
  locked = false, // allows manual locking of the entire table
  syncSave = false, // forces synchronous saves on table rows
  convertDateProps = [], // columns to convert to friendly date
  insertRow = { get: null, set: null }, // used by parent to signal a row to insert. 'get' format: { index, rowData }
  removeRow = { get: null, set: null }, // used by parent to signal a row to remove, by ID
  dupeOnProperty = null, // property name to duplicate rows on (for one-to-many rows)
  headerHeight = 1, // header row height multiplier
  minHeight = null, // manually-set minimum table height (px). optionally 'fullscreen' to make table take up the full screen
  projectId = null, // limit results to this project ID
  jobId = null, // limit results to this job ID
  reqId = null, // limit results to this requisition ID
  reqRevNum = null, // limit results to this requisition number + revision number (eg. R123-0001-0)
  categoryId = null, // used for CostTypeCodes
  isEditable = true, // should this instance be editable?
  addRemoveRows = true, // can the user add/remove rows (if editable)
  activeProperty = '', // if populated, only show rows where this property's isActive = true; '.' represents parent object
  constraints = [], // requires certain fields to be non-empty before other fields can be changed. format: [{ required: [fieldName1, fieldName2], reliant: [fieldName3, fieldName4] }]
  nullProperties = [], // properties to null out before sending to API to save
  addProperties = [], // properties to add to every row after fetch, plus their value. formate: [[property, value], ...]. doesn't add on create row - use dataSchema for that
  extra = <></>, // extra element to render, below the table but within the same card
  idProp = null, // required if a given table dataset has a primary ID field with a name other than 'id'
  showSaveOverlay = true, // whether to show the saving overlay while waiting for the table to save
  externalSave = null, // allows saving from a parent component by passing a state get and set { get: getState, set: setState }, instead of using the normal 'Save' button
  setExternalHotData = null, // used to pass hotData to parent
  setExternalLoaded = null, // used to let parent know when the table is loaded
  setExternalSaveFinished = null, // used to let parent know when the table is done saving
  setExternalResetDone = null, // used to let parent know when a reset is completed
  setExternalHasError = null, // used to let parent know if one or more errors occurred when saving
  setExternalDirty = null, // used to let parent know if data is dirty (awaiting a save)
  setExternalEdit = null, // used to let parent know if table 'Edit' flag is currently enabled
  externalReset = false, // used to allow parent to reset this table
  reqPermissions = undefined, // used to determine which fields to lock for Lines based on permissions
  reqState = null, // used to determine which fields to lock for Lines based on permissions
  reqRevision = null, // used to determine which fields to lock for Lines based on permissions
  revisionDiff = null, // used to bold line changes compared to previous revision
  showDirtyPrompt = true, // allows parent to disable dirty redirect prompt
  isRevisionHistory = false // whether this table is being called from the 'Revision History' tab
}) => {
  const [hotData, setHotData] = useState(null); // table data
  const [preHotData, setPreHotData] = useState(null); // used as a middleman to hotData on initial fetch, to prevent strange rendering issues with Handsontable
  const [initialHotData, setInitialHotData] = useState(null); // unmodifiable table data (from first load or last save)
  const [hotColumnSettings, setHotColumnSettings] = useState(null); // initial column settings
  const [editAllowed, setEditAllowed] = useState(isEditable && editPermission === null); // restricts editing
  const [updatedRows, setUpdatedRows] = useState([]); // *
  const [createdRows, setCreatedRows] = useState([]); // IDs of modified rows
  const [deletedRows, setDeletedRows] = useState([]); // *
  const [invalidRows, setInvalidRows] = useState([]); // IDs of invalid rows (populated during save)
  const [errors, setErrors] = useState([]);
  const [, setCreatedCount] = useState(0); // identity counter for created rows
  const [isLoaded, setIsLoaded] = useState(false); // loaded flag
  const [isLoading, setIsLoading] = useState(false); // loading flag
  const [isEditing, setIsEditing] = useState(
    !locked && (endpoint === 'Lines' || endpoint === 'RequisitionReviews/MyPending')
  ); // editing flag
  const [hasBeganSave, setHasBeganSave] = useState(false); // begin save flag
  const [isSaving, setIsSaving] = useState(false); // saving flag
  const [isEditingCell, setIsEditingCell] = useState(false); // cell selected flag
  const [isChanging, setIsChanging] = useState(false); // cell change in progress flag
  const [costTypeCodes, setCostTypeCodes] = useState(null); // used for Lines table
  const [lineFields, setLineFields] = useState(null); // used for Lines table
  const [lineFieldPurposes, setLineFieldPurposes] = useState(null); // used for Lines table
  const [, setNextLineNumber] = useState(1); // used for Lines table
  const [projectsAllowed, setProjectsAllowed] = useState(null); // used for Projects and Jobs table per-project permissions
  const [reloading, setReloading] = useState(false); // used to track when table data reloading is finished
  const [refilter, setRefilter] = useState(false); // used to tell table when to re-apply filtering (and sorting)

  const { instance, accounts } = useMsal();
  const hot = useRef(null);
  const cardContainer = useRef(null);

  let hotInstance;
  if (hot && hot.current) hotInstance = hot.current.hotInstance;
  
  const convertDate = (date) => {
    let tempDate = new Date(new Date(date).getTime());

    let dd = String(tempDate.getDate()).padStart(2, '0');
    let MM = String(tempDate.getMonth() + 1).padStart(2, '0');
    let yyyy = tempDate.getFullYear();
    let hh = String(tempDate.getHours()).padStart(2, '0');
    let mm = String(tempDate.getMinutes()).padStart(2, '0');
    let ss = String(tempDate.getSeconds()).padStart(2, '0');

    return yyyy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss;
  };

  const rowIsEmpty = useCallback((row) => {
    for (const prop in row) {
      if (
        row[prop] === null ||
        prop === 'isActive' ||
        prop === 'id' ||
        prop === 'projectId' ||
        prop === 'lineNumber' ||
        prop === 'selected' ||
        !row.hasOwnProperty(prop)
      ) {
        // property is null, a property set by default when row created (so ignore), or not a property
        continue;
      } else if (typeof row[prop] === 'object') {
        // property is object - recurse
        if (rowIsEmpty(row[prop])) continue;
        else return false; // non-null property found in sub-object
      } else {
        return false; // non-null property found in row
      }
    }

    // row is empty
    return true;
  }, []);

  const rowsMatch = (rowA, rowB) => {
    for (const prop in rowA) {
      if (['display', 'rowVersion', 'selected'].includes(prop)) {
        continue; // in list of ignored properties, skip
      } else if (!rowA.hasOwnProperty(prop)) {
        continue; // not a property, skip
      } else if (!rowB.hasOwnProperty(prop)) {
        return false; // property missing on row B - no match
      } else if (rowA[prop] !== null && typeof rowA[prop] === 'object') {
        // property is object - recurse
        if (rowsMatch(rowA[prop], rowB[prop])) continue;
        else {
          return false; // non-null property found in sub-object
        }
      } else if (
        rowA[prop] !== rowB[prop] &&
        !(rowA[prop] === null && rowB[prop] === '') &&
        !(rowA[prop] === '' && rowB[prop] === null)
      ) {
        return false; // values inequal - no match
      }
    }

    // rows match
    return true;
  };

  const hasPermission = (permission, lineStateId = null) => {
    // p[0] - permission is for this requisitionStateId
    // p[1] - permission is for this lineStateId
    // p[2] - permission is for revision === 0 (true) or revision !=== 0 (false)

    return (
      reqPermissions &&
      reqPermissions[permission] !== undefined &&
      reqPermissions[permission].some(
        (p) => p[0] === reqState && (lineStateId === null || p[1] === lineStateId) && p[2] === (reqRevision === 0)
      )
    );
  };

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  // reset table to initial state (triggers re-fetch)
  const reset = useCallback(
    (keepToasts = false) => {
      if (isLoaded) {
        setHotData(null);
        setInitialHotData(null);
        setUpdatedRows([]);
        setCreatedRows([]);
        setDeletedRows([]);
        setInvalidRows([]);
        setCreatedCount(0);
        setIsLoaded(false);
        setIsLoading(false);
        // setIsEditing(false); // leave 'isEditing' in it's current state
        setHasBeganSave(false);
        setIsSaving(false);
        setIsEditingCell(false);
        setIsChanging(false);
        setErrors([]);

        if (setExternalHotData) setExternalHotData(null);

        // clear undo history
        hotInstance.undoRedo.clear();

        // clear all toasts
        if (!keepToasts) toast.dismiss();
      }
    },
    [hotInstance, isLoaded, setExternalHotData]
  );
  useEffect(() => {
    if (externalReset) {
      reset(true);

      // let parent know a reset has finished
      if (setExternalResetDone) setExternalResetDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalReset]);

  const fetchData = useCallback(async () => {
    if (isLoaded || isLoading) return;

    // fetch permission if specified
    if (editPermission) {
      if (['Projects', 'Jobs'].includes(endpoint)) {
        console.log('Get request sent for permission: ' + editPermission);

        let token = await getToken();

        await axios
          .get(process.env.REACT_APP_API_URL + 'UserPermissions/system', {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + token
            }
          })
          .then((resp) => {
            console.log(editPermission + ' permission loaded:', resp);

            let match = resp.data.find((p) => p.permissionName === editPermission);

            setEditAllowed(match !== undefined);

            // if user does not have system-wide permission
            if (match && match.projectIds[0] !== null) {
              // set projects they have permission for
              setProjectsAllowed(Array.from(match.projectIds));
            }
          });
      } else if (projectId) {
        console.log('Get request sent for permission: ' + editPermission + ' on project ID: ' + projectId);

        let token = await getToken();

        await axios
          .get(process.env.REACT_APP_API_URL + 'UserPermissions/system', {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + token
            }
          })
          .then((resp) => {
            console.log(editPermission + ' permission loaded:', resp);

            setEditAllowed(
              resp.data.some(
                (p) =>
                  p.permissionName === editPermission && (p.projectIds[0] === null || p.projectIds.includes(projectId))
              )
            );
          });
      } else {
        console.log('Get request sent for system permission: ' + editPermission);

        let token = await getToken();

        await axios
          .get(process.env.REACT_APP_API_URL + 'UserPermissions/' + editPermission, {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + token
            }
          })
          .then((resp) => {
            console.log(editPermission + ' system permission loaded:', resp);

            setEditAllowed(resp.data);
          });
      }
    }

    // used for Lines table
    let tempCostTypeCodes = [];
    let tempInactiveCostTypeCodes = [];
    let tempCommodityCodes = [];

    // fetch all required data for dropdown options
    await Promise.all(
      dropdowns.map(async (d) => {
        let dropdownEndpoint = d[0];

        // make sure we're not dealing with an enum dropdown, which doesn't need fetching
        if (dropdownEndpoint === 'enum') return;

        let projectSpecific = d[4];

        // special dropdown fetch logic for CostTypeCodes on Lines
        let isCostTypeCodesOnLines = endpoint === 'Lines' && dropdownEndpoint === 'CostTypeCodes';
        let setDropdownData = d[3];
        if (isCostTypeCodesOnLines) {
          console.log('Get request sent for dropdown:', dropdownEndpoint);

          let token = await getToken();

          await axios
            .get(process.env.REACT_APP_API_URL + dropdownEndpoint + '/Job/' + jobId + "/Category/" + categoryId, {
              raxConfig: {
                retry: 5,
                shouldResetTimeout: true
              },
              timeout: timeoutSetting,
              crossDomain: true,
              headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token
              }
            })
            .then((resp) => {
              console.log('Dropdown data loaded for ' + dropdownEndpoint + ':', resp.data);

              tempCostTypeCodes = resp.data.filter((o) => o.isActive);
              tempInactiveCostTypeCodes = resp.data.filter((o) => !o.isActive);

              // create CostCodeNumber & CostTypeNumber display fields that include descriptions for CostTypeCodes
              if (endpoint === 'Lines' && dropdownEndpoint === 'CostTypeCodes') {
                for (const ctc of tempCostTypeCodes) {
                  if (ctc.costCodeDescription) {
                    ctc.codeDisplay = ctc.costCodeNumber + ' - ' + ctc.costCodeDescription;
                    ctc.codeDisplay = ctc.codeDisplay.substring(0, 200); // truncate to 200 chars
                  } else {
                    ctc.codeDisplay = ctc.costCodeNumber;
                  }

                  if (ctc.description) {
                    ctc.typeDisplay = ctc.costTypeNumber + ' - ' + ctc.description;
                    ctc.typeDisplay = ctc.typeDisplay.substring(0, 200); // truncate to 200 chars
                  } else {
                    ctc.typeDisplay = ctc.costTypeNumber;
                  }
                }
              }

              setDropdownData(tempCostTypeCodes);
              setCostTypeCodes(tempCostTypeCodes);
            })
            .catch((err) => {
              if (err.response && err.response.status === 404) {
                console.log('Dropdown data loaded for ' + dropdownEndpoint + ' (empty)');
              } else {
                console.error(err);
              }
            });
        } else {
          console.log('Get request sent for dropdown:', dropdownEndpoint);

          let token = await getToken();

          await axios
            .get(
              process.env.REACT_APP_API_URL +
                dropdownEndpoint +
                (projectId && projectSpecific ? '/Project/' + projectId : ''),
              {
                raxConfig: {
                  retry: 5,
                  shouldResetTimeout: true
                },
                timeout: timeoutSetting,
                crossDomain: true,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  Authorization: 'Bearer ' + token
                }
              }
            )
            .then((resp) => {
              console.log('Dropdown data loaded for ' + dropdownEndpoint + ':', resp.data);
              let tempData = resp.data;

              // create display field & save commodity codes for use further down
              if (endpoint === 'Lines' && dropdownEndpoint === 'CommodityCodes') {
                for (const cc of tempData) {
                  cc.display = cc.commodityCode1 + ' (' + cc.size1 + ', ' + cc.size2 + ') - ' + cc.description;
                  // remove any newlines, '\r', '\n', and trailing spaces to prevent issues
                  cc.display = cc.display
                    .replace(/(\r\n|\n|\r)/gm, '')
                    .replace('\r', '')
                    .replace('\n', '')
                    .trim();
                  // truncate to 200 chars
                  cc.display = cc.display.substring(0, 200);
                }

                tempCommodityCodes = tempData;
              }

              // create display field for UoM
              if (endpoint === 'Lines' && dropdownEndpoint === 'UnitOfMeasures') {
                for (const uom of tempData) {
                  uom.display = uom.unit + ' - ' + uom.description;
                  // remove any newlines, '\r', or '\n' to prevent issues
                  uom.display = uom.display
                    .replace(/(\r\n|\n|\r)/gm, '')
                    .replace('\r', '')
                    .replace('\n', '');
                  // truncate to 200 chars
                  uom.display = uom.display.substring(0, 200);
                }
              }

              // add blank category option for cost type code setup
              if (endpoint === 'CostTypeCodes' && dropdownEndpoint === 'Categories') {
                tempData.unshift({
                  id: null,
                  name: '',
                  isActive: true
                });
              }

              setDropdownData(tempData);
            })
            .catch((err) => {
              if (err.response && err.response.status === 404) {
                console.log('Dropdown data loaded for ' + dropdownEndpoint + ' (empty)');
              } else {
                console.error(err);
              }
            });
        }
      })
    );

    // fetch line fields and constraints for the Lines table
    if (endpoint === 'Lines') {
      console.log('Get request sent for Line Fields');

      let token = await getToken();

      await axios
        .get(process.env.REACT_APP_API_URL + 'LineFields', {
          raxConfig: {
            retry: 5,
            shouldResetTimeout: true
          },
          timeout: timeoutSetting,
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
          }
        })
        .then((resp) => {
          console.log(resp.data);
          setLineFields(resp.data);
        });

      console.log('Get request sent for Line Field Purposes');
      await axios
        .get(process.env.REACT_APP_API_URL + 'LineFieldPurposes', {
          raxConfig: {
            retry: 5,
            shouldResetTimeout: true
          },
          timeout: timeoutSetting,
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
          }
        })
        .then((resp) => {
          console.log(resp.data);
          setLineFieldPurposes(resp.data);
        });
    }

    // fetch main grid data
    console.log('Get request sent for:', endpoint);

    let token = await getToken();

    await axios
      .get(
        process.env.REACT_APP_API_URL +
          endpoint + (endpoint === 'CostTypeCodes' ? '/CategorySummary' : '') +
          (jobId && endpoint !== 'Lines'
            ? '/Job/' + jobId
            : endpoint === 'Requisitions/history' && reqRevNum
            ? '/' + reqRevNum
            : reqRevNum
            ? '/Requisition/' + reqRevNum + (isRevisionHistory ? '?specificRevision=true' : '')
            : reqId
            ? '/Requisition/' + reqId
            : projectId
            ? '/Project/' + projectId
            : ''),
        {
          raxConfig: {
            retry: 5,
            shouldResetTimeout: true
          },
          timeout: timeoutSetting,
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
          }
        }
      )
      .then((resp) => {
        console.log('Main data loaded for ' + endpoint + ':', resp.data);

        if (resp.data === "") {
          // server will return an empty string if no records found
          setPreHotData([]);
        } else {
          let tempData = [];

          // split rows based on an array of sub-objects (one-to-many)
          // these can be re-combined using their ID's/sub-ID's before sending back to API, if the need arises
          // for now, saving is not supported
          if (dupeOnProperty) {
            for (let r = 0; r < resp.data.length; ++r) {
              let row = resp.data[r];

              // check if array property is empty (no references)
              if (row[dupeOnProperty].length > 0) {
                // create child row(s) from array property
                for (let i = 0; i < row[dupeOnProperty].length; ++i) {
                  let e = row[dupeOnProperty][i];
                  let tempRow = { ...row }; // shallow copy row
                  tempRow.id = tempRow.id + '-' + i; // add a sub ID to row ID, to keep child rows distinct
                  tempRow[dupeOnProperty] = e; // replace array property with current element
                  tempData.push(tempRow); // add new child row to data array
                }
              } else {
                // currently, the only use for this behavior requires rows with empty arrays to be ignored
                // if needed, the below can be brought back conditionally for showing records with empty arrays
                //
                // let tempRow = { ...row }; // shallow copy row
                // tempRow.id = row.id + '-0'; // add a sub ID to row ID, for consistency
                // tempRow[dupeOnProperty] = {}; // convert empty array to empty object
                // tempData.push(tempRow); // add to data array
              }
            }

            console.log('Child rows created: ', tempData);
          } else {
            tempData = resp.data;

            // check for non-standard ID property, and swap its key to 'id' if needed
            if (idProp) {
              for (const row of tempData) {
                delete Object.assign(row, { id: row[idProp] })[idProp];
              }
            }
          }

          // if specified, only keep rows whose activeProperty object has isActive = true ('.' represents parent)
          if (activeProperty !== '') {
            tempData = tempData.filter((r) => (activeProperty === '.' ? r.isActive : r[activeProperty].isActive));
            console.log('Filtered (isActive) data:', tempData);
          }

          // special logic for the Lines table
          if (endpoint === 'Lines') {
            // manipulate each line's CostCode, CostType, and CommodityCode as necessary
            for (const row of tempData) {
              let costTypeCodeNumber = tempCostTypeCodes.find((c) => c.id === row.costTypeCodeId);
              let inactiveCostTypeCodeNumber = tempInactiveCostTypeCodes.find((c) => c.id === row.costTypeCodeId);

              if (costTypeCodeNumber) {
                row.costCodeNumber = costTypeCodeNumber.costCodeNumber;
                row.costTypeNumber = costTypeCodeNumber.costTypeNumber;
              } else if (inactiveCostTypeCodeNumber) {
                row.costCodeNumber = inactiveCostTypeCodeNumber.costCodeNumber;
                row.costTypeNumber = inactiveCostTypeCodeNumber.costTypeNumber;
              } else {
                // no match for the line's costTypeCodeId - blank out it's costTypeCodeId
                row.costTypeCodeId = null;
              }

              let commodityCode = tempCommodityCodes.find((c) => c.id === row.commodityCodeId);
              if (!commodityCode) {
                // no match for the line's commodityCodeId - blank out its commodityCodeId to make it 'custom'
                row.commodityCodeId = null;
              } else {
                // match found, set the display field to description1
                row.commodityCode = commodityCode.commodityCode1;
              }
            }

            setNextLineNumber(tempData.map((r) => r.lineNumber).reduce((a, b) => Math.max(a, b), 0) + 1);
          }

          // convert dates to friendly format
          if (convertDateProps.length > 0) {
            for (const row of tempData) {
              for (const prop of convertDateProps) {
                row[prop] = convertDate(row[prop]);
              }
            }
          }

          // add properties as specified
          if (addProperties.length > 0) {
            for (const row of tempData) {
              for (const prop of addProperties) {
                row[prop[0]] = prop[1];
              }
            }
          }

          setPreHotData(tempData);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          console.log('Main data loaded for ' + endpoint + ' (empty)');
          setPreHotData([]);
        } else {
          console.error(err);
        }
      });

    if (setExternalLoaded) setExternalLoaded(true);

    setIsLoaded(true);
    setIsLoading(false);
  }, [
    activeProperty,
    addProperties,
    categoryId,
    convertDateProps,
    dropdowns,
    dupeOnProperty,
    editPermission,
    endpoint,
    getToken,
    idProp,
    isLoaded,
    isLoading,
    jobId,
    projectId,
    reqId,
    reqRevNum,
    setExternalLoaded
  ]);
  // fetch data if hotData is empty, or if isLoaded and isLoading are both false
  useEffect(() => {
    if (projectId === -1 || jobId === -1 || categoryId === -1) return; // skip if we're still waiting for IDs
    if (!hotData || (!isLoaded && !isLoading)) {
      setIsLoading(true);
      fetchData();
    }
  }, [fetchData, isLoaded, isLoading, hotData, jobId, categoryId, projectId, setExternalHotData]);
  // force a refetch if project ID, job ID, or requisition ID changes
  useEffect(() => {
    if (initialHotData === null) return; // skip before initial fetch
    if (setExternalHotData) setExternalHotData(null);
    setIsLoaded(false);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, jobId, reqId]);
  // using data 'middleman' prevents errors with custom renderers
  useEffect(() => {
    if (preHotData) {
      setHotData(preHotData);
    }
  }, [preHotData]);
  // wait for hotData to have side effects manipulate it's data before cloning
  useEffect(() => {
    if (hotData && !initialHotData) {
      setInitialHotData(JSON.parse(JSON.stringify(hotData))); // deepclone initial data

      // let parent know save + reload is finished
      if (reloading) {
        if (setExternalSaveFinished) setExternalSaveFinished(true);
        setReloading(false);
      }

      // also re-apply filtering
      setRefilter(true);
    } else if (invalidRows.length > 0) {
      // let parent know save is finished
      if (reloading) {
        if (setExternalSaveFinished) setExternalSaveFinished(true);
        setReloading(false);
      }

      // also re-apply filtering
      setRefilter(true);
    }
  }, [hotData, initialHotData, invalidRows.length, reloading, setExternalSaveFinished, setRefilter]);

  const sendUpdatedRow = useCallback(async (rowId, tempHotData, invalidUpdatedRows, tempErrors) => {
    let rowIndex = tempHotData.findIndex((r) => r.id === rowId);

    // shallow copy the row
    let currentRow = { ...tempHotData[rowIndex] };

    // locally validate row to ensure it is ready to send
    let visualRow = hotInstance.toVisualRow(rowIndex);
    let isValid = new Promise(function(resolve, reject) {
      hotInstance.validateRows([visualRow], async (valid) => {
        if (!valid) {
          console.log(visualRow, valid, currentRow.lineNumber);
          if (invalidUpdatedRows.find((r) => r === rowId) === undefined) invalidUpdatedRows.push(rowId);
          tempErrors.push((currentRow.lineNumber ? "Line #" + currentRow.lineNumber + ": This row" : "A row") + " failed validation. Please verify the value of any highlighted cells.");
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
    if (await isValid === false) return;

    // nullify unnecessary object references
    for (const m in dropdowns) {
      if (dropdowns[m][0] !== 'enum' && dropdowns[m].length === 5) {
        let propName = dropdowns[m][1];
        currentRow[propName] = null;
      }
    }
    for (const p in nullProperties) {
      let propName = nullProperties[p];
      currentRow[propName] = null;
    }

    // special case for Fixed lines in Line Purpose Constraints
    if (endpoint === 'LineFieldPurposes' && currentRow.fieldValue === '' && currentRow.uiState !== 'Fixed') {
      currentRow.fieldValue = null;
    }

    // send PUT request
    console.log('Update request sent for:', currentRow);

    let token = await getToken();

    await axios
      .put(process.env.REACT_APP_API_URL + endpoint + '/' + currentRow.id, currentRow, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      .then((resp) => {
        console.log(resp);
        let tempRow = tempHotData[rowIndex];

        // set new rowVersion
        tempRow.rowVersion = resp.data.rowVersion;

        // update description1-3 on CommodityCodes and Lines after update
        if (endpoint === 'CommodityCodes' || endpoint === 'Lines') {
          tempRow.description1 = resp.data.description1 === '' ? null : resp.data.description1;
          tempRow.description2 = resp.data.description2 === '' ? null : resp.data.description2;
          tempRow.description3 = resp.data.description3 === '' ? null : resp.data.description3;
        }

        // show any returned warning messages to uuser
        if (resp.data.warningMessages !== undefined) {
          toast.warn(
            <span style={{ lineHeight: '1.5em' }}>
              {resp.data.warningMessages}
            </span>,
            {
              autoClose: false,
              closeOnClick: false,
              theme: 'colored'
            }
          );
        }
      })
      .catch((err) => {
        console.error(err, currentRow);
        if (invalidUpdatedRows.find((r) => r === rowId) === undefined) invalidUpdatedRows.push(rowId);

        // save errors
        if (typeof err.response.data === 'string') {
          // string error
          tempErrors.push(err.response.data);
        } else if (err.response.data.length > 0) {
          // array of string errors
          for (const e in err.response.data) {
            tempErrors.push(err.response.data[e]);
          }
        } else if (err.response.data.errors) {
          // array or object of string errors
          for (const e in err.response.data.errors) {
            tempErrors.push(err.response.data.errors[e]);
          }
        }
      });
  }, [dropdowns, endpoint, getToken, nullProperties, hotInstance]);

  const sendCreatedRow = useCallback(async (rowId, tempHotData, invalidCreatedRows, tempErrors) => {
    let rowIndex = tempHotData.findIndex((r) => r.id === rowId);

    // if row is empty, remove from hotData and skip
    if (rowIsEmpty(tempHotData[rowIndex])) {
      tempHotData.splice(rowIndex, 1);
      return;
    }

    // shallow copy the row & delete ID property
    let currentRow = { ...tempHotData[rowIndex] };
    delete currentRow.id;

    // locally validate row to ensure it is ready to send
    let visualRow = hotInstance.toVisualRow(rowIndex);
    let isValid = new Promise(function(resolve, reject) {
      hotInstance.validateRows([visualRow], async (valid) => {
        if (!valid) {
          console.log(visualRow, valid, currentRow.lineNumber);
          if (invalidCreatedRows.find((r) => r === rowId) === undefined) invalidCreatedRows.push(rowId);
          tempErrors.push("Line #" + currentRow.lineNumber + ": This row failed validation. Please verify the values of any cells with a red background.");
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
    if (await isValid === false) return;

    // nullify unnecessary object references
    for (const m in dropdowns) {
      if (dropdowns[m][0] !== 'enum' && dropdowns[m].length === 5) {
        let propName = dropdowns[m][1];
        currentRow[propName] = null;
      }
    }
    for (const p in nullProperties) {
      let propName = nullProperties[p];
      currentRow[propName] = null;
    }

    // special case for Fixed lines in Line Purpose Constraints
    if (endpoint === 'LineFieldPurposes' && currentRow.fieldValue === '' && currentRow.uiState !== 'Fixed') {
      currentRow.fieldValue = null;
    }

    // send POST request
    console.log('Create request sent for:', currentRow);

    let token = await getToken();

    await axios
      .post(process.env.REACT_APP_API_URL + endpoint, currentRow, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      .then((resp) => {
        console.log(resp);
        // set newly assigned ID to row
        let tempRow = tempHotData[rowIndex];
        tempRow.oldId = tempRow.id;
        tempRow.id = resp.data.id;

        // set new rowVersion
        tempRow.rowVersion = resp.data.rowVersion;

        // update description1-3 on CommodityCodes and Lines after creation
        if (endpoint === 'CommodityCodes' || endpoint === 'Lines') {
          tempRow.description1 = resp.data.description1 === '' ? null : resp.data.description1;
          tempRow.description2 = resp.data.description2 === '' ? null : resp.data.description2;
          tempRow.description3 = resp.data.description3 === '' ? null : resp.data.description3;
        }
      })
      .catch((err) => {
        console.error(err, currentRow);
        if (invalidCreatedRows.find((r) => r === rowId) === undefined) invalidCreatedRows.push(rowId);

        // save errors
        if (typeof err.response.data === 'string') {
          // string error
          tempErrors.push(err.response.data);
        } else if (err.response.data.length > 0) {
          // array of string errors
          for (const e in err.response.data) {
            tempErrors.push(err.response.data[e]);
          }
        } else if (err.response.data.errors) {
          // array or object of string errors
          for (const e in err.response.data.errors) {
            tempErrors.push(err.response.data.errors[e]);
          }
        }
      });
  }, [dropdowns, endpoint, getToken, nullProperties, rowIsEmpty, hotInstance]);

  const sendDeletedRow = useCallback(async (row, tempHotData, invalidDeletedRows, tempErrors) => {
        // skip new rows (aren't saved in db)
        if (typeof row.id === 'string' && row.id.includes('N')) return;

        // send DELETE request
        console.log('Delete request sent for:', row);

        let token = await getToken();

        await axios
          .delete(process.env.REACT_APP_API_URL + endpoint + '/' + row.id, {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + token
            }
          })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.error(err, row);
            if (invalidDeletedRows.find((r) => r === row.id) === undefined) invalidDeletedRows.push(row.id);

            // re-add row to hotData, since it was deleted locally
            tempHotData.push(row);

            // save errors
            if (typeof err.response.data === 'string') {
              // string error
              tempErrors.push(err.response.data);
            } else if (err.response.data.length > 0) {
              // array of string errors
              for (const e in err.response.data) {
                tempErrors.push(err.response.data[e]);
              }
            } else if (err.response.data.errors) {
              // array or object of string errors
              for (const e in err.response.data.errors) {
                tempErrors.push(err.response.data.errors[e]);
              }
            }
          });
        }, [endpoint, getToken]);

  const save = useCallback(async () => {
    console.log(hotData, updatedRows, createdRows, deletedRows);

    if (!hotInstance) return;

    if (dupeOnProperty) {
      // shouldn't ever get here, as 'isEditable' should be false in this case
      console.error("Error: Saving isn't allowed on tables with one-to-many relations (duplicate rows)");
      return;
    }

    if (isSaving) return; // return if we're already saving
    setIsSaving(true);

    // clear all existing toasts
    toast.dismiss();

    // shallow copy hotData for manipulation
    const tempHotData = Array.from(hotData);

    // to hold any errors encountered
    let tempErrors = [];

    // handle updated rows
    let invalidUpdatedRows = [];
    if (syncSave) {
      // synchronous save
      for (let i = 0; i < updatedRows.length; ++i) {
        await sendUpdatedRow(updatedRows[i], tempHotData, invalidUpdatedRows, tempErrors);
      }
    } else {
      // async save
      await Promise.all(
        updatedRows.map(async (rowId) => {
          await sendUpdatedRow(rowId, tempHotData, invalidUpdatedRows, tempErrors);
        })
      );
    }

    // handle created rows
    let invalidCreatedRows = [];
    if (syncSave) {
      // synchronous save
      for (let i = 0; i < createdRows.length; ++i) {
        await sendCreatedRow(createdRows[i], tempHotData, invalidCreatedRows, tempErrors);
      }
    } else {
      // async save
      await Promise.all(
        createdRows.map(async (rowId) => {
          await sendCreatedRow(rowId, tempHotData, invalidCreatedRows, tempErrors);
        })
      );
    }

    // handle deleted rows
    let invalidDeletedRows = [];
    if (syncSave) {
      // synchronous save
      for (let i = 0; i < deletedRows.length; ++i) {
        await sendDeletedRow(deletedRows[i], tempHotData, invalidDeletedRows, tempErrors);
      }
    } else {
      // async save
      await Promise.all(
        deletedRows.map(async (row) => {
          await sendDeletedRow(row, tempHotData, invalidDeletedRows, tempErrors);
        })
      );
    }

    if (invalidUpdatedRows.length > 0 || invalidCreatedRows.length > 0 || invalidDeletedRows.length > 0) {
      toast.error(
        <span style={{ lineHeight: '1.5em' }}>
          One or more rows were unable to be saved. Rows with issues will be highlighted, and a list of all row errors is available below.
        </span>,
        {
          autoClose: false,
          closeOnClick: false,
          theme: 'colored'
        }
      );

      if (setExternalHasError) setExternalHasError(true);
      setErrors(tempErrors);

      toast.info('Table save completed with errors.', {
        autoClose: TOAST_DURATIONS.medium,
        closeOnClick: false
      });
    } else {
      if (setExternalHasError) setExternalHasError(false);
      setErrors([]);

      toast.success('Table save completed successfully.', {
        autoClose: TOAST_DURATIONS.medium,
        closeOnClick: false
      });
    }

    // update initial state of table to match newly saved state
    let tempInitialHotData = Array.from(tempHotData);
    tempInitialHotData.forEach((row, idx) => {
      if (invalidUpdatedRows.includes(row.id) || invalidDeletedRows.includes(row.id)) {
        // existing row with invalid changes - keep old version in new initial state array
        let initialRowIndex = initialHotData.findIndex((r) => r.id === row.id);
        tempInitialHotData[idx] = initialHotData[initialRowIndex];
      } else if (invalidCreatedRows.includes(row.id)) {
        // new invalid row - exclude from new initial state array
        tempInitialHotData.splice(idx, 1);
      }
    });
    setInitialHotData(JSON.parse(JSON.stringify(tempInitialHotData)));

    // clear undo history to prevent issues
    hotInstance.undoRedo.clear();

    // sort data by line number if relevant
    if (endpoint === 'Lines') {
      tempHotData.sort((a, b) => {
        if (a.lineNumber > b.lineNumber) return 1;
        else if (a.lineNumber < b.lineNumber) return -1;
        else return 0;
      });
    }

    // apply changes
    setHotData(tempHotData);

    setIsSaving(false);

    // clear modified row arrays, except for rows that weren't saved successfully
    setCreatedRows(invalidCreatedRows);
    setUpdatedRows(invalidUpdatedRows);
    setDeletedRows(invalidDeletedRows);

    let allInvalidRows = [...invalidCreatedRows, ...invalidUpdatedRows, ...invalidDeletedRows];
    setInvalidRows(allInvalidRows);

    // re-fetch all rows if there's no errors
    if (allInvalidRows.length === 0 && tempErrors.length === 0) {
      reset(true);
    }

    setReloading(true);
  }, [
    updatedRows,
    sendUpdatedRow,
    createdRows,
    sendCreatedRow,
    deletedRows,
    sendDeletedRow,
    dupeOnProperty,
    endpoint,
    syncSave,
    hotData,
    hotInstance,
    initialHotData,
    isSaving,
    reset,
    setExternalHasError
  ]);

  const afterChange = (changes, source) => {
    if (changes !== null) {
      // existing row(s) modified
      let tempHotData = Array.from(hotData);

      for (const change of changes) {
        const [rowIndex, prop, oldValue, newValue] = [...change];
        let isCostTypeCodesOnLines = endpoint === 'Lines' && (prop === 'costCodeNumber' || prop === 'costTypeNumber');

        // convert to physical row
        let physRowIndex = hotInstance.toPhysicalRow(rowIndex);

        if (oldValue === newValue) continue; // value didn't change

        // set changing flag to prevent premature saves
        if (!isChanging) setIsChanging(true);

        let rowId = hotData[physRowIndex].id;
        let tempRow = tempHotData[physRowIndex];

        // change 'false' strings and nulls to false values for checkboxes
        if (tempRow.hasOwnProperty('isActive'))
          if (typeof tempRow.isActive === 'string') tempRow.isActive = tempRow.isActive === 'true';
          else if (tempRow.isActive === null) tempRow.isActive = false;
        if (tempRow.hasOwnProperty('isNotificationActive'))
          if (typeof tempRow.isNotificationActive === 'string')
            tempRow.isNotificationActive = tempRow.isNotificationActive === 'true';
          else if (tempRow.isNotificationActive === null) tempRow.isNotificationActive = false;

        // check if this is a dropdown field
        let isDropdown = false;
        let dropdownIndex;
        if (prop.includes('.')) {
          isDropdown = true;
        } else {
          for (const m in dropdowns) {
            if (prop === dropdowns[m][6] || (isCostTypeCodesOnLines && dropdowns[m][0] === 'CostTypeCodes')) {
              isDropdown = true;
              dropdownIndex = m;
              break;
            }
          }
        }

        // if dropdown, handle setting IDs and other properties
        if (isDropdown && dupeOnProperty === null) {
          // if this dropdown is an autocomplete, allow non-matching text entries
          let isAutocomplete =
            hotInstance.getCellMeta(physRowIndex, hotInstance.propToCol(changes[0][1])).type === 'autocomplete';

          // let matchedColumn = hotInstance.getSettings().columns.find((c) => c.data === prop);
          // let isValid = !!matchedColumn.source && !!matchedColumn.source.find((o) => o === newValue);

          if (prop.includes('.')) {
            // this is in a hydrated object reference
            // split property specifier into the object name (before first .) and prop name (everything after) to account for nested properties
            let [objectName, propName] = [prop.substring(0, prop.indexOf('.')), prop.substring(prop.indexOf('.') + 1)];
            let idProperty = objectName + 'Id';

            let dropdown = dropdowns.find((d) => d[1] === objectName);
            let dropdownData = dropdown[2];
            let objPropName = dropdown[5];

            // find related ID and set
            let match = dropdownData.find((m) => resolvePath(m, objPropName ?? propName) === newValue);
            if (!match && objPropName) match = dropdownData.find((m) => resolvePath(m, propName) === newValue);
            if (!match) {
              // user entered text that couldn't be matched to a record (doesn't exist)
              // set the row's obj id and all of the obj ref's properties to null (like in a new row)
              // unless cell is autocomplete instead of dropdown - then leave obj ref's properties but reset ID
              tempRow[idProperty] = null;
              if (!isAutocomplete && tempRow[objectName]) {
                let objRef = resolvePath(tempRow[objectName], propName.substring(0, propName.lastIndexOf('.')));
                Object.keys(objRef).forEach((p) => (objRef[p] = null));
              }
            } else if (newValue !== '') {
              // match found
              // set the row's obj id and the obj ref to their proper values
              tempRow[idProperty] = match.id;
              tempRow[objectName] = { ...match };
            }
          } else {
            // this is in a top-level property
            let dropdown = dropdowns[dropdownIndex];
            let propName = dropdown[6];
            let idProperty = dropdown[1] + 'Id';
            let dropdownData = dropdown[2];
            let objPropName = dropdown[5];

            // find related ID and set
            if (!isCostTypeCodesOnLines) {
              tempHotData[physRowIndex] = tempRow;

              let match = dropdownData.find((m) => resolvePath(m, objPropName) === newValue);
              if (propName === 'commodityCode' && !match) {
                match = dropdownData.find((m) => resolvePath(m, 'commodityCode1') === newValue);
              }
              if (!match) {
                // user entered text that couldn't be matched to a record (doesn't exist)
                // set the row's obj id and the top-level property's value to null
                // unless cell is autocomplete instead of dropdown - then leave value but reset ID
                tempRow[idProperty] = null;
                if (!isAutocomplete) tempRow[propName] = null;
              } else if (newValue !== '' || endpoint === 'CostTypeCodes') {
                // match found
                // set the row's obj id and the obj ref to their proper values
                tempRow[idProperty] = match.id;

                if (propName === 'commodityCode') tempRow[propName] = match.commodityCode1;
                else if (match[propName]) tempRow[propName] = match[propName];

                tempRow[objPropName] = match[objPropName];
              }

              // check if this is 'Commodity Code' on the 'Lines' table - if so, autofill some other cells
              if (endpoint === 'Lines' && dropdown[0] === 'CommodityCodes') {
                if (newValue !== '' && match) {
                  // valid commodity code entered
                  // set related values
                  tempRow['size1'] = match.size1;
                  tempRow['size2'] = match.size2;
                  tempRow['description'] = match.description;
                  tempRow['description1'] = match.description1 === '' ? null : match.description1;
                  tempRow['description2'] = match.description2 === '' ? null : match.description2;
                  tempRow['description3'] = match.description3 === '' ? null : match.description3;
                  tempRow['unitOfMeasure'] = match.unitOfMeasure;
                  tempRow['unitOfMeasureId'] = match.unitOfMeasure.id;
                } else {
                  // invalid/blank commodity code entered
                  // unset related values
                  delete tempRow['size1'];
                  delete tempRow['size2'];
                  delete tempRow['description'];
                  delete tempRow['description1'];
                  delete tempRow['description2'];
                  delete tempRow['description3'];
                  delete tempRow['unitOfMeasure'];
                  delete tempRow['unitOfMeasureId'];
                }
              }
            } else {
              // check if selected value is valid, reset if not
              if (prop === 'costTypeNumber') {
                if (!costTypeCodes.find((c) => c.typeDisplay === newValue)) {
                  tempRow[prop] = null;
                  tempRow.costTypeCodeId = null;
                }
              } else if (prop === 'costCodeNumber') {
                if (!costTypeCodes.find((c) => c.codeDisplay === newValue)) {
                  tempRow[prop] = null;
                  tempRow.costTypeCodeId = null;
                }
              }

              if (prop === 'costTypeNumber' && tempRow.costCodeNumber) {
                // set costTypeCodeId
                let match = costTypeCodes.find(
                  (c) =>
                    c.costCodeNumber === tempRow.costCodeNumber &&
                    (c.typeDisplay === newValue || c.costTypeNumber === newValue)
                );

                if (match) {
                  tempRow.costTypeCodeId = match.id;
                  tempRow.costTypeNumber = match.costTypeNumber;
                }
              } else if (prop === 'costCodeNumber') {
                // reset costTypeNumber if it isn't valid for this costCodeNumber, otherwise set new costTypeCodeId
                let match = costTypeCodes.find(
                  (c) =>
                    (c.codeDisplay === newValue || c.costCodeNumber === newValue) &&
                    c.costTypeNumber === tempRow.costTypeNumber
                );

                if (!match) {
                  tempRow.costTypeNumber = null;
                  tempRow.costTypeCodeId = null;

                  match = costTypeCodes.find((c) => c.codeDisplay === newValue || c.costCodeNumber === newValue);
                  if (match) tempRow.costCodeNumber = match.costCodeNumber;
                } else {
                  tempRow.costTypeCodeId = match.id;
                  tempRow.costCodeNumber = match.costCodeNumber;
                }
              }
            }
          }

          // allows cell validation to run after a dropdown selection is made
          setIsEditingCell(false);
        }

        if (['rasDate', 'endDate'].includes(prop)) {
          // check if End Date is before RAS Date - if so, clear End Date
          if (tempRow.rasDate && tempRow.endDate && new Date(tempRow.endDate) < new Date(tempRow.rasDate)) {
            tempRow.endDate = null;
          }

          // allows cell validation to run after a date selection is made
          setIsEditingCell(false);
        }

        // add row to updatedRows if necessary
        if (typeof rowId === 'number' || (typeof rowId === 'string' && rowId.charAt(0) !== 'N')) {
          // row is not newly created

          if (!updatedRows.includes(rowId)) {
            // row has not already been added
            // compare current row to initial row to check if it's modified
            let initialRow = initialHotData.find((r) => r.id === rowId);
            if (!rowsMatch(tempRow, initialRow)) {
              // rows don't match, so this row is modified
              // add row to updatedRows
              setUpdatedRows((rows) => rows.concat(rowId));
            }
          } else {
            // costTypeCode logic will be handled further down
            // row has already been added
            // compare current row to initial row to check if it's still modified
            let initialRow = initialHotData.find((r) => r.id === rowId);
            if (rowsMatch(tempRow, initialRow)) {
              // rows match, so this row is unmodified
              // remove row from updatedRows
              setUpdatedRows((rows) => rows.filter((r) => r !== rowId));
            }
          }
        }
      }
        
      // apply changes
      setHotData(tempHotData);
    }

    // reset changing flag to allow saves again
    if (isChanging) setIsChanging(false);
  };

  // sets read-only to proper cells based on 'constraints' prop
  // if a 'required' field is not populated, all cells in 'reliant' will be made read-only
  const cellConstraints = (cp, prop, tempRow) => {
    const initialRO = cp.cellProperties.readOnly;
    cp.cellProperties.readOnly = false;
    let cellIsConstrained = false;
    let valid = true;

    // return read-only if entire table is set to read-only
    if (!isEditing) {
      cp.cellProperties.readOnly = true;
      return;
    }

    for (const c in constraints) {
      let { required, reliant } = constraints[c];

      // skip this loop, as this cell isn't constrained, or has already failed the check
      if (!reliant.includes(prop) || !valid) break;

      // cell found in at least one constraints.reliant array
      cellIsConstrained = true;

      // check that required fields are not empty
      for (const requiredProp of required) {
        const requiredValue = tempRow[requiredProp];
        if (!requiredValue) {
          valid = false;
          break;
        }
      }

      // return read-only and unset the value as soon as we find a lacking requirement
      if (!valid) {
        cp.cellProperties.readOnly = true;
        if (prop === 'isActive') tempRow[prop] = false;
        else tempRow[prop] = null;
        return;
      }
    }

    if (!cellIsConstrained) {
      // cell is not constrained, so leave it alone
      cp.cellProperties.readOnly = initialRO;
    }
  };

  const afterCreateRow = (rowIndex, source) => {
    if (rowIndex !== null && source !== 'UndoRedo.undo') {
      // convert to physical row
      let physRowIndex = hotInstance.toPhysicalRow(rowIndex);

      // prepare row for setting properties
      let tempRow = hotData[physRowIndex];

      // check if we need to set project ID, job ID, and/or req ID
      if (projectId) tempRow.projectId = projectId;
      if (jobId) tempRow.jobId = jobId;
      if (reqId) tempRow.requisitionId = reqId;

      // check if we need to set lineStateId and lineNumber
      if (endpoint === 'Lines') {
        tempRow.lineStateId = 1;
        setNextLineNumber((n) => {
          tempRow.lineNumber = n;
          return n + 1;
        });
      }

      // change 'false' strings and nulls to false values for checkboxes
      if (tempRow.hasOwnProperty('isActive'))
        if (typeof tempRow.isActive === 'string') tempRow.isActive = tempRow.isActive === 'true';
        else if (tempRow.isActive === null) tempRow.isActive = false;
      if (tempRow.hasOwnProperty('isNotificationActive'))
        if (typeof tempRow.isNotificationActive === 'string')
          tempRow.isNotificationActive = tempRow.isNotificationActive === 'true';
        else if (tempRow.isNotificationActive === null) tempRow.isNotificationActive = false;

      // update row's project ID to current project ID, if necessary
      if (projectId) tempRow.projectId = projectId;

      setCreatedCount((c) => {
        // define new ID for row
        let tempId = 'N' + c;

        // set ID to newly defined ID
        tempRow.id = tempId;

        // add row to createdRows
        setCreatedRows((rows) => rows.concat(tempId));

        return c + 1;
      });

      // apply changes
      setHotData((d) => d.filter((r) => r.id !== tempRow.id).concat(tempRow));

      // select and scroll to new row
      hotInstance.selectCell(rowIndex, 1);

      toast.info('Row added', {
        autoClose: TOAST_DURATIONS.short,
        closeOnClick: false
      });
    }
  };

  const afterUndo = (action) => {
    if (action.actionType !== 'remove_row') return;
    console.log(action);
    // row(s) just un-deleted

    let tempHotData = Array.from(hotData);
    let undeletedRows = Array(action.data.length)
      .fill(action.index)
      .map((x, y) => x + y);

    for (const index in undeletedRows) {
      let rowIndex = undeletedRows[index];
      let rowId = action.data[index].id;
      let tempRow = tempHotData[rowIndex];
      let tempDeletedRow = deletedRows.find((r) => r.id === rowId);

      console.log(index, rowIndex, rowId, tempRow, tempDeletedRow);

      // set all values as before deletion
      for (const prop in tempDeletedRow) {
        tempRow[prop] = tempDeletedRow[prop];
      }

      // remove row from deletedRows
      setDeletedRows((rows) => rows.filter((r) => r.id !== rowId));

      // check if modified rows arrays need to be updated
      if (typeof rowId === 'string' && rowId.includes('N')) {
        // row is a string and includes 'N' marker, thus is newly created
        // re-add row to createdRows
        setCreatedRows((rows) => rows.concat(rowId));
      } else {
        // row is pre-existing and might be modified
        // compare current row to initial row to determine
        let initialRowIndex = initialHotData.findIndex((r) => r.id === rowId);
        if (!rowsMatch(hotData[rowIndex], initialHotData[initialRowIndex])) {
          // rows don't match, so this row is modified
          // re-add row to updatedRows
          setUpdatedRows((rows) => rows.concat(rowId));
        }
      }
    }

    // apply changes
    setHotData(tempHotData);

    toast.info(action.data.length + ' row(s) re-added', {
      autoClose: TOAST_DURATIONS.short,
      closeOnClick: false
    });
  };

  const beforeRemoveRow = (rowIndex, amount, source) => {
    if (!addRemoveRows) return false; // disable row deletion if specified

    // convert to physical row
    let physRowIndex = hotInstance.toPhysicalRow(rowIndex);

    if (physRowIndex !== null) {
      let tempHotData = Array.from(hotData);
      for (let i = 0; i < amount; ++i) {
        let tempRow = tempHotData[physRowIndex + i];
        let rowId = tempRow.id;

        if (typeof rowId === 'string') {
          // row is newly created (thus ID is a string), not saved in db yet
          // remove row ID from createdRows
          setCreatedRows((rows) => rows.filter((r) => r !== rowId));
        } else {
          // row exists in db
          if (updatedRows.includes(rowId)) {
            // row has been updated already
            // remove row ID from updatedRows
            setUpdatedRows((rows) => rows.filter((r) => r !== rowId));
          }
        }

        // add entire row to deletedRows (for possible un-deletion later)
        if (deletedRows.find((r) => r === rowId) === undefined) {
          setDeletedRows((rows) => [...rows, tempRow]);
        }
      }

      // show appropriate toast based on source
      if (source === 'UndoRedo.undo') {
        toast.info(amount + ' row' + (amount === 1 ? '' : 's') + ' un-created', {
          autoClose: TOAST_DURATIONS.short,
          closeOnClick: false
        });
        return;
      } else {
        toast.info(amount + ' row' + (amount === 1 ? '' : 's') + ' deleted', {
          autoClose: TOAST_DURATIONS.short,
          closeOnClick: false
        });
      }
    }
  };

  const beforeCreateRow = () => {
    if (!addRemoveRows) return false;
  };

  const undoAll = () => {
    // while (hotInstance.isUndoAvailable()) {
    //   hotInstance.undo();
    // }

    reset();

    toast.info('Undo All completed (data refreshed)', {
      autoClose: TOAST_DURATIONS.medium,
      closeOnClick: false
    });
  };

  const editButtons = () => {
    if (endpoint === 'Lines') {
      return (
        <div className='card-buttons' style={{ gridColumn: 2 }}>
          <ButtonGroup>
            <Button
              onClick={() => hotInstance.undo()}
              disabled={!isEditing || !hotInstance || !hotInstance.isUndoAvailable()}
            >
              Undo
            </Button>
            <Button
              onClick={() => undoAll()}
              disabled={
                !isEditing // ||
                // (createdRows.length === 0 &&
                //   updatedRows.length === 0 &&
                //   deletedRows.length === 0 &&
                //   invalidRows.length === 0)
              }
            >
              Undo All
            </Button>
          </ButtonGroup>
        </div>
      );
    } else if (endpoint === 'RequisitionReviews/MyPending') {
      return <></>;
    } else {
      return (
        <div className='card-buttons' style={{ gridColumn: 2 }}>
          <ButtonGroup>
            <Button togglable={true} selected={isEditing} onClick={() => setIsEditing(!isEditing)}>
              Edit
            </Button>
            <Button onClick={() => hotInstance.undo()} disabled={!isEditing || !hotInstance.isUndoAvailable()}>
              Undo
            </Button>
            <Button
              onClick={() => undoAll()}
              disabled={
                !isEditing // ||
                // (createdRows.length === 0 &&
                //   updatedRows.length === 0 &&
                //   deletedRows.length === 0 &&
                //   invalidRows.length === 0)
              }
            >
              Undo All
            </Button>
            <Button onClick={() => setHasBeganSave(true)} disabled={!isEditing}>
              Save
            </Button>
          </ButtonGroup>
        </div>
      );
    }
  };

  const addRemoveButtons = () => {
    return (
      <div className='add-remove-buttons'>
        {isEditing ? (
          <ButtonGroup>
            <Button
              data-tip='Add row'
              onClick={() => hotInstance.alter('insert_row_above', hotInstance.countRows(), 1)}
              disabled={reqPermissions !== undefined && !hasPermission('Line.Create')}
            >
              +
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </div>
    );
  };

  // calculate table height
  const calculateHeight = useCallback(() => {
    if (hotInstance == null || hotInstance.rootElement == null) return 0; // prevent errors when run before table is rendered

    // table
    let hotRect = hotInstance.rootElement.getBoundingClientRect();
    // table container
    let cardRect = cardContainer.current.getBoundingClientRect();
    // table container bottom margin in px
    let cardMargin = parseInt(window.getComputedStyle(cardContainer.current).getPropertyValue('margin-bottom'), 10);
    // remaining space on the page, without causing a page scrollbar to appear
    let calcHeight =
      window.innerHeight -
      (hotRect.top + window.scrollY) -
      (cardRect.bottom + window.scrollY - (hotRect.bottom + window.scrollY)) -
      cardMargin;

    /*
    // actual height of table, accounting for all rows
    let actualHeight = document.querySelector('.htColumnHeaders > .ht_master > .wtHolder > .wtHider').offsetHeight;
    */

    // minimum height of 360px
    let calcMinHeight;
    if (minHeight === 'fullscreen') {
      // we want this table to be nicely 'fullscreenable',
      // so subtract height needed for other elements in the card container
      calcMinHeight =
        window.innerHeight -
        (hotRect.top + window.scrollY - (cardRect.top + window.scrollY)) -
        (cardRect.bottom + window.scrollY - (hotRect.bottom + window.scrollY)) -
        cardMargin * 2;
    } else {
      calcMinHeight = minHeight ? minHeight : 360;
    }

    return Math.max(calcHeight, calcMinHeight);
  }, [hotInstance, minHeight]);
  const handleResize = useCallback(() => {
    if (hotInstance && !hotInstance.isDestroyed) {
      hotInstance.updateSettings({
        height: calculateHeight()
      });
    }
  }, [calculateHeight, hotInstance]);

  // resize listener for dynamic table height
  useEffect(() => {
    window.addEventListener('resize', () => handleResize(), false);
  }, [handleResize]);
  useEffect(() => {
    if (isLoaded) {
      handleResize();
    }
  }, [isLoaded, handleResize]);

  // watch for rows to delete from parent
  useEffect(() => {
    if (removeRow.get) {
      // find and remove the row
      let index = hotData.findIndex((r) => r.id === removeRow.get);
      let tempHotData = Array.from(hotData);
      tempHotData.splice(index, 1);

      // apply changes
      setHotData(tempHotData);

      // reset removeRow value
      removeRow.set(null);
    }
  }, [hotData, removeRow]);

  // watch for rows to insert from parent
  useEffect(() => {
    if (insertRow.get?.index) {
      // insert the row
      let index = insertRow.get.index;
      let rowData = insertRow.get.rowData;
      let tempHotData = Array.from(hotData);
      tempHotData.splice(index, 0, rowData);

      // apply changes
      setHotData(tempHotData);
      
      // add row to createdRows
      setCreatedRows((rows) => rows.concat(rowData.id));

      // reset insertRow value
      insertRow.set({ index: null, rowData: null });

      // set flag to re-filter
      setRefilter(true);
    }
  }, [hotData, insertRow, hotInstance]);
  useEffect(() => {
    if (refilter) {
      // save current scroll position (row) to scroll back to after re-filtering
      let scrollRow = hotInstance.getPlugin('AutoRowSize').getFirstVisibleRow();

      // re-filter data
      const filtersPlugin = hotInstance.getPlugin('filters');
      filtersPlugin.filter();

      // re-sort data
      const columnSortPlugin = hotInstance.getPlugin('multiColumnSorting');
      if (columnSortPlugin.enabled) columnSortPlugin.sort(columnSortPlugin.getSortConfig());

      // scroll back to original position
      hotInstance.scrollViewportTo(scrollRow);

      // reset refilter flag
      setRefilter(false);
    }
  }, [refilter, hotInstance]);

  // save - useEffect ensures all table updates are finished before saving
  useEffect(() => {
    //console.log(hasBeganSave, !isSaving, !isEditingCell, !isChanging);
    if (hasBeganSave && !isSaving && !isEditingCell && !isChanging) {
      setHasBeganSave(false);
      save();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving, hasBeganSave, isEditingCell, isChanging]);

  useEffect(() => {
    if (externalSave && externalSave.get) {
      // start internal save process
      setHasBeganSave(true);
      // reset external save state
      externalSave.set(false);
    }
  }, [externalSave]);

  // update externalHotData if necessary
  useEffect(() => {
    if (setExternalHotData && hotData && isLoaded) setExternalHotData(Array.from(hotData));
  }, [hotData, isLoaded, setExternalHotData, createdRows, updatedRows, deletedRows]);

  // update externalEdit if necessary
  useEffect(() => {
    if (setExternalEdit) setExternalEdit(isEditing);
  }, [setExternalEdit, isEditing]);

  // prompt before navigation if table is dirty
  usePrompt(
    'Are you sure you want to leave? This table has not been saved, and any changes you have made may be lost.',
    showDirtyPrompt && (updatedRows.length > 0 || createdRows.length > 0 || deletedRows.length > 0)
  );
  // update externalDirty if necessary
  useEffect(() => {
    if (!setExternalDirty) return;
    if (updatedRows.length > 0 || createdRows.length > 0 || deletedRows.length > 0) setExternalDirty(true);
    else setExternalDirty(false);
  }, [createdRows.length, deletedRows.length, updatedRows.length, setExternalDirty]);

  // rebuild tooltips
  useEffect(() => ReactTooltip.rebuild(), [isEditing]);
  
  // manually validate after each state change (required for current design)
  useEffect(() => {
    async function validate() {
      if (hotInstance && isLoaded && !isEditingCell && isEditable) {
        // save user's current selection before validation
        let selection = await hotInstance.getSelected();
  
        hotInstance.validateCells();
  
        if (selection) {
          // restore user's selection
          hotInstance.selectCells(selection);
        }
      }
    }
    
    validate();
  });

  // set column settings after table is loaded
  useEffect(() => {
    if (hotInstance && !hotColumnSettings) {
      let hotSettings = hotInstance.getSettings();
      if (hotSettings && hotSettings.columns && hotSettings.columns.length > 0)
        setHotColumnSettings(hotSettings.columns);
    }
  }, [hotColumnSettings, hotInstance, hotData]);

  const downloadCsv = () => {
    hotInstance.getPlugin('exportFile').downloadFile('csv', {
      rowHeaders: false,
      columnHeaders: true,
      range: endpoint === 'RequisitionReviews/MyPending' ? [0, 0, hotData.length, 7] : [],
      filename:
        endpoint +
        '-[YYYY]-[MM]-[DD]T' +
        new Date(Date.now()).getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
        '-' +
        new Date(Date.now()).getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 }) +
        '-' +
        new Date(Date.now()).getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 })
    });
  };

  // setup context menu for table (cell right-click)
  let items = {};
  if (isEditing) {
    // removed options, as new rows are always added to the end
    // if (addRemoveRows && (reqPermissions === undefined || hasPermission('Line.Create'))) {
    //   items.row_above = null;
    //   items.row_below = null;
    //   items.sp1 = '---------';
    // }
    if (addRemoveRows && (reqPermissions === undefined || hasPermission('Line.Delete'))) {
      items.remove_row = null;
      items.sp2 = '---------';
    }
    items.undo = null;
    items.sp3 = '---------';
    items.cut = { name: 'Cut' };
    items.copy = { name: 'Copy' };
    items.paste = { name: 'Paste' };
    items.sp4 = '---------';
  } else {
    items.copy = { name: 'Copy' };
    items.sp1 = '---------';
  }
  items.exportCsv = {
    name: 'Export current view to CSV',
    callback() {
      downloadCsv();
    }
  };

  let tableReadOnly = !isEditing || !isEditable || !editAllowed || reqPermissions === null;

  return (
    <div>
      <div
        className='card-container grid'
        style={{
          gridTemplateColumns: 'repeat(2, 1fr)',
          display: !isLoaded && 'none',
          minWidth: '600px'
        }}
        ref={cardContainer}
      >
        {addRemoveRows && isEditable && addRemoveButtons()}
        {editAllowed && isEditable && editButtons()}
        <div className='card-component'>
          <HotTable
            data={hotData ?? []}
            dataSchema={dataSchema}
            ref={hot}
            readOnly={tableReadOnly}
            contextMenu={{ items: items }}
            dropdownMenu={['filter_by_condition', '---------', 'filter_by_value', '---------', 'filter_action_bar']}
            multiColumnSorting={endpoint === 'CostTypeCodes' ? false : {
              compareFunctionFactory: (sortOrder, columnMeta) => {
                // custom sorting required, as HOT default has strange behavior with alphanumeric
                return function (value, nextValue) {
                  if (!hotData) return;
                  if (sortOrder === 'asc') {
                    if (value === null) return -1;
                    if (nextValue === null) return 1;
                    return value.toString().localeCompare(nextValue.toString(), 'en', { numeric: true });
                  } else if (sortOrder === 'desc') {
                    if (nextValue === null) return -1;
                    if (value === null) return 1;
                    return nextValue.toString().localeCompare(value.toString(), 'en', { numeric: true });
                  }
                };
              },
              initialConfig: initialSort
            }}
            autoColumnSize={false}
            filters={true}
            licenseKey={HOT_LICENSE_KEY}
            afterChange={(changes, source) => afterChange(changes, source)}
            afterCreateRow={(rowIndex, _, source) => afterCreateRow(rowIndex, source)}
            afterUndo={(action) => afterUndo(action)}
            beforeRemoveRow={(rowIndex, amount, _, source) => beforeRemoveRow(rowIndex, amount, source)}
            afterBeginEditing={() => {
              setIsEditingCell(true);
            }}
            afterSelection={() => {
              setIsEditingCell(false);
            }}
            afterDeselect={() => {
              setIsEditingCell(false);
            }}
            beforeChange={() => {
              setIsChanging(true);
            }}
            beforeCreateRow={beforeCreateRow}
            height={calculateHeight()}
            renderAllRows={false}
            colWidths={colData.widths}
            colHeaders={colData.names}
            columnHeaderHeight={headerHeight * 8 + 20}
            hiddenColumns={{
              columns: [0]
            }}
            cells={(row, col, prop) => {
              if (!hotData || (!isEditable && !isRevisionHistory)) return;

              let cp = { cellProperties: {} }; // object so other functions can modify

              // get table/row data ready in case changes are needed
              let tempHotData = Array.from(hotData);
              let tempRow = tempHotData[row];
              const initTempRow = { ...tempRow };

              if (!tempRow) return;

              // run cells prop function
              if (cellsFunc) cellsFunc(cp, row, col, prop);

              // run constraints function if any constraints exist
              if (constraints.length > 0) cellConstraints(cp, prop, tempRow);

              // per-line permission constraints
              if (['Project.Edit', 'Job.Edit', 'Project.SharedMailbox.Edit'].includes(editPermission)) {
                if (projectsAllowed) {
                  // lock any rows that aren't linked to a project with one of these IDs
                  let permitted = true;
                  if (endpoint === 'Projects') {
                    permitted = projectsAllowed.some((p) => p === tempRow.id);
                  } else if (endpoint === 'Jobs') {
                    permitted = projectsAllowed.some((p) => p === tempRow.project.id);
                  }

                  // lock and set gray background
                  if (!permitted) {
                    cp.cellProperties.readOnly = true;
                    cp.cellProperties.className =
                      (cp.cellProperties.className ? cp.cellProperties.className + ' ' : '') +
                      hotColumnSettings[col].className +
                      ' unpermitted';
                  }
                }
              }

              // add strike-throughs and make row read-only for inactive projects and jobs
              let active = true;
              if (['Project.Edit', 'Job.Edit', 'Project.SharedMailbox.Edit'].includes(editPermission)) {
                if (endpoint === 'Projects') {
                  if (!tempRow.jdeProject.isActive) {
                    cp.cellProperties.readOnly = true;
                    if (['jdeProject.project', 'jdeProject.description'].includes(prop)) active = false;
                  }
                } else if (endpoint === 'Jobs') {
                  if (!tempRow.jdeJob.isActive) {
                    cp.cellProperties.readOnly = true;
                    if (prop === 'jdeJob.job') active = false;
                  }
                  if (!tempRow.project.jdeProject.isActive) {
                    cp.cellProperties.readOnly = true;
                    if (prop === 'jdeProjectNumber') active = false;
                  }
                }
                if (!active) {
                  cp.cellProperties.className =
                    (cp.cellProperties.className ? cp.cellProperties.className + ' ' : '') +
                    hotColumnSettings[col].className +
                    ' inactive';
                }
              }

              // special constraint for fields on Lines table in requisition details based on commodity code
              if (endpoint === 'Lines') {
                if (hotInstance) {
                  const ccConstrained = ['size1', 'size2', 'description', 'unitOfMeasure.unit'];
                  if (ccConstrained.includes(prop)) {
                    if (tempRow.commodityCodeId) cp.cellProperties.readOnly = true;
                    else cp.cellProperties.readOnly = false;
                  }
                }

                // process LineFieldPurposes (constraints) on Lines table as necessary
                if (prop !== 'purpose.name') {
                  let purposeId = tempRow.purposeId;
                  if (purposeId) {
                    // match this row to a LineField by property name (with first letter case matching)

                    // if this is a dropdown, must get it's ID property name to match LineField.propertyName
                    let propName = prop;
                    if (
                      ['dropdown', 'autocomplete'].includes(hotColumnSettings[col].type) &&
                      prop !== 'commodityCode'
                    ) {
                      if (propName.includes('.')) propName = propName.split('.')[0];
                      if (['costCodeNumber', 'costTypeNumber'].includes(propName)) propName = 'costTypeCode';
                      propName += 'Id';
                    }

                    let lineField = lineFields.find(
                      (f) => f.propertyName === propName.charAt(0).toUpperCase() + propName.slice(1)
                    );
                    if (lineField) {
                      // match a LineFieldPurpose to this lineFieldId/purposeId
                      let lineFieldPurpose = lineFieldPurposes.find(
                        (p) => p.lineFieldId === lineField.id && p.purposeId === purposeId
                      );
                      if (lineFieldPurpose) {
                        switch (lineFieldPurpose.uiState) {
                          case 'Optional':
                            // no action needed
                            break;
                          case 'Mandatory':
                            // no action needed - validation handled through API on save
                            break;
                          case 'NonEditable':
                            // set read-only
                            cp.cellProperties.readOnly = true;
                            break;
                          case 'Fixed':
                            // set this cell's value to the specified fieldValue
                            // logic will change depending on column (whether the fieldValue is an id reference or just a value)
                            if (['unitOfMeasureId'].includes(propName)) {
                              // dropdown values
                              // set the dropdown object and ID
                              let tempProp = prop;
                              if (tempProp.includes('.')) tempProp = tempProp.split('.')[0];

                              let dropdown = dropdowns.find((d) => d[1] === tempProp);
                              let dropdownData = dropdown[2];
                              let match = dropdownData.find((m) => m.id === parseInt(lineFieldPurpose.fieldValue));

                              // there may be no match given the current project/job/category, so skip the rest (no read-only)
                              if (!match) break;

                              tempRow[propName] = match.id; // set ID
                              tempRow[tempProp] = { ...match }; // set dropdown object
                            } else if (propName === 'costTypeCodeId') {
                              // cost code/type values
                              // set their dropdown values and costCodeTypeId
                              let costTypeCode = costTypeCodes.find(
                                (c) => c.id === parseInt(lineFieldPurpose.fieldValue)
                              );

                              // there may be no match given the current project/job/category, so skip the rest (no read-only)
                              if (!costTypeCode) break;

                              tempRow[prop] = costTypeCode[prop]; // set dropdown value
                              tempRow[propName] = costTypeCode.id; // set ID
                            } else {
                              // normal values
                              tempRow[propName] = lineFieldPurpose.fieldValue; // set value

                              // these fields should be parsed to floats (numeric)
                              if (['size1', 'size2', 'quantity', 'poQuantity'].includes(propName))
                                tempRow[propName] = parseFloat(tempRow[propName]);
                            }

                            // fixed, so lock this field
                            cp.cellProperties.readOnly = true;
                            break;
                          default:
                            break;
                        }
                      }
                    }
                  } else if (prop !== 'selected') {
                    // no purpose is chosen, so lock other cells in the row
                    cp.cellProperties.readOnly = true;
                  }
                } else if (prop === 'purpose.name') {
                  // lock purpose if a value for it has been selected
                  if (tempRow.purposeId) cp.cellProperties.readOnly = true;
                }

                // filter down cost types based on the row's cost code
                if (costTypeCodes) {
                  if (prop === 'costTypeNumber') {
                    let costTypes = costTypeCodes
                      .filter((c) => c.costCodeNumber === tempRow.costCodeNumber && c.isActive)
                      .map((c) => c.typeDisplay);

                    cp.cellProperties.source = costTypes;
                  } else if (prop === 'costCodeNumber') {
                    if (!hotInstance.getSettings().columns.find((c) => c.data === 'costCodeNumber').source) {
                      let costCodes = costTypeCodes
                        .filter(
                          (c, idx) => costTypeCodes.findIndex((o) => o.costCodeNumber === c.costCodeNumber) === idx
                        )
                        .map((c) => c.codeDisplay);

                      cp.cellProperties.source = costCodes;
                    }
                  }
                }

                // disable selection checkboxes for new, unsaved lines
                // if (
                //   prop === 'selected' &&
                //   typeof tempRow.id === 'string' &&
                //   tempRow.id.includes('N')
                // ) {
                //   cp.cellProperties.readOnly = true;
                // }

                // lock fields based on user permissions
                if (reqPermissions) {
                  let lineState = tempRow.lineStateId;
                  let permitted = true;

                  switch (prop) {
                    case 'purpose.name':
                      if (!hasPermission('Line.Purpose.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'partNumber':
                      if (!hasPermission('Line.PartNumber.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'commodityCode':
                      if (!hasPermission('Line.CommodityCode.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'size1':
                      if (!hasPermission('Line.Size 1.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'size2':
                      if (!hasPermission('Line.Size 2.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'description':
                      if (!hasPermission('Line.Description.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'quantity':
                      if (!hasPermission('Line.Quantity.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'unitOfMeasure.unit':
                      if (!hasPermission('Line.UoM.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'costCodeNumber':
                      if (!hasPermission('Line.CostCode.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'costTypeNumber':
                      if (!hasPermission('Line.CostTypeCode.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'rasDate':
                      if (!hasPermission('Line.RasDate.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'endDate':
                      if (!hasPermission('Line.EndDate.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'purchaseDetails':
                      if (!hasPermission('Line.PurchaseDetails.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'rentalNumber':
                      if (!hasPermission('Line.RNumber.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'poNumber':
                      if (!hasPermission('Line.PoNumber.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'poLine':
                      if (!hasPermission('Line.PoLine.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'poQuantity':
                      if (!hasPermission('Line.PoQuantity.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'poVersion':
                      if (!hasPermission('Line.PoRevision.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'description1':
                      if (!hasPermission('Line.Description1.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'description2':
                      if (!hasPermission('Line.Description2.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    case 'description3':
                      if (!hasPermission('Line.Description3.Edit', lineState)) {
                        permitted = false;
                      }
                      break;
                    default:
                      break;
                  }

                  // lock and set gray background
                  if (!permitted) {
                    cp.cellProperties.readOnly = true;
                    cp.cellProperties.className =
                      (cp.cellProperties.className ? cp.cellProperties.className + ' ' : '') +
                      hotColumnSettings[col].className +
                      ' unpermitted';
                  }
                }
              }

              // convert properties into links as specified in linkProps
              if (
                linkProps.length > 0 &&
                ((typeof tempRow[prop] === 'string' && !tempRow[prop].includes('<a href')) ||
                  typeof tempRow[prop] !== 'string')
              ) {
                let match = linkProps.find((l) => l.prop === prop);
                if (match) {
                  let prefix = match.prefix ?? '';
                  let suffix = match.suffixProp !== undefined ? '-' + tempRow[match.suffixProp] : '';
                  tempRow[prop] = '<a href="' + prefix + tempRow[prop] + suffix + '">' + tempRow[prop] + '</a>';
                }
              }

              // set blank strings to null
              if (!endpoint === 'CostTypeCodes' && tempRow[prop] === '') {
                tempRow[prop] = null;
              }

              // highlight invalid rows
              let invalid = invalidRows.findIndex((r) => r === tempRow.id) > -1;
              if (invalid) {
                cp.cellProperties.className =
                  (cp.cellProperties.className ? cp.cellProperties.className + ' ' : '') +
                  hotColumnSettings[col].className +
                  ' invalid';
              }

              // bold changed values compared to previous revision
              if (revisionDiff && (revisionDiff.newLineIds?.length > 0 || revisionDiff.changedLines?.length > 0)) {
                if (revisionDiff.newLineIds.includes(tempRow.id)) {
                  // row is new - always bold
                  cp.cellProperties.className =
                  (cp.cellProperties.className ? cp.cellProperties.className + ' ' : '') +
                  hotColumnSettings[col].className +
                  ' changed';
                } else if (revisionDiff.changedLines.find((l) => l.lineId === tempRow.id && l.lineProperties.includes(prop))) {
                  // this field on this line has changed - bold
                  cp.cellProperties.className =
                  (cp.cellProperties.className ? cp.cellProperties.className + ' ' : '') +
                  hotColumnSettings[col].className +
                  ' changed';
                }
              }

              // if column is specifically set read-only, make sure it always is
              if (hotColumnSettings && hotColumnSettings[col].readOnly) cp.cellProperties.readOnly = true;

              // update hotData if tempRow is changed
              if (Object.entries(tempRow).sort().toString() !== Object.entries(initTempRow).sort().toString())
                setHotData(tempHotData);

              // final read-only check, based on table's read-only state
              if (tableReadOnly) {
                cp.cellProperties.readOnly = true;
              }

              return cp.cellProperties;
            }}
          >
            {children}
          </HotTable>
        </div>
        {extra}
      </div>
      {!isLoaded && <LoadingCard />}
      {showSaveOverlay && isSaving && <SaveOverlay />}
      {errors.length > 0 && (
        <div>
          <div className='card-container'>
            <h2 style={{ marginBottom: '6px', color: '#587aff' }}>Row Errors</h2>
            <div style={{ textAlign: 'left', lineHeight: '2em' }}>
              {errors.map((e, idx) => (
                <span key={'row-error-' + idx}>
                  {e}
                  <br />
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
