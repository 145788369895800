import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function UoM() {
  const colData = {
    widths: [0, 110, 160, 160, 160, 90, 160, 70],
    names: [
      'ID',
      'Unit*',
      'Description*',
      'Measurement Type',
      'Measurement System',
      'JDE Unit*',
      'JDE Description',
      'Active'
    ]
  };

  return (
    <div id='UoM'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Unit of Measures</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'UnitOfMeasures'}
        colData={colData}
        constraints={[{ required: ['unit', 'description', 'jdeUnit'], reliant: ['isActive'] }]}
        editPermission={'UnitOfMeasure.Edit'}
        dataSchema={{
          isActive: false
        }}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'unit'} className='htLeft' />
        <HotColumn data={'description'} className='htLeft' />
        <HotColumn data={'measurementType'} className='htLeft' />
        <HotColumn data={'measurementSystem'} className='htLeft' />
        <HotColumn data={'jdeUnit'} className='htLeft' />
        <HotColumn data={'jdeDescription'} className='htLeft' />
        <HotColumn data={'isActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default UoM;
