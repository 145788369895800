import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@progress/kendo-react-buttons';

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button variant='secondary' className='ml-auto' onClick={() => handleLogout(instance)}>
      <span className={'k-icon k-i-logout'} />
    </Button>
  );
};
