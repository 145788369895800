import React, { useState, useCallback, useEffect } from 'react';
import * as rax from 'retry-axios';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest, timeoutSetting } from '../authConfig';
import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LabelledInput } from './LabelledInput';
import { LoadingCard } from './LoadingCard';

export const RequisitionDetailsHeader = ({
  reqRevNum,
  setReqId,
  reqPermissions,
  setReqNum,
  reqNum,
  setProjectId,
  setJobId,
  setCategoryId,
  setSaveType,
  saveType,
  lineData,
  linesLoaded,
  linesSaved,
  attachmentsSaved,
  linesSelected,
  setHeaderSaved,
  headerSaved,
  setReqState,
  setReqRevision,
  setRevisionDiff,
  locked,
  setDirty,
  dirty,
  redirected,
  setRedirected,
  setHeaderErrors,
  isRevisionHistory,
  AttachmentList
}) => {
  const NL = { NotLoaded: true };

  const [initialReq, setInitialReq] = useState(null);

  const [revisions, setRevisions] = useState(null);
  const [selectedRevision, setSelectedRevision] = useState(null);

  const [id, setId] = useState(null);

  const [project, setProject] = useState(NL);
  const [job, setJob] = useState(NL);
  const [category, setCategory] = useState(NL);
  const [reqNumber, setReqNumber] = useState(NL);
  const [revNumber, setRevNumber] = useState(NL);
  const [state, setState] = useState(NL);
  const [requisitioner, setRequisitioner] = useState(NL);

  const [priority, setPriority] = useState(NL);
  const [deliveryLocation, setDeliveryLocation] = useState(NL);
  const [pressureRetMat, setPressureRetMat] = useState(NL);
  const [reason, setReason] = useState(reqRevNum ? NL : {});

  const [description, setDescription] = useState(NL);
  const [reference, setReference] = useState(NL);
  const [workPackage, setWorkPackage] = useState(NL);
  const [turnoverSystem, setTurnoverSystem] = useState(NL);

  const [suggestedSrc, setSuggestedSrc] = useState(NL);
  const [instructions, setInstructions] = useState(NL);
  const [comments, setComments] = useState(NL);

  const [rowVersion, setRowVersion] = useState(null);
  const [reqDate, setReqDate] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

// attach retry-axios interceptor to axios (retries 3x by default)
  rax.attach();

  // dirty checking
  if (setDirty) {
    if (
      initialReq &&
      ((priority.id && initialReq.priority && priority.id !== initialReq.priorityId) ||
        (deliveryLocation.id &&
          initialReq.deliveryLocation &&
          deliveryLocation.id !== initialReq.deliveryLocation.id) ||
        pressureRetMat !== initialReq.pressureRetainingMaterial ||
        (reason.id && initialReq.reasonForPurchase && reason.id !== initialReq.reasonForPurchase.id) ||
        description !== initialReq.description ||
        reference !== initialReq.reference ||
        workPackage !== initialReq.workPackage ||
        turnoverSystem !== initialReq.turnoverSystem ||
        suggestedSrc !== initialReq.suggestedSources ||
        instructions !== initialReq.instructionsToPurchasing ||
        comments !== initialReq.comments)
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }

  const hasPermission = (permission) => {
    // p[0] - permission is for this requisitionStateId
    // p[1] - permission is for this lineStateId (unneeded)
    // p[2] - permission is for revision === 0 (true) or revision !=== 0 (false)
    return (
      reqPermissions &&
      reqPermissions[permission] !== undefined &&
      reqPermissions[permission].some((p) => p[0] === state.id && p[2] === (revNumber === 0))
    );
  };

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  // fetch requisition
  const fetchList = useCallback(async () => {
    console.log('Get request sent for requisition ' + reqRevNum);

    let token = await getToken();

    await axios
      .get(
        process.env.REACT_APP_API_URL +
          'Requisitions/' +
          (isRevisionHistory ? reqNum + '-' + selectedRevision + '?specificRevision=true' : reqRevNum),
        {
          raxConfig: {
            retry: 5,
            shouldResetTimeout: true
          },
          timeout: timeoutSetting,
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
          }
        }
      )
      .then((resp) => {
        console.log('Requisition loaded: ', resp.data);

        let currentReqRevNum = resp.data.requisitionNumber + '-' + resp.data.revisionNumber;
        if (reqRevNum !== currentReqRevNum && !isRevisionHistory && !redirected) {
          window.history.pushState(null, '', '/details/' + currentReqRevNum);
          
          toast.info('You have been redirected to the latest Revision of this requisition. To see older Revisions, visit the "Revision History" tab.', {
            autoClose: false,
            theme: 'colored'
          });

          setRedirected(true);
        }

        const d = resp.data;
        d.pressureRetainingMaterial =
          d.pressureRetainingMaterial === true ? 'Yes' : d.pressureRetainingMaterial === false ? 'No' : null;

        setInitialReq(d);

        setId(d.id);
        setReqDate(d.requisitionDate);

        setProject(d.job.project ?? {});
        setJob(d.job ?? {});
        setCategory(d.category ?? {});
        setReqNumber(d.requisitionNumber);
        setRevNumber(d.revisionNumber);
        setState(d.requisitionState ?? {});
        setRequisitioner(d.requisitionerUser ?? {});

        setPriority(d.priority ?? {});
        setDeliveryLocation(d.deliveryLocation ?? {});
        setPressureRetMat(d.pressureRetainingMaterial);
        setReason(d.reasonForPurchase ?? {});

        setDescription(d.description);
        setReference(d.reference);
        setWorkPackage(d.workPackage);
        setTurnoverSystem(d.turnoverSystem);

        setSuggestedSrc(d.suggestedSources);
        setInstructions(d.instructionsToPurchasing);
        setComments(d.comments);

        setRowVersion(d.rowVersion);

        // for parent
        setReqNum && reqNum === null && setReqNum(d.requisitionNumber);
        setReqRevision && setReqRevision(d.revisionNumber);
        setReqState && setReqState(d.requisitionStateId);
        setReqId && setReqId(d.id ?? -1);
        setProjectId && setProjectId(d.job && d.job.projectId ? d.job.projectId : -1);
        setJobId && setJobId(d.job && d.job.id ? d.job.id : -1);
        setCategoryId && setCategoryId(d.category && d.category.id ? d.category.id : -1);
      })
      .catch((err) => {
        console.error(err);
      });

    setIsLoaded(true);
    setIsLoading(false);
  }, [
    getToken,
    isRevisionHistory,
    reqNum,
    reqRevNum,
    selectedRevision,
    setCategoryId,
    setJobId,
    setProjectId,
    setReqId,
    setReqNum,
    setReqRevision,
    setReqState
  ]);
  useEffect(() => {
    if (
      (!isRevisionHistory && !isLoaded && !isLoading) ||
      (isRevisionHistory &&
        !isLoading &&
        selectedRevision !== false &&
        selectedRevision !== null &&
        typeof selectedRevision === 'number' &&
        revNumber !== selectedRevision)
    ) {
      setIsLoading(true);
      fetchList();
    }
  }, [fetchList, isLoaded, isLoading, isRevisionHistory, revNumber, selectedRevision]);

  // update reqRevision and reset revisionDiff when selecting a revision for history
  useEffect(() => {
    if (selectedRevision !== null && typeof selectedRevision === 'number') {
      setRevisionDiff(null);
      setReqRevision(selectedRevision);
    } else setReqRevision(null);
  }, [selectedRevision, setReqRevision, setRevisionDiff]);

  // fetch revision count for Revision History
  const fetchRevisions = useCallback(async () => {
    console.log('Get request sent for Revision Count');

    let token = await getToken();

    await axios
      .get(process.env.REACT_APP_API_URL + 'Requisitions/revisions/' + reqNum, {
        raxConfig: {
          retry: 5,
          shouldResetTimeout: true
        },
        timeout: timeoutSetting,
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token
        }
      })
      .then((resp) => {
        console.log('Revisions loaded: ', resp);
        setRevisions(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqNum]);
  useEffect(() => {
    if (isRevisionHistory && revisions === null && reqNum !== null) fetchRevisions();
  }, [fetchRevisions, isRevisionHistory, reqNum, revisions]);

  // logic for Save/Submit buttons
  const save = useCallback(async () => {
    if (saving || !saveType || !linesSaved || !attachmentsSaved) return;

    // create requisition object to send to API
    let modReq = {
      id: id,
      jobId: job.id,
      requisitionStateId: saveType === 1 ? state.id : 2, // saveType 1 = save, 2 = submit // stateId 2 = Submitted
      categoryId: category.id,
      requisitionNumber: reqNumber,
      requisitionDate: reqDate,
      priorityId: priority ? priority.id : null,
      deliveryLocationId: deliveryLocation ? deliveryLocation.id : null,
      reasonForPurchaseId: reason ? reason.id : null,
      pressureRetainingMaterial: pressureRetMat === 'Yes' ? true : pressureRetMat === 'No' ? false : null,
      suggestedSources: suggestedSrc,
      instructionsToPurchasing: instructions,
      comments: comments,
      description: description,
      reference: reference,
      turnoverSystem: turnoverSystem,
      workPackage: workPackage,
      rowVersion: rowVersion
    };

    console.log('Update request sent for requisition', modReq);

    let token = await getToken();

    // send PUT request
    await axios
      .put(process.env.REACT_APP_API_URL + 'Requisitions/' + id, modReq, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      .then((resp) => {
        console.log(resp);
        toast.success('Requisition header ' + (saveType === 1 ? 'saved' : 'submitted') + ' successfully.', {
          autoClose: 2500,
          closeOnClick: false
        });

        // re-fetch header for any updated fields
        fetchList();
      })
      .catch((err) => {
        console.error(err);

        let errorList = [];

        if (typeof err.response.data === 'string') {
          // string error
          errorList.push(err.response.data);
        } else if (err.response.data.length > 0) {
          // array of string errors
          for (const e in err.response.data) {
            errorList.push(err.response.data[e]);
          }
        } else if (err.response.data.errors) {
          // array or object of string errors
          for (const e in err.response.data.errors) {
            errorList.push(err.response.data.errors[e]);
          }
        }

        setHeaderErrors(errorList);

        toast.error('The requisition header was unable to be saved. A list of all header errors is available below.', {
          autoClose: false,
          closeOnClick: false,
          theme: 'colored'
        });
      });

    setHeaderSaved(true);
    setSaving(false);
  }, [
    attachmentsSaved,
    category.id,
    comments,
    deliveryLocation,
    description,
    fetchList,
    getToken,
    id,
    instructions,
    job.id,
    linesSaved,
    pressureRetMat,
    priority,
    reason,
    reference,
    reqDate,
    reqNumber,
    rowVersion,
    saveType,
    saving,
    setHeaderErrors,
    setHeaderSaved,
    state.id,
    suggestedSrc,
    turnoverSystem,
    workPackage
  ]);
  useEffect(() => {
    if (linesSaved && attachmentsSaved && !saving && !headerSaved) {
      // console.log(linesSaved, saving, attachmentsSaved);
      setSaving(true);
      save();
    }
  }, [linesSaved, saving, attachmentsSaved, headerSaved, save]);

  // logic for requisition modification buttons
  const modifyRequisition = async (type) => {
    // type: 1 = 'Cancel Requisition', 2 = 'Recall', 3 = 'Create Revision', 4 = 'Cancel Revision'

    console.log(
      (type === 1 ? 'Cancel' : type === 2 ? 'Recall' : type === 3 ? 'Create Revision' : 'Cancel Revision') +
        ' request sent for requisition',
      reqRevNum
    );

    if (type === 3) {
      // send GET request
      let token = await getToken();

      await axios
        .get(process.env.REACT_APP_API_URL + 'Requisitions/revise/' + id, {
          raxConfig: {
            retry: 5,
            shouldResetTimeout: true
          },
          timeout: timeoutSetting,
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json; charset=utf-8'
          }
        })
        .then((resp) => {
          console.log(resp);

          toast.success('Revision created successfully.', {
            autoClose: 2500,
            closeOnClick: false
          });

          // navigate to the new revision's URL
          let reqIdentifier = resp.headers.location.split('/').pop();
          navigate('/details/' + reqIdentifier);
          window.location.reload();
        })
        .catch((err) => {
          console.error(err);

          let errorList = err.response.data.map((e, idx) => <li key={idx}>{e}</li>);

          toast.error(
            <div>
              The requisition was unable to be modified due to the following error(s):
              <ul style={{ paddingLeft: '1em' }}>{errorList}</ul>
            </div>,
            {
              autoClose: false,
              closeOnClick: false,
              theme: 'colored'
            }
          );
        });
    } else {
      let newState = state.id;
      if (type === 1 || type === 4) {
        newState = 5; // 5 = Cancelled
      } else if (type === 2) {
        newState = 1; // 1 = Draft (recalled)
      }

      // create requisition object to send to API
      let modReq = {
        id: id,
        jobId: job.id,
        requisitionStateId: newState,
        categoryId: category.id,
        requisitionNumber: reqNumber,
        requisitionDate: reqDate,
        priorityId: priority ? priority.id : null,
        deliveryLocationId: deliveryLocation ? deliveryLocation.id : null,
        reasonForPurchaseId: reason ? reason.id : null,
        pressureRetainingMaterial: pressureRetMat === 'Yes' ? true : pressureRetMat === 'No' ? false : null,
        suggestedSources: suggestedSrc,
        instructionsToPurchasing: instructions,
        comments: comments,
        description: description,
        reference: reference,
        turnoverSystem: turnoverSystem,
        workPackage: workPackage,
        rowVersion: rowVersion
      };

      // send PUT request
      let token = await getToken();

      await axios
        .put(process.env.REACT_APP_API_URL + 'Requisitions/' + id, modReq, {
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json; charset=utf-8'
          }
        })
        .then((resp) => {
          console.log(resp);

          let toastText;
          if (type === 1) {
            toastText = 'Requisition cancelled successfully.';
          } else if (type === 4) {
            toastText = 'Revision cancelled successfully.';
          } else if (type === 2) {
            toastText = 'Requisition recalled successfully.';
          }

          toast.success(toastText, {
            autoClose: 2500,
            closeOnClick: false
          });

          if (type === 4) {
            // navigate to the cancelled revision's URL
            navigate('/details/' + resp.data.requisitionNumber + '-' + resp.data.revisionNumber);
            window.location.reload();
          }

          // re-fetch header for any updated fields
          fetchList();
        })
        .catch((err) => {
          console.error(err);

          let errorList = err.response.data.map((e, idx) => <li key={idx}>{e}</li>);

          toast.error(
            <div>
              The requisition was unable to be modified due to the following error(s):
              <ul style={{ paddingLeft: '1em' }}>{errorList}</ul>
            </div>,
            {
              autoClose: false,
              closeOnClick: false,
              theme: 'colored'
            }
          );
        });
    }
  };

  // logic for generate sheet buttons
  const generateSheet = async (type) => {
    // type: 1 = 'RFQ', 2 = 'SMat', 3 = 'JDE'

    if (linesSelected.length === 0) {
      toast.info('Please ensure you have selected one or more lines before generating a sheet.', {
        autoClose: 5000,
        closeOnClick: false,
        theme: 'colored'
      });
      return;
    }

    let typeText = type === 1 ? 'RFQ' : type === 2 ? 'SMat' : 'JDE';
    let exportType = type === 1 ? 'Rfq' : type === 2 ? 'SMat' : 'Jde';

    // create object to send
    let genObj = {
      requisitionId: id,
      exportType: exportType,
      lines: linesSelected.map((l) => l.id)
    };

    console.log(typeText + ' sheet generation request sent', genObj);

    // send POST request
    let token = await getToken();

    await axios
      .post(process.env.REACT_APP_API_URL + 'ExportJobs/', genObj, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      .then((resp) => {
        console.log(resp);

        toast.success(
          typeText + ' sheet generation requested successfully. You will receive an email when it is ready.',
          {
            autoClose: 2500,
            closeOnClick: false
          }
        );
      })
      .catch((err) => {
        console.error(err);

        let errorList = [];

        if (typeof err.response.data === 'string') {
          // string error
          errorList.push(err.response.data);
        } else if (err.response.data.length > 0) {
          // array of string errors
          for (const e in err.response.data) {
            errorList.push(err.response.data[e]);
          }
        } else if (err.response.data.errors) {
          // array or object of string errors
          for (const e in err.response.data.errors) {
            errorList.push(err.response.data.errors[e]);
          }
        }

        errorList = errorList.map((e, idx) => <li key={idx}>{e}</li>);

        toast.error(
          <div>
            The {typeText} sheet generation was unable to be started due to the following error(s):
            <ul style={{ paddingLeft: '1em' }}>{errorList}</ul>
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            theme: 'colored'
          }
        );
      });
  };

  // ensure the requisition can be submitted before setting the save flag
  const checkSubmit = () => {
    if (lineData.length === 0) {
      // trying to submit with no lines isn't allowed
      // cancel save and show a toast
      setSaveType(0);

      toast.info('A requisition requires one or more Lines before it can be submitted. Changes have not been saved.', {
        autoClose: false,
        closeOnClick: false,
        theme: 'colored'
      });

      return;
    } else {
      // submit the req
      setSaveType(2);
    }
  };

  return (
    <>
      {!isRevisionHistory && (
        <div
          className='card-buttons'
          style={{ display: isRevisionHistory ? 'none' : 'flex', gap: '8em', justifyContent: 'space-between' }}
        >
          <ButtonGroup>
            <Button
              onClick={() =>
                window.confirm('Are you sure you want to cancel this requisition?') && modifyRequisition(1)
              }
              disabled={
                !isLoaded || !(hasPermission('Requisition.CancelDraft') || hasPermission('Requisition.CancelApproved'))
              }
            >
              Cancel Requisition
            </Button>
            <Button onClick={() => modifyRequisition(2)} disabled={!isLoaded || !hasPermission('Requisition.UnSubmit')}>
              Recall
            </Button>
            <Button
              onClick={() => modifyRequisition(3)}
              disabled={!isLoaded || !hasPermission('Requisition.CreateRevision')}
            >
              Create Revision
            </Button>
            <Button
              onClick={() => window.confirm('Are you sure you want to cancel this revision?') && modifyRequisition(4)}
              disabled={
                !isLoaded ||
                !(
                  hasPermission('Requisition.CancelDraftRevision') ||
                  hasPermission('Requisition.CancelApprovedRevision')
                )
              }
            >
              Cancel Revision
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              onClick={() => generateSheet(1)}
              disabled={!isLoaded || dirty || !hasPermission('Requisition.RfqSpreadsheet.Generate')}
            >
              Generate RFQ Sheet
            </Button>
            <Button
              onClick={() => generateSheet(2)}
              disabled={!isLoaded || dirty || !hasPermission('Requisition.SMatSpreadsheet.Generate')}
            >
              Generate SMat Sheet
            </Button>
            <Button
              onClick={() => generateSheet(3)}
              disabled={!isLoaded || dirty || !hasPermission('Requisition.JdeSpreadsheet.Generate')}
            >
              Generate JDE Sheet
            </Button>
          </ButtonGroup>
        </div>
      )}
      {!isRevisionHistory ? null : revisions !== null ? (
        <LabelledInput
          type='dropdown'
          label='Revision'
          options={revisions}
          input={{ set: setSelectedRevision, value: selectedRevision }}
          width='280px'
        />
      ) : (
        <LoadingCard mini={true} />
      )}
      {(!isRevisionHistory ||
        (selectedRevision !== false && selectedRevision !== null && typeof selectedRevision === 'number')) && (
        <div className='card-component'>
          <div className='req-form'>
            <div>
              <LabelledInput
                type={'textbox'}
                input={{ set: setProject, value: project.projectNumber }}
                label={'Project'}
                locked={true}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setJob, value: job.jobNumber }}
                label={'Job'}
                locked={true}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setCategory, value: reqRevNum ? category.name : category.categoryName }}
                label={'Category'}
                locked={true}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setReqNumber, value: reqNumber }}
                label={'Requisition #'}
                locked={true}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setRevNumber, value: revNumber }}
                label={'Revision #'}
                bold={revNumber > 0}
                locked={true}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setState, value: state.name }}
                label={'State'}
                locked={true}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setRequisitioner, value: requisitioner.displayName }}
                label={'Requisitioner'}
                locked={true}
              />
              <div className='label-container' style={{ marginTop: '1em' }}>
                <Button
                  style={{ width: '8em' }}
                  onClick={() => {
                    setHeaderErrors([]);
                    setSaveType(1);
                  }}
                  disabled={!isLoaded || !linesLoaded || state.id === 5}
                >
                  {/* disabled if: state is Cancelled OR Buyer Complete */}
                  Save
                </Button>
                <Button
                  style={{ width: '8em', marginLeft: '4em' }}
                  onClick={() => checkSubmit()}
                  disabled={
                    !isLoaded ||
                    !linesLoaded ||
                    dirty ||
                    lineData === null ||
                    lineData.length === 0 ||
                    !hasPermission('Requisition.Submit')
                  }
                >
                  {/* disabled if: requisition is dirty (unsaved changes) */}
                  Submit
                </Button>
              </div>
            </div>
            <div>
              <LabelledInput
                type={'dropdown'}
                input={{ set: setPriority, value: priority }}
                label={'Priority*'}
                endpoint={'Priorities'}
                field={'name'}
                activeOnly={true}
                locked={locked || !hasPermission('Requisition.Priority.Edit')}
              />
              <LabelledInput
                type={'dropdown'}
                input={{ set: setDeliveryLocation, value: deliveryLocation }}
                label={'Delivery Location*'}
                endpoint={'DeliveryLocations'}
                projectId={reqRevNum ? project.id : project.projectId}
                waitForProjectId={true}
                field={'name'}
                activeOnly={true}
                locked={locked || !hasPermission('Requisition.DeliveryLocationId.Edit')}
              />
              <LabelledInput
                type={'dropdown'}
                input={{ set: setPressureRetMat, value: pressureRetMat }}
                label={'Pressure Retaining Material*'}
                options={['Yes', 'No']}
                locked={locked || !hasPermission('Requisition.PressureRetainingMaterial.Edit')}
              />
              <LabelledInput
                type={'dropdown'}
                input={{ set: setReason, value: reason }}
                label={'Reason for Purchase*'}
                endpoint={'ReasonForPurchases'}
                field={'name'}
                activeOnly={true}
                locked={locked || !hasPermission('Requisition.ReasonForPurchase.Edit')}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setDescription, value: description }}
                label={'Description*'}
                locked={locked || !hasPermission('Requisition.Description.Edit')}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setReference, value: reference }}
                label={'Reference'}
                locked={locked || !hasPermission('Requisition.Reference.Edit')}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setWorkPackage, value: workPackage }}
                label={'Work Package'}
                locked={locked || !hasPermission('Requisition.WorkPackage.Edit')}
              />
              <LabelledInput
                type={'textbox'}
                input={{ set: setTurnoverSystem, value: turnoverSystem }}
                label={'Turnover System'}
                locked={locked || !hasPermission('Requisition.TurnoverSystem.Edit')}
              />
            </div>
            <div>
              <LabelledInput
                type={'textarea'}
                input={{ set: setSuggestedSrc, value: suggestedSrc }}
                label={'Suggested Sources'}
                locked={locked || !hasPermission('Requisition.SuggestedSources.Edit')}
              />
              <LabelledInput
                type={'textarea'}
                input={{ set: setInstructions, value: instructions }}
                label={'Instructions to Procurement'}
                locked={locked || !hasPermission('Requisition.InstructionsToPurchasing.Edit')}
              />
              <LabelledInput
                type={'textarea'}
                input={{ set: setComments, value: comments }}
                label={'Comments'}
                locked={locked || !hasPermission('Requisition.Comments.Edit')}
              />
            </div>
            <div>{AttachmentList()}</div>
          </div>
        </div>
      )}
    </>
  );
};
