import { SpinningCircles } from 'react-loading-icons';

export const LoadingCard = ({ mini = false }) => {
  if (!mini) {
    return (
      <div className='card-container'>
        <SpinningCircles style={{ margin: '0 auto' }} stroke='#587aff' fill='#587aff' />
      </div>
    );
  } else {
    return (
      <SpinningCircles style={{ margin: '0 auto', height: '26px', width: 'auto' }} stroke='#587aff' fill='#587aff' />
    );
  }
};
