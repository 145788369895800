import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { usePrompt } from '../functions/promptBlocker';
import { RequisitionDetailsHeader } from '../components/RequisitionDetailsHeader';
import { RequisitionDetailsTable } from '../components/RequisitionDetailsTable';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useParams } from 'react-router-dom';
import { AttachmentList } from '../components/AttachmentList';
import { ApprovalsHistory } from '../components/ApprovalsHistory';
import { AuditTrail } from '../components/AuditTrail';
import { SaveOverlay } from '../components/SaveOverlay';
import { LoadingCard } from '../components/LoadingCard';

function ReqDetails() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [reqNum, setReqNum] = useState(null);
  const [reqId, setReqId] = useState(-1);
  const [projectId, setProjectId] = useState(-1);
  const [jobId, setJobId] = useState(-1);
  const [categoryId, setCategoryId] = useState(-1);
  const [saveType, setSaveType] = useState(0); // 0 = not saving, 1 = save, 2 = submit
  const [headerSaved, setHeaderSaved] = useState(false);
  const [linesSaved, setLinesSaved] = useState(false);
  const [attachmentsSaved, setAttachmentsSaved] = useState(false);
  const [linesLoaded, setLinesLoaded] = useState(false);
  const [lineData, setLineData] = useState(null);
  const [resetLines, setResetLines] = useState(false);
  const [linesSelected, setLinesSelected] = useState([]);
  const [reqState, setReqState] = useState(null);
  const [reqRevision, setReqRevision] = useState(null);
  const [revisionDiff, setRevisionDiff] = useState(null);
  const [dirtyStates, setDirtyStates] = useState({ header: false, lines: false, attachments: false });
  const [reqPermissions, setReqPermissions] = useState(null);
  const [headerErrors, setHeaderErrors] = useState([]);
  const [redirected, setRedirected] = useState(false);

  let { reqRevNum } = useParams();
  const { instance, accounts } = useMsal();

  console.log('Dirty:', dirtyStates);

  // note: save order is Lines (RequisitionDetailsTable) first, then Attachments (AttachmentList), then Header (RequisitionDetailsHeader)
  // Lines must be saved before the header, as a requisition header can't be submitted before its Lines
  // Lines also must be saved before attachments, as an attachment is linked to an existing line ID
  // Attachments must be saved before the header, as attachments can't be added after a header is Submitted+

  // figure out if we're in the middle of saving
  const saving = saveType > 0;

  // dirty checking
  const dirty = dirtyStates.header || dirtyStates.lines || dirtyStates.attachments;

  // used to lock all fields/editing on the req
  let locked = false;
  // lock when req state is 'Cancelled' or permissions are not loaded yet
  if (reqState === 5 || !reqPermissions) locked = true;

  // show popup dialog if user tries to leave before saving
  usePrompt(
    'Are you sure you want to leave? This requisition has not been saved, and any changes you have made may be lost.',
    dirty
  );
  // const alertUser = useCallback(
  //   (e) => {
  //     if (saving || dirty) e.preventDefault();
  //   },
  //   [saving, dirty]
  // );
  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser);
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser);
  //   };
  // }, [alertUser]);

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  // fetch permissions
  useEffect(() => {
    const fetchPermissions = async () => {
      console.log('Get request sent for requisition details permissions.');

      let token = await getToken();

      let tempEndpoint = 'UserPermissions/ProjectRequisition/' + projectId;

      await axios
        .get(process.env.REACT_APP_API_URL + tempEndpoint, {
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + (await token)
          }
        })
        .then((resp) => {
          console.log('Requisition details permissions loaded:', resp);

          setReqPermissions({ ...resp.data });
        });
    };
    if (!reqPermissions && projectId !== -1) fetchPermissions();
  }, [getToken, projectId, reqPermissions]);

  // fetch revision diff
  useEffect(() => {
    const fetchRevisionDiff = async () => {
      console.log('Get request sent for revision diff.');

      let token = await getToken();

      let tempEndpoint = 'Requisitions/revisiondiff/' + reqNum + '-' + reqRevision;

      await axios
        .get(process.env.REACT_APP_API_URL + tempEndpoint, {
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + (await token)
          }
        })
        .then((resp) => {
          console.log('Revision diff loaded:', resp);

          // set changed line property names to camelCase from PascalCase, to match property names in RGHotTable
          setRevisionDiff({ ...resp.data, changedLines: resp.data.changedLines.map((c) => ({...c, lineProperties: c.lineProperties.map((p) => p.charAt(0).toLowerCase() + p.substr(1))}))});
        });
    };

    if (revisionDiff === null) {
      if (reqRevision === 0) setRevisionDiff({}); // no previous revision - don't fetch diff
      else if (projectId !== -1) fetchRevisionDiff();
    }
  }, [getToken, revisionDiff, projectId, reqNum, reqRevision]);

  // reset save states when save is completed
  useEffect(() => {
    if (attachmentsSaved && headerSaved) {
      setSaveType(0);
      setLinesSaved(false);
      setAttachmentsSaved(false);
      setHeaderSaved(false);
      setRevisionDiff(null);
      setDirtyStates({ header: false, lines: false, attachments: false });
    }
  }, [attachmentsSaved, headerSaved]);

  // reset reqRevision when tab is changed
  useEffect(() => {
    setReqRevision(null);
  }, [selectedTab]);

  return (
    <>
      {saving && <SaveOverlay />}
      <div id='ReqDetails'>
        <div>
          <div className='card-container title-card'>
            <h3 className='page-title'>Requisition Details</h3>
          </div>
        </div>

        <div>
          <div className='card-container grid'>
            <div className='card-component'>
              <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)}>
                <TabStripTab title='Requisition Details'>
                  <RequisitionDetailsHeader
                    reqRevNum={reqRevNum}
                    reqId={reqId}
                    reqPermissions={reqPermissions}
                    setReqNum={setReqNum}
                    reqNum={reqNum}
                    setReqId={setReqId}
                    setProjectId={setProjectId}
                    setJobId={setJobId}
                    setCategoryId={setCategoryId}
                    setSaveType={setSaveType}
                    saveType={saveType}
                    lineData={lineData}
                    linesLoaded={linesLoaded}
                    linesSaved={linesSaved}
                    attachmentsSaved={attachmentsSaved}
                    linesSelected={linesSelected}
                    setHeaderSaved={setHeaderSaved}
                    headerSaved={headerSaved}
                    setReqState={setReqState}
                    setReqRevision={setReqRevision}
                    locked={locked}
                    setDirty={(d) => d !== dirtyStates.header && setDirtyStates({ ...dirtyStates, header: d })}
                    dirty={dirty}
                    setRedirected={setRedirected}
                    redirected={redirected}
                    setHeaderErrors={setHeaderErrors}
                    AttachmentList={() => (
                      <AttachmentList
                        key={reqRevision}
                        reqId={reqId}
                        reqPermissions={reqPermissions}
                        reqState={reqState}
                        reqRevision={reqRevision}
                        revisionDiff={revisionDiff}
                        lineData={lineData}
                        linesLoaded={linesLoaded}
                        linesSaved={linesSaved}
                        resetLines={resetLines}
                        setResetLines={setResetLines}
                        setAttachmentsSaved={setAttachmentsSaved}
                        locked={locked}
                        setDirty={(d) =>
                          d !== dirtyStates.attachments && setDirtyStates({ ...dirtyStates, attachments: d })
                        }
                      />
                    )}
                  />
                </TabStripTab>
                <TabStripTab title='Approvals History' disabled={reqNum === null}>
                  <ApprovalsHistory reqRevNum={reqRevNum.substring(0, reqRevNum.lastIndexOf('-'))} />
                </TabStripTab>
                <TabStripTab title='Revision History' disabled={reqNum === null}>
                  <RequisitionDetailsHeader
                    reqId={reqId}
                    reqNum={reqNum}
                    reqRevNum={reqRevNum}
                    reqPermissions={reqPermissions}
                    setReqId={setReqId}
                    setProjectId={setProjectId}
                    setJobId={setJobId}
                    setCategoryId={setCategoryId}
                    setReqRevision={setReqRevision}
                    setRevisionDiff={setRevisionDiff}
                    locked={true}
                    isRevisionHistory={true}
                    AttachmentList={() => (
                      <AttachmentList
                        key={reqRevision}
                        reqId={reqId}
                        reqPermissions={reqPermissions}
                        reqState={reqState}
                        reqRevision={reqRevision}
                        revisionDiff={revisionDiff}
                        lineData={lineData}
                        linesLoaded={linesLoaded}
                        resetLines={resetLines}
                        setResetLines={setResetLines}
                        locked={true}
                      />
                    )}
                  />
                </TabStripTab>
                <TabStripTab title='Audit Trail' disabled={reqNum === null}>
                  <AuditTrail reqNum={reqNum} />
                </TabStripTab>
              </TabStrip>
            </div>
          </div>
        </div>
        {selectedTab === 0 &&
          (reqPermissions && revisionDiff ? (
            <>
              {headerErrors.length > 0 && (
                <div>
                  <div className='card-container'>
                    <h2 style={{ marginBottom: '6px', color: '#587aff' }}>Header Errors</h2>
                    <div style={{ textAlign: 'left', lineHeight: '2em' }}>
                      {headerErrors.map((e, idx) => (
                        <span key={'header-error-' + idx}>
                          {e}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <RequisitionDetailsTable
                reqId={reqId}
                reqRevNum={reqRevNum}
                reqPermissions={reqPermissions}
                projectId={projectId}
                jobId={jobId}
                categoryId={categoryId}
                setSaveType={setSaveType}
                saveType={saveType}
                linesSaved={linesSaved}
                setLinesSaved={setLinesSaved}
                lineData={lineData}
                setLineData={setLineData}
                linesLoaded={linesLoaded}
                setLinesLoaded={setLinesLoaded}
                setResetLines={setResetLines}
                setLinesSelected={setLinesSelected}
                reqState={reqState}
                reqRevision={reqRevision}
                revisionDiff={revisionDiff}
                locked={locked}
                setDirty={(d) => d !== dirtyStates.lines && setDirtyStates({ ...dirtyStates, lines: d })}
              />
            </>
          ) : (
            <LoadingCard />
          ))}
        {selectedTab === 2 && reqRevision !== null && reqNum !== null && revisionDiff && (
          <RequisitionDetailsTable
            reqId={reqId}
            reqRevNum={reqNum + '-' + reqRevision}
            projectId={projectId}
            jobId={jobId}
            categoryId={categoryId}
            lineData={lineData}
            setLineData={setLineData}
            linesLoaded={linesLoaded}
            setLinesLoaded={setLinesLoaded}
            setResetLines={setResetLines}
            setLinesSelected={setLinesSelected}
            revisionDiff={revisionDiff}
            locked={true}
            isRevisionHistory={true}
          />
        )}
      </div>
    </>
  );
}

export default ReqDetails;
