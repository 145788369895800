import React, { useEffect, useState, useCallback } from 'react';
import { HotColumn } from '@handsontable/react';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { RGHotTable } from '../components/RGHotTable';
import { RGReviewCards } from '../components/RGReviewCards';
import { useIsMobile } from '../functions/useIsMobile';

function ReqReview() {
  //used to pull values from props.TD.parentNode.children
  const revisionDataCol = 5;
  const feedbackDataCol = 8;
  //used for setting the button placement using props.column equals
  const rejectBtnCol = 10;
  const approveBtnCol = 11;
  let isMobile = useIsMobile();

  //added to support dynamic resizing when moving between mobile and desktop screen size
  window.onresize = useResize;
  function useResize()
  {
    let initialIsMobile = isMobile;
    isMobile = useIsMobile(); //check based on current screen width

    if (initialIsMobile !== isMobile) {
      window.location.reload(); 
    }
  }

  const colData = {
    widths: [0, 110, 110, 210, 80, 130, 60, 110, 130, 210, 110, 110],
    names: ['ID', 'Project', 'Job', 'Category', 'Priority', 'Requisition #', 'Rev #', 'Review Type', 'Date Submitted', 'Review Feedback', '', '']
  };

  const { instance, accounts } = useMsal();

  const [externalReset, setExternalReset] = useState(false);

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  const submitReview = async (id, approve, comment) => {
    // first, we have to fetch the review
    console.log('Get request sent for review ' + id);

    let token = await getToken();

    let modReview;

    let reviewExists = true;
    await axios
      .get(process.env.REACT_APP_API_URL + 'RequisitionReviews/' + id, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token
        }
      })
      .then((resp) => {
        console.log('Review loaded: ', resp.data);
        modReview = resp.data;
      })
      .catch((err) => {
        console.error(err);

        reviewExists = false;

        toast.error(
          'Review unsuccessful. It is likely that this requisition was already rejected by another user since you opened this page. Please refresh to see the latest available reviews.',
          {
            autoClose: false,
            closeOnClick: false,
            theme: 'colored'
          }
        );
      });

    if (!reviewExists) return;

    // now we update the review
    modReview.isApproved = approve;
    modReview.comments = comment;

    if (!approve && !modReview.comments) {
      toast.warning('You must enter feedback when rejecting a requisition review.', {
        autoClose: 5000,
        closeOnClick: false,
        theme: 'colored'
      });
      return;
    }

    console.log('Put request sent for review', modReview);

    // send PUT request
    await axios
      .put(process.env.REACT_APP_API_URL + 'RequisitionReviews/' + id, modReview, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      .then((resp) => {
        console.log(resp);

        toast.success('Requisition review submitted.', {
          autoClose: 2500,
          closeOnClick: false
        });
      })
      .catch((err) => {
        console.error(err);

        let errorList = [];

        if (typeof err.response.data === 'string') {
          // string error
          errorList.push(err.response.data);
        } else if (err.response.data.length > 0) {
          // array of string errors
          for (const e in err.response.data) {
            errorList.push(err.response.data[e]);
          }
        } else if (err.response.data.errors) {
          // array or object of string errors
          for (const e in err.response.data.errors) {
            errorList.push(err.response.data.errors[e]);
          }
        }

        errorList = errorList.map((e, idx) => <li key={idx}>{e}</li>);

        toast.error(
          <div>
            The review was unable to be submitted due to the following error(s):
            <ul style={{ paddingLeft: '1em' }}>{errorList}</ul>
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            theme: 'colored'
          }
        );
      });

    // reload table to fetch any changes
    setExternalReset(true);
  };

  // reset externalReset
  useEffect(() => {
    if (externalReset) setExternalReset(false);
  }, [externalReset]);

  const ReviewButton = (props) => {
    let commentText = props.TD.parentNode.children[feedbackDataCol].innerText; 
    if (props.col === rejectBtnCol)
      return (
        <Button className='review-button reject-button' onClick={() => submitReview(props.value, false, commentText)}>
          Reject
        </Button>
      );
    else if (props.col === approveBtnCol)
      return (
        <Button className='review-button approve-button' onClick={() => submitReview(props.value, true, commentText)}>
          Approve
        </Button>
      );
  };

  const ReqLink = (props) => {
    let revision = props.TD.parentNode.children[revisionDataCol].innerText;
    return (
      <div style={{ textAlign: 'left' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='#' onClick={() => {
          var url = 'details/' + props.value + '-' + revision;
          window.open(url, url).focus()
        }}>
          {props.value}
        </a>
      </div>
    );
  };

  return (
    <div id='ReqReview'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Requisitions - For Review</h3>
        </div>
      </div>
      {!isMobile && (
      <RGHotTable
        endpoint={'RequisitionReviews/MyPending'}
        colData={colData}
        initialSort={[
          {
            column: 1, //Project
            sortOrder: 'asc'
          },
          {
            column: 2, //Job
            sortOrder: 'asc'
          },
          {
            column: 5, //Requisition #
            sortOrder: 'asc'
          }
        ]}
        addRemoveRows={false}
        idProp={'requisitionReviewId'}
        externalReset={externalReset}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'projectNumber'} readOnly={true} className='htLeft' />
        <HotColumn data={'jobNumber'} readOnly={true} className='htLeft' />
        <HotColumn data={'categoryName'} readOnly={true} className='htLeft' />
        <HotColumn data={'priority'} readOnly={true} className='htLeft' /> 
        <HotColumn data={'requisitionNumber'} readOnly={true}>
          <ReqLink hot-renderer />
        </HotColumn>
        <HotColumn data={'revisionNumber'} readOnly={true} className='htLeft' />
        <HotColumn data={'reviewTypeName'} readOnly={true} className='htLeft' />
        <HotColumn data={'submissionDate'} readOnly={true} className='htLeft' />
        <HotColumn data={'comments'} className='htLeft' />
        <HotColumn data={'id'} readOnly={true}>
          {/* Reject */}
          <ReviewButton hot-renderer />
        </HotColumn>
        <HotColumn data={'id'} readOnly={true}>
          {/* Approve */}
          <ReviewButton hot-renderer />
        </HotColumn>
      </RGHotTable>
      )}
      {isMobile && (
        <RGReviewCards 
          endpoint={'RequisitionReviews/MyPending'}>
        </RGReviewCards>
      )}
    </div>
  );
}

export default ReqReview;
