import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';
import { LabelledInput } from '../components/LabelledInput';

function ProjectsCategoriesReviewers() {
  const [selectedProject, setSelectedProject] = useState({});

  const colData = {
    widths: [0, 210, 160, 210, 140, 70],
    names: ['ID', 'Category*', 'Reviewer Type*', 'User*', 'Notifications Active', 'Active']
  };

  // dropdown options
  const [catOptions, setCatOptions] = useState([]);
  const [revTypeOptions, setRevTypeOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  // collection of API endpoints, property names, data, setData, and whether to fetch only by selected project for each dropdown
  const dropdowns = [
    ['Categories', 'category', catOptions, setCatOptions, true],
    ['ReviewTypes', 'reviewType', revTypeOptions, setRevTypeOptions, false],
    ['User', 'user', userOptions, setUserOptions, false]
  ];

  return (
    <div id='ProjectsCategoriesReviewers'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects, Categories, & Reviewers</h3>
        </div>
      </div>
      <div className='card-container'>
        <div className='card-component'>
          <LabelledInput
            type={'dropdown'}
            input={{ set: setSelectedProject, value: selectedProject }}
            label={'Project'}
            endpoint={'Projects'}
            field={'dropdownDisplayName'}
            activeOnly={true}
            isAdminPageFilter={true}
          />
        </div>
      </div>
      {!selectedProject.id ? (
        <></>
      ) : (
        <RGHotTable
          endpoint={'Reviewers'}
          colData={colData}
          editPermission={'Reviewer.FR.Edit'} // NOTE: there are multiple permissions, one for each review type, but all currently map to the same roles
          dataSchema={{
            isNotificationActive: false,
            isActive: false
          }}
          dropdowns={dropdowns}
          isEditable={selectedProject.jdeProject.isActive}
          projectId={selectedProject.id}
          syncSave={true}
          key={selectedProject.id}
        >
          <HotColumn data={'id'} />
          <HotColumn
            data={'category.name'}
            type='dropdown'
            source={catOptions.filter((o) => o.isActive).map((o) => o.name)}
            className='htLeft'
          />
          <HotColumn
            data={'reviewType.name'}
            type='dropdown'
            source={revTypeOptions.filter((o) => o.isCategorySpecific).map((o) => o.name)}
            className='htLeft'
          />
          <HotColumn
            data={'user.displayName'}
            type='dropdown'
            source={userOptions.map((o) => o.displayName)}
            className='htLeft'
          />
          <HotColumn data={'isNotificationActive'} type='checkbox' />
          <HotColumn data={'isActive'} type='checkbox' />
        </RGHotTable>
      )}
    </div>
  );
}

export default ProjectsCategoriesReviewers;
