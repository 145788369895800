import React, { useEffect, useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';
import { LabelledInput } from '../components/LabelledInput';
import { Button } from '@progress/kendo-react-buttons';

function ProjectsJobsCategoriesCodes() {
  //used to pull values from props.TD.parentNode.children
  const categoryCol = 4;

  const [externalHotData, setExternalHotData] = useState(null);
  const [selectedProject, setSelectedProject] = useState({});
  const [selectedJob, setSelectedJob] = useState({});

  const colData = {
    widths: [0, 160, 260, 160, 260, 260, 70, 90],
    names: [
      'ID',
      'Cost Code (parent)',
      'Cost Code Description',
      'Cost Type (child)',
      'Cost Type Description',
      'Category*',
      'Active',
      'Duplicate'
    ]
  };

  // dropdown options
  const [catOptions, setCatOptions] = useState([]);
  // collection of API endpoints, property names, 'data', 'setData', whether to fetch only by selected project for each dropdown,
  // and (optional) a top-level property to get the text for the dropdown from instead of a hydrated object like usual, plus the property used to retrieve it from 'data'
  const dropdowns = [['Categories', 'category', catOptions, setCatOptions, true, 'name', 'categoryName']];

  const [dupeCount, setDupeCount] = useState(0); // used for unique ID's on duplicated rows
  const [insertRow, setInsertRow] = useState({ index: null, rowData: null });
  const [externalEdit, setExternalEdit] = useState(false);
  const [externalReset, setExternalReset] = useState(false);

  const duplicateRow = async (row) => {
    let duplicatedRowData = { ...externalHotData[row] }; // shallow copy row

    // reset key fields
    duplicatedRowData.id = 'N' + dupeCount + '-' + duplicatedRowData.id;
    duplicatedRowData.categoryId = null;
    duplicatedRowData.categoryName = null;
    duplicatedRowData.isActive = false;

    // send to be inserted by RGHotTable
    setInsertRow({index: row + 1, rowData: duplicatedRowData});

    // increment dupeCount
    setDupeCount(dupeCount + 1);
  };
  
  // reset externalReset
  useEffect(() => {
    if (externalReset) setExternalReset(false);
  }, [externalReset]);

  const DuplicateButton = (props) => {
    let category = props.TD.parentNode.children[categoryCol].lastChild.nodeValue;

    return (
      <Button
      className={'duplicate-button' + (externalEdit ? ' duplicate-button-active' : '')}
      disabled={!externalEdit || category === ""} // disable if 'Edit' not enabled, or if existing row doesn't have a category yet
      onClick={() => duplicateRow(props.cellProperties.row)}>
        +
      </Button>
    );
  };

  return (
    <div id='ProjectsJobsCategoriesCodes'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects, Jobs, Categories, & Codes</h3>
        </div>
      </div>
      <div className='card-container'>
        <div className='card-component'>
          <LabelledInput
            type={'dropdown'}
            input={{ set: setSelectedProject, value: selectedProject }}
            label={'Project'}
            endpoint={'Projects'}
            field={'dropdownDisplayName'}
            activeOnly={true}
            isAdminPageFilter={true}
          />
          {!selectedProject.id ? (
            <></>
          ) : (
            <>
              <br />
              <LabelledInput
                type={'dropdown'}
                input={{ set: setSelectedJob, value: selectedJob }}
                label={'Job'}
                endpoint={'Jobs'}
                field={'dropdownDisplayName'}
                projectId={selectedProject.id}
                activeOnly={true}
                isAdminPageFilter={true}
              />
            </>
          )}
        </div>
      </div>
      {!selectedProject.id || !selectedJob.id ? (
        <></>
      ) : (
        <RGHotTable
          endpoint={'CostTypeCodes'}
          colData={colData}
          editPermission={'CostTypeCode.Edit'}
          dataSchema={{
            isActive: false
          }}
          dropdowns={dropdowns}
          constraints={[{ required: ['categoryName'], reliant: ['isActive'] }]}
          isEditable={selectedProject.jdeProject.isActive && selectedJob.jdeJob.isActive}
          addRemoveRows={false}
          projectId={selectedProject.id}
          jobId={selectedJob.id}
          externalReset={externalReset}
          setExternalHotData={setExternalHotData}
          setExternalEdit={setExternalEdit}
          insertRow={{ get: insertRow, set: setInsertRow }}
          key={selectedProject.id + "-" + selectedJob.id}
        >
          <HotColumn data={'id'} />
          <HotColumn data={'costCodeNumber'} readOnly={true} className='htLeft' />
          <HotColumn data={'costCodeDescription'} readOnly={true} className='htLeft' />
          <HotColumn data={'costTypeNumber'} readOnly={true} className='htLeft' />
          <HotColumn data={'description'} readOnly={true} className='htLeft' />
          <HotColumn
            data={'categoryName'}
            type='dropdown'
            source={catOptions.filter((o) => o.isActive).map((o) => o.name)}
            className='htLeft'
          />
          <HotColumn data={'isActive'} type='checkbox' className='htCenter' />
          <HotColumn data={'id'} readOnly={true}>
            <DuplicateButton hot-renderer />
          </HotColumn>
        </RGHotTable>
      )}
    </div>
  );
}

export default ProjectsJobsCategoriesCodes;
