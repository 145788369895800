import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function ReqOutstanding() {
  const colData = {
    widths: [0, 110, 90, 110, 50, 120, 160, 70, 115, 99, 180, 110, 90, 115, 90, 110, 125, 125, 135],
    names: [
      'ID',
      'Project',
      'Job',
      'Req #',
      'Rev<br />#',
      'Category',
      'Description',
      'Priority',
      'Date Submitted',
      'Outstanding<br />Approvals',
      'Reviewers',
      'Requisitioner',
      'Requisition<br />State',
      'Reason for<br />Purchase',
      'Reference',
      'Work Package',
      'Turnover System',
      'Delivery Location',
      'Pressure Retaining<br />Material'
    ]
  };

  const ReqLink = (props) => {
    //open in new window
    let revision = props.TD.parentNode.children[3].innerText;
    return (
      <div style={{ textAlign: 'left' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='#' onClick={() => {
          var url = 'details/' + props.value + '-' + revision;
          window.open(url, url).focus()
        }}>

          {props.value}
        </a>
      </div>
    );
  };

  return (
    <div id='ReqOutstanding'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Outstanding Reviews - My Projects</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'Requisitions/MyPending'}
        colData={colData}
        initialSort={[
          {
            column: 1,
            sortOrder: 'asc'
          },
          {
            column: 2,
            sortOrder: 'asc'
          },
          {
            column: 3,
            sortOrder: 'asc'
          }
        ]}
        headerHeight={2}
        isEditable={false}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'projectNumber'} className='htLeft' />
        <HotColumn data={'jobNumber'} className='htLeft' />
        <HotColumn data={'requisitionNumber'} className='htLeft'>
          <ReqLink hot-renderer />
        </HotColumn>
        <HotColumn data={'revisionNumber'} className='htLeft' />
        <HotColumn data={'categoryName'} className='htLeft' />
        <HotColumn data={'description'} className='htLeft' />
        <HotColumn data={'priority'} className='htLeft' />
        <HotColumn data={'submissionDate'} />
        <HotColumn data={'reviewTypeName'} className='htLeft' />
        <HotColumn data={'reviewers'} className='htLeft' />
        <HotColumn data={'requisitionerName'} className='htLeft' />
        <HotColumn data={'requisitionStateName'} className='htLeft' />
        <HotColumn data={'reasonForPurchase'} className='htLeft' />
        <HotColumn data={'reference'} className='htLeft' />
        <HotColumn data={'workPackage'} className='htLeft' />
        <HotColumn data={'turnoverSystem'} className='htLeft' />
        <HotColumn data={'deliveryLocationName'} className='htLeft' />
        <HotColumn data={'pressureRetainingMaterial'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default ReqOutstanding;
