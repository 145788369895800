import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Upload } from '@progress/kendo-react-upload';

function NewTemplate() {
  const projectDD = ['Project A', 'Project B', 'Project C'];
  const jobDD = ['Job A', 'Job B', 'Job C'];
  const categoryDD = ['Category A', 'Category B', 'Category C'];

  return (
    <div id='NewTemplate'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>New from Import Template</h3>
        </div>
      </div>
      <div className='card-container'>
        <div style={{ textAlign: 'right', marginBottom: '32px', marginRight: '64px' }}>
          <Button>Download Template (CSV)</Button>
        </div>
        <div className='label-container'>
          <div className='label'>Project</div>
          <DropDownList data={projectDD} />
        </div>
        <div className='label-container'>
          <div className='label'>Job</div>
          <DropDownList data={jobDD} />
        </div>
        <div className='label-container'>
          <div className='label'>Category</div>
          <DropDownList data={categoryDD} />
        </div>
        <div className='label-container' style={{ height: '48px' }}>
          <div className='label' style={{ flex: 1 }}>
            File (CSV)
          </div>
          <Upload autoUpload={false} />
        </div>
        <div style={{ textAlign: 'left', padding: '16px 0 0 64px' }}>
          <Button>
            <b>Submit</b>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NewTemplate;
