import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { LoadingCard } from './LoadingCard';
import { RGReviewCard } from "./RGReviewCard";

export const RGReviewCards = ({
    endpoint, // primary API endpoint for the instance
  }) => {
    const [rowData, setRowData] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false); // loaded flag
    const [isLoading, setIsLoading] = useState(false); // loading flag

    const { instance, accounts } = useMsal();

    const getToken = useCallback(async () => {
        let token = null;
        await instance
          .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
          .then((resp) => (token = resp.accessToken))
          .catch(() => {
            // auth has expired; force logout the user
            instance.logoutRedirect().catch((e) => {
              console.error(e);
            });
          });
        return token;
      }, [instance, accounts]);
    
    const fetchData = useCallback(async () => {
        if (isLoaded) return;

        // fetch required cards data
        console.log('Get request sent for:', endpoint);

        let token = await getToken();

        await axios
        .get(
            process.env.REACT_APP_API_URL + endpoint,
            {
            crossDomain: true,
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token
                }
            }
        )
        .then((resp) => {
            console.log('Main data loaded for ' + endpoint + ':', resp.data);

            let tempData = [];
            tempData = resp.data;
        
            //initial test of storing data
            setRowData(tempData);
        })
        .catch((err) => {
            if (err.response && err.response.status === 404) {
              console.log('Main data loaded for ' + endpoint + ' (empty)');
            } else {
              console.error(err);
            }
        });
    
        setIsLoaded(true);
        setIsLoading(false);
    },[endpoint, getToken, isLoaded]); 

    // fetch data if isLoaded and isLoading are both false
    useEffect(() => {
    if (!isLoaded && !isLoading) {
      setIsLoading(true);
      fetchData();
        }
    }, [fetchData, isLoading, isLoaded]);

    function updateCall() {
      //called from RGReviewCard after reject or approve
      console.log('Update Call in Parent');
      setIsLoaded(false); //trigger the above useEffect
    };

    return (
        <React.Fragment>
        {(rowData.length > 0) &&
            rowData.map((cardData) => (
                  <div style={{display: 'inline-block', border: '1px solid black', margin: '10px', padding: '5px', backgroundColor: '#fff', borderRadius: '5px', textAlign: 'center'}}>
                  <RGReviewCard updateCall={updateCall} cardData={cardData}></RGReviewCard>
                  </div>
            ))
        }
        {!isLoaded && <LoadingCard />}
        </React.Fragment>
      );
  };

export default RGReviewCards;