import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

function Home() {
  const [reviewCount, setReviewCount] = useState(null);

  const navigate = useNavigate();

  const { instance, accounts } = useMsal();

  const isMounted = useRef();

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    async function fetchCount() {
      console.log('Get request sent for review count');

      let token = await getToken();

      await axios
        .get(process.env.REACT_APP_API_URL + 'RequisitionReviews/MyCount', {
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
          }
        })
        .then((resp) => {
          console.log('Review count loaded:', resp.data);
          setReviewCount(resp.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    fetchCount();
  }, [reviewCount, getToken]);

  return (
    <div id='Home' className='main-content home-page'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Home</h3>
        </div>
      </div>
      <div className='card-container small-card grid two-grid'>
        <div>
          <Button onClick={() => navigate('/new-scratch')}>Create a Req</Button>
        </div>
        <div>
          <Button onClick={() => navigate('/req-review')}>
            Manage Approvals
            {reviewCount !== null && (
              <>
                <br />(<b>{reviewCount}</b>)
              </>
            )}
          </Button>
        </div>
        <div>
          <Button onClick={() => navigate('/req-mine')}>Manage Existing Req</Button>
        </div>
        <div>
          <Button disabled={true}>
            View Reports
            <br />& Dashboards
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Home;
