import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function LinePurposes() {
  const colData = {
    widths: [0, 210, 70],
    names: ['ID', 'Purpose', 'Active']
  };

  return (
    <div id='LinePurposes'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Line Purposes</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'Purposes'}
        dataSchema={{
          isActive: false
        }}
        isEditable={false}
        colData={colData}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'name'} className='htLeft' />
        <HotColumn data={'isActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default LinePurposes;
