import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';
import { LabelledInput } from '../components/LabelledInput';

function ReqLines() {
  const [selectedProject, setSelectedProject] = useState({});

  const colData = {
    widths: [
      0, 80, 80, 160, 100, 50, 210, 110, 100, 80, 115, 115, 130, 120, 150, 100, 110, 130, 50, 150, 110, 130, 60, 60, 210,
      80, 80, 90, 90, 90, 90, 100, 90, 70, 50, 50, 50
    ],
    names: [
      'ID',
      'Project',
      'Job',
      'Category',
      'Req #',
      'Rev #',
      'Description',
      'Requisitioner',
      'Requisition<br />State',
      'Priority',
      'Date Submitted',
      'Date Approved',
      'Delivery Location',
      'Pressure Retaining<br />Material',
      'Reason for Purchase',
      'Reference',
      'Work Package',
      'Turnover System',
      'Line<br />#',
      'Line Purpose',
      'Part Number',
      'Commodity Code',
      'Size 1',
      'Size 2',
      'Line<br />Description',
      'Quantity',
      'UoM',
      'Cost Code',
      'Cost Type',
      'RAS Date',
      'End Date',
      'Notes To/From<br />Procurement',
      'R Number',
      'PO<br />Number',
      'PO<br />Line',
      'PO<br />QTY',
      'PO<br />Rev'
    ]
  };

  const ReqLink = (props) => {
    let revision = props.TD.parentNode.children[4].innerText;
    return (
      <div style={{ textAlign: 'left' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='#' onClick={() => {
          var url = 'details/' + props.value + '-' + revision;
          window.open(url, url).focus()
        }}>
          {props.value}
        </a>
      </div>
    );
  };

  const styles = `
  .handsontable th{
    height: 50px;
  }
  .handsontable .changeType{
    margin-top: 30px;
  }
  `;

  return (
    <>
      <style>{styles}</style>
      <div id='ReqLines'>
        <div>
          <div className='card-container title-card'>
            <h3 className='page-title'>Requisitions - Lines</h3>
          </div>
        </div>
        <div className='card-container'>
          <div className='card-component'>
            <LabelledInput
              type={'dropdown'}
              input={{ set: setSelectedProject, value: selectedProject }}
              label={'Project'}
              endpoint={'Projects'}
              field={'dropdownDisplayName'}
              activeOnly={true}
            />
          </div>
        </div>
        {selectedProject.id && (
          <RGHotTable
            endpoint={'Lines/RequisitionSummary'}
            colData={colData}
            projectId={selectedProject.id}
            initialSort={[
              {
                column: 1,
                sortOrder: 'asc'
              },
              {
                column: 2,
                sortOrder: 'asc'
              },
              {
                column: 3,
                sortOrder: 'asc'
              }
            ]}
            headerHeight={2}
            isEditable={false}
          >
            <HotColumn data={'id'} />
            <HotColumn data={'projectShortName'} className='htLeft' />
            <HotColumn data={'jobNumber'} className='htLeft' />
            <HotColumn data={'categoryName'} className='htLeft' />
            <HotColumn data={'requisitionNumber'} className='htLeft'>
              <ReqLink hot-renderer />
            </HotColumn>
            <HotColumn data={'revisionNumber'} className='htLeft' />
            <HotColumn data={'requisitionDescription'} className='htLeft' />
            <HotColumn data={'requisitionerName'} className='htLeft' />
            <HotColumn data={'requisitionStateName'} className='htLeft' />
            <HotColumn data={'priority'} className='htLeft' />
            <HotColumn data={'submissionDate'} />
            <HotColumn data={'approvalDate'} />
            <HotColumn data={'deliveryLocationName'} className='htLeft' />
            <HotColumn data={'pressureRetainingMaterial'} type='checkbox' />
            <HotColumn data={'reasonForPurchase'} className='htLeft' />
            <HotColumn data={'reference'} className='htLeft' />
            <HotColumn data={'workPackage'} className='htLeft' />
            <HotColumn data={'turnoverSystem'} className='htLeft' />
            <HotColumn data={'lineNumber'} className='htLeft' />
            <HotColumn data={'linePurpose'} className='htLeft' />
            <HotColumn data={'partNumber'} className='htLeft' />
            <HotColumn data={'commodityCode'} className='htLeft' />
            <HotColumn data={'size1'} className='htLeft' />
            <HotColumn data={'size2'} className='htLeft' />
            <HotColumn data={'lineDescription'} className='htLeft' />
            <HotColumn data={'quantity'} className='htLeft' />
            <HotColumn data={'unitOfMeasure'} className='htLeft' />
            <HotColumn data={'costCode'} className='htLeft' />
            <HotColumn data={'costType'} className='htLeft' />
            <HotColumn data={'rasDate'} />
            <HotColumn data={'endDate'} />
            <HotColumn data={'purchaseDetails'} className='htLeft' />
            <HotColumn data={'rentalNumber'} className='htLeft' />
            <HotColumn data={'poNumber'} className='htLeft' />
            <HotColumn data={'poLine'} className='htLeft' />
            <HotColumn data={'poQuantity'} className='htLeft' />
            <HotColumn data={'poVersion'} className='htLeft' />
          </RGHotTable>
        )}
      </div>
    </>
  );
}

export default ReqLines;
