import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { HotTable } from '@handsontable/react';
import { HotColumn } from '@handsontable/react';
import { Button } from '@progress/kendo-react-buttons';
import { toast } from 'react-toastify';
import { useFilePicker } from 'react-sage';
import { LoadingCard } from './LoadingCard';
import { HOT_LICENSE_KEY } from '../constants';

const FILE_SIZE_LIMIT = 24; // MB

export const AttachmentList = ({
  reqId,
  reqPermissions,
  reqState,
  reqRevision,
  revisionDiff,
  lineData,
  linesLoaded,
  linesSaved,
  resetLines,
  setResetLines,
  setAttachmentsSaved,
  locked,
  setDirty
}) => {
  const { instance, accounts } = useMsal();

  const { files, onClick, errors, HiddenFileInput } = useFilePicker({
    maxFileSize: FILE_SIZE_LIMIT
  });

  const [hotData, setHotData] = useState(null);
  const [attachmentData, setAttachmentData] = useState(null);
  const [initialHotData, setInitialHotData] = useState(null);
  const [hotColumnSettings, setHotColumnSettings] = useState(null); // initial column settings
  const [deletedRows, setDeletedRows] = useState([]);
  const [invalidRows, setInvalidRows] = useState([]);
  const [createdCount, setCreatedCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedReqId, setLoadedReqId] = useState(null);

  const hot = useRef(null);
  let hotInstance;
  if (hot && hot.current) hotInstance = hot.current.hotInstance;

  const hasPermission = (permission) => {
    // p[0] - permission is for this requisitionStateId
    // p[1] - permission is for this lineStateId (unneeded)
    // p[2] - permission is for revision === 0 (true) or revision !=== 0 (false)
    return (
      reqPermissions &&
      reqPermissions[permission] !== undefined &&
      reqPermissions[permission].some((p) => p[0] === reqState && p[2] === (reqRevision === 0))
    );
  };

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  // fetch attachments
  const fetchList = useCallback(async () => {
    console.log('Get request sent for attachments');

    let token = await getToken();

    await axios
      .get(process.env.REACT_APP_API_URL + 'Documents/Requisition/' + reqId, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token
        }
      })
      .then((resp) => {
        setAttachmentData(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getToken, reqId]);
  useEffect(() => {
    if (reqId === -1 || !linesLoaded || isLoading) return;

    if (isLoaded && reqId && reqId !== loadedReqId) {
      setIsLoaded(false);
      setIsLoading(true);
    } else if (!isLoaded && !isLoading && reqId) {
      setIsLoading(true);
    }
  }, [isLoaded, isLoading, linesLoaded, loadedReqId, reqId]);
  useEffect(() => {
    if (reqId && isLoading && lineData) {
      fetchList();
    }
  }, [fetchList, isLoading, lineData, loadedReqId, reqId]);

  const processAttachments = useCallback(() => {
    if (!lineData || !attachmentData) return;

    console.log('Attachments loaded: ', attachmentData);
    let tempHotData = [];
    for (const attachment in attachmentData) {
      let tempAttachment = attachmentData[attachment];
      let tempRow = {};
      let match = lineData.find((l) => l.id === tempAttachment.lineId);
      // there's no strict relationship to Lines, so attachments may be linked to lineID's that don't exist
      if (match) {
        // found matching line
        tempRow.lineNumber = match.lineNumber;
      } else if (tempAttachment.lineId === null) {
        // header level attachment
        tempRow.lineNumber = 0;
      }
      tempRow.id = tempAttachment.id;
      tempRow.lineId = tempAttachment.lineId;
      tempRow.requisitionId = tempAttachment.requisitionId;
      tempRow.fileName = tempAttachment.fileName;
      tempRow.url = process.env.REACT_APP_API_URL + tempAttachment.url;
      tempRow.createdBy = tempAttachment.createdBy;
      tempRow.rowVersion = tempAttachment.rowVersion;

      tempHotData.push(tempRow);
    }
    setHotData(tempHotData);
    setInitialHotData(JSON.parse(JSON.stringify(tempHotData))); // deepclone initial data
    setIsLoaded(true);
    setIsLoading(false);
    setLoadedReqId(reqId);
  }, [attachmentData, lineData, reqId]);
  // watch for attachmentData to change for calling processAttachments
  useEffect(() => {
    processAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentData]);

  // when Lines are reset, reset attachments as well
  useEffect(() => {
    if (resetLines) {
      // Lines table has just been reset
      setResetLines(false);
      setIsLoaded(false);
      setIsLoading(true);
      setLoadedReqId(null);
      setDeletedRows([]);
      setCreatedCount(0);
    }
  }, [lineData, linesLoaded, setResetLines, resetLines]);

  // save attachments
  const save = useCallback(async () => {
    // console.log(hotData, lineData);

    let tempHotData = Array.from(hotData);
    let tempInvalidRows = [];

    // to hold any errors encountered
    let tempErrors = [];

    // find any newly created rows, and set their actual ID's
    for (const row in tempHotData) {
      let tempRow = tempHotData[row];
      if (typeof tempRow.lineId === 'string' && tempRow.lineId.includes('N')) {
        // row is a string and includes 'N' marker, thus is newly created
        tempRow.lineId = lineData.find((l) => l.lineNumber === tempRow.lineNumber).id;
      }
    }

    // save new and updated attachments
    for (const row in tempHotData) {
      let tempRow = tempHotData[row];

      if (typeof tempRow.id === 'string' && tempRow.id.includes('N')) {
        // this is a new attachment

        let token = await getToken();

        // send POST request
        console.log('Create request sent for:', tempRow);
        let tempFormData = new FormData();
        tempFormData.append('file', tempRow.fileData);
        await axios
          .post(
            process.env.REACT_APP_API_URL +
              (tempRow.lineId ? 'Documents/Line/' + tempRow.lineId : 'Documents/Requisition/' + reqId),
            tempFormData,
            {
              crossDomain: true,
              headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then((resp) => {
            console.log(resp);
            // update row with new fields
            tempRow.id = resp.data.id;
            tempRow.requisitionId = resp.data.requisitionId;
            tempRow.fileName = resp.data.fileName;
            tempRow.url = process.env.REACT_APP_API_URL + resp.data.url;
            tempRow.createdBy = resp.data.createdBy;
            tempRow.rowVersion = resp.data.rowVersion;
          })
          .catch((err) => {
            console.error(err, tempRow);
            tempInvalidRows.push(tempRow.id);

            // save errors
            if (typeof err.response.data === 'string') {
              // string error
              tempErrors.push(err.response.data);
            } else if (err.response.data.length > 0) {
              // array of string errors
              for (const e in err.response.data) {
                tempErrors.push(err.response.data[e]);
              }
            } else if (err.response.data.errors) {
              // array or object of string errors
              for (const e in err.response.data.errors) {
                tempErrors.push(err.response.data.errors[e]);
              }
            }
          });
      } else {
        // this is an existing attachment, possibly modified

        // check if it's modified and needs an update
        let match = initialHotData.find((r) => r.id === tempRow.id);
        if (match.lineNumber !== tempRow.lineNumber) {
          // modified attachment
          let token = await getToken();
          await axios
            .put(process.env.REACT_APP_API_URL + 'Documents/' + tempRow.id, tempRow, {
              crossDomain: true,
              headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json; charset=utf-8'
              }
            })
            .then((resp) => {
              console.log(resp);
              // update row's rowVersion
              tempRow.rowVersion = resp.data.rowVersion;
            })
            .catch((err) => {
              console.error(err, tempRow);
              tempInvalidRows.push(tempRow.id);

              // save errors
              if (typeof err.response.data === 'string') {
                // string error
                tempErrors.push(err.response.data);
              } else if (err.response.data.length > 0) {
                // array of string errors
                for (const e in err.response.data) {
                  tempErrors.push(err.response.data[e]);
                }
              } else if (err.response.data.errors) {
                // array or object of string errors
                for (const e in err.response.data.errors) {
                  tempErrors.push(err.response.data.errors[e]);
                }
              }
            });
        }
      }
    }

    // send requests for deleted attachments
    let tempDeletedRows = Array.from(deletedRows);
    for (const row of deletedRows) {
      let tempId = row.id;

      // if this was a new attachment (not saved), we don't need to send it for deletion
      if (typeof tempId === 'string' && tempId.includes('N')) continue;

      console.log('Delete request sent for attachment:', tempId);

      let token = await getToken();

      await axios
        .delete(process.env.REACT_APP_API_URL + 'Documents/' + tempId, {
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token
          }
        })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.error(err);

          // save errors
          if (typeof err.response.data === 'string') {
            // string error
            tempErrors.push(err.response.data);
          } else if (err.response.data.length > 0) {
            // array of string errors
            for (const e in err.response.data) {
              tempErrors.push(err.response.data[e]);
            }
          } else if (err.response.data.errors) {
            // array or object of string errors
            for (const e in err.response.data.errors) {
              tempErrors.push(err.response.data.errors[e]);
            }
          }
        });

      // remove row from deletedRows
      tempDeletedRows.splice(
        tempDeletedRows.findIndex((r) => r.id === tempId),
        1
      );
    }

    setAttachmentsSaved(true);
    setDeletedRows(tempDeletedRows);
    setHotData(tempHotData);
    setInitialHotData(JSON.parse(JSON.stringify(tempHotData))); // deepclone new initial data
    setInvalidRows(tempInvalidRows);

    if (tempInvalidRows.length > 0) {
      toast.error(
        <span style={{ lineHeight: '1.5em' }}>
          One or more attachments were unable to be saved. Attachments with issues will be highlighted. The following error(s) occurred:{' '}
          <ul style={{ paddingLeft: '1em' }}>
            {tempErrors.map((e, idx) => (
              <li key={idx}>{e}</li>
            ))}
          </ul>
        </span>,
        {
          autoClose: false,
          closeOnClick: false,
          theme: 'colored'
        }
      );
    } else {
      toast.success('Attachments saved successfully.', {
        autoClose: 2500,
        closeOnClick: false
      });
    }
  }, [deletedRows, getToken, hotData, initialHotData, lineData, reqId, setAttachmentsSaved]);
  // effect ensures lineData has a chance to update before saving, to get actual lineID's of new lines
  useEffect(() => {
    if (linesSaved) {
      save();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linesSaved]);

  // dirty checking
  useEffect(() => {
    if (!hotData || !initialHotData || !isLoaded) return;

    const dirty =
      hotData
        .filter(
          (r) =>
            !initialHotData.some(
              (o) =>
                r.id === o.id &&
                r.lineId === o.lineId &&
                r.lineNumber === o.lineNumber &&
                r.fileName === o.fileName &&
                r.url === o.url
            )
        )
        .concat(
          initialHotData.filter(
            (r) =>
              !hotData.some(
                (o) =>
                  r.id === o.id &&
                  r.lineId === o.lineId &&
                  r.lineNumber === o.lineNumber &&
                  r.fileName === o.fileName &&
                  r.url === o.url
              )
          )
        ).length > 0;

    setDirty && setDirty(dirty);
  }, [hotData, initialHotData, isLoaded, setDirty]);

  // modify attachments
  const afterChange = (changes) => {
    if (!changes) return;

    let [row, prop, , newValue] = changes[0];

    if (prop === 'lineNumber') {
      // update associated line ID using lineData array
      let tempHotData = Array.from(hotData);
      let tempRow = tempHotData[row];

      if (newValue === 0) {
        // header level attachment
        tempRow.lineId = null;
      } else {
        // line level attachment
        tempRow.lineId = lineData.find((l) => l.lineNumber === newValue).id;
      }

      setHotData(tempHotData);
    }
  };

  const deleteAttachment = useCallback(
    (id, orphan) => {
      let index = hotData.findIndex((r) => r.id === id);

      // add attachment to deleted rows
      let tempDeletedRows = deletedRows;
      tempDeletedRows.push({ ...hotData[index], orphan });
      setDeletedRows(tempDeletedRows);

      // remove row from hotData
      let tempHotData = Array.from(hotData);
      tempHotData.splice(index, 1);
      setHotData(tempHotData);
    },
    [deletedRows, hotData]
  );

  // secure download
  const downloadAttachment = useCallback(
    async (docId, filename) => {
      console.log('Downloading attachment: ' + filename);

      let token = await getToken();

      await axios
        .get(process.env.REACT_APP_API_URL + 'Documents/Content/' + docId, {
          crossDomain: true,
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + token,
            'Content-Disposition': 'attachment'
          },
          responseType: 'blob'
        })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fixFilename(filename)); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    [getToken]
  );

  // adding attachments
  React.useEffect(() => {
    const addAttachments = async () => {
      if (!files || files.length === 0) return;

      // clone files array
      let tempFiles = Array.from(files);

      // check for errors, remove invalid files
      if (errors.hasInvalidFileSize) {
        console.error(errors);
        let invalidCount = 0;
        for (const file in files) {
          let tempFile = files[file];
          if (tempFile.size > FILE_SIZE_LIMIT * 1000000) {
            // per useFilePicker, 1MB = 1,000,000B
            // over-sized, remove from files and data arrays
            invalidCount += 1;
            tempFiles.splice(
              tempFiles.findIndex((f) => f.name === tempFile.name),
              1
            );
          }
        }
        if (invalidCount > 0) {
          // should always be true, but just in case
          toast.info(
            invalidCount +
              ' attachment' +
              (invalidCount === 1 ? ' was' : 's were') +
              ' over the size limit of 10MB, and ' +
              (invalidCount === 1 ? 'was' : 'were') +
              ' not added.',
            {
              autoClose: false,
              closeOnClick: false
            }
          );
        }
      }

      // update hotData with new files
      let tempHotData = Array.from(hotData);
      for (const file in tempFiles) {
        let tempFile = tempFiles[file];
        let tempRow = {};

        tempRow.id = 'N' + (createdCount + parseInt(file));
        tempRow.lineId = null;
        tempRow.fileName = tempFile.name;
        tempRow.lineNumber = 0;
        tempRow.fileData = tempFile;
        console.log('Attachment added:', tempRow);

        tempHotData.push(tempRow);
      }
      setHotData(tempHotData);

      // update counter
      setCreatedCount(createdCount + files.length);

      // clear files
      files.length = 0;
    };

    addAttachments();
  }, [createdCount, errors, files, hotData]);

  // watch for deleted/undeleted lines, and modify attachments accordingly
  useEffect(() => {
    if (!linesLoaded || linesSaved) return; // wait until lines are loaded, or until lines are saved (if saving)
    if (lineData && hotData && hotData.length > 0) {
      // at least one attachment currently exists

      // delete orphaned attachments
      for (const row of hotData) {
        if (row.lineId === null) continue; // header level attachment
        if (lineData.findIndex((l) => l.id === row.lineId) === -1) {
          // related line is deleted - delete this attachment
          deleteAttachment(row.id, true);
        }
      }

      // undelete attachments if parent line is undeleted
      for (const line of lineData) {
        let index = deletedRows.findIndex((r) => r.lineId === line.id && r.orphan);
        if (index > -1) {
          let row = deletedRows[index];

          setHotData((rows) => rows.concat(row));
          setDeletedRows((rows) => rows.filter((r) => r.id !== row.id));
        }
      }
    }
  }, [deleteAttachment, deletedRows, hotData, lineData, linesLoaded, linesSaved]);

  // set column settings after table is loaded
  useEffect(() => {
    if (hotInstance && !hotColumnSettings) {
      try {
        let hotSettings = hotInstance.getSettings();
        if (hotSettings && hotSettings.columns && hotSettings.columns.length > 0)
          setHotColumnSettings(hotSettings.columns);
      } catch (e) {
        console.error(e);
      }
    }
  }, [hotColumnSettings, hotInstance, hotData]);

  const fixFilename = (rawFilename) => {
    return rawFilename.slice(rawFilename.lastIndexOf('/') + 1);
  };

  const DeleteButton = (props) => {
    let allowed = false;
    if (hotData[props.row]) {
      if (hotData[props.row].lineNumber && hotData[props.row].lineNumber > 0) {
        // note: does not currently account for line state
        allowed = hasPermission('Line.Document.Remove');
      } else {
        allowed = hasPermission('Requisition.Document.Remove');
      }
    }

    return (
      <Button
        onClick={() => deleteAttachment(props.value, false)}
        disabled={locked || !allowed}
        className='delete-button'
      >
        &#xD7;
      </Button>
    );
  };

  const AttachmentLink = (props) => {
    if (hotData[props.row] && hotData[props.row].url) {
      let changed = revisionDiff ? revisionDiff.newDocumentIds?.includes(hotData[props.row].id) : false;
      return (
        <div style={{ textAlign: 'left' }}>
          <button
            onClick={() => downloadAttachment(hotData[props.row].id, hotData[props.row].fileName)}
            className={'link-button' + (changed ? " changed" : '')}
          >
            {fixFilename(props.value)}
          </button>
        </div>
      );
    } else {
      return <div style={{ textAlign: 'left' }}>{props.value}</div>;
    }
  };

  return (
    <>
      <div className='label-comment-container' style={{ width: '500px ' }}>
        <div className={'label comment-label'}>Attachments</div>
        {!isLoaded || isLoading ? (
          <LoadingCard mini={true} />
        ) : (
          <>
            <Button
              style={{ width: 30, float: 'right' }}
              onClick={onClick}
              disabled={locked || !hasPermission('Requisition.Document.Add')}
            >
              +
            </Button>
            <HiddenFileInput multiple={true} />
            <HotTable
              data={hotData}
              ref={hot}
              readOnly={locked}
              cells={(row, col, prop) => {
                if (!hotData) return;

                let cellProperties = {};

                // get table/row data ready in case changes are needed
                let tempHotData = Array.from(hotData);
                let tempRow = tempHotData[row];

                if (!tempRow) return;

                // lock cells based on permissions
                if (prop === 'lineNumber' && !hasPermission('Requisition.Document.Add')) {
                  cellProperties.readOnly = true;
                }

                // highlight invalid rows
                let invalid = invalidRows.findIndex((r) => r === tempRow.id) > -1;
                if (invalid) {
                  cellProperties.className = hotColumnSettings[col].className + ' invalid';
                }

                // bold new documents compared to previous revision
                if (revisionDiff && revisionDiff.newDocumentIds?.length > 0) {
                  if (revisionDiff.newDocumentIds.includes(tempRow.id)) {
                    // row is new - bold
                    cellProperties.className =
                    (cellProperties.className ? cellProperties.className + ' ' : '') +
                    (hotColumnSettings ? hotColumnSettings[col].className + ' ' : '') +
                    'htLeft changed';
                  }
                }

                return cellProperties;
              }}
              afterChange={(changes) => afterChange(changes)}
              dataSchema={{
                selected: false
              }}
              width={500}
              height={280}
              colWidths={[24, 46, 220, 210]}
              colHeaders={['', 'Line #', 'File', 'Created By']}
              autoRowSize={true}
              licenseKey={HOT_LICENSE_KEY}
            >
              <HotColumn data={'id'} readOnly={true}>
                <DeleteButton hot-renderer />
              </HotColumn>
              <HotColumn
                data={'lineNumber'}
                type={'dropdown'}
                source={lineData ? [0, ...lineData.map((l) => l.lineNumber)] : [0]}
                className={'htLeft'}
              />
              <HotColumn data={'fileName'} className={'htLeft'} readOnly={true}>
                <AttachmentLink hot-renderer />
              </HotColumn>
              <HotColumn data={'createdBy'} className={'htLeft'} readOnly={true} />
            </HotTable>
          </>
        )}
      </div>
    </>
  );
};
