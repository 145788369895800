import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function ReasonForPurchase() {
  const colData = {
    widths: [0, 260, 70],
    names: ['ID', 'Reason for Purchase', 'Active']
  };

  return (
    <div id='ReasonForPurchase'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Reason for Purchase</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'ReasonForPurchases'}
        colData={colData}
        editPermission={'ReasonForPurchase.Edit'}
        dataSchema={{
          isActive: false
        }}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'name'} className='htLeft' />
        <HotColumn data={'isActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default ReasonForPurchase;
