import React, { useState, useCallback } from 'react';
import { LabelledInput } from './LabelledInput';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { toast } from 'react-toastify';

export const RGReviewCard = (props) => {
    const { updateCall, cardData } = props;

    const [feedback, setFeedback] = useState(null);

    const screenWidth = window.innerWidth * 0.9;
    const { instance, accounts } = useMsal();

    const getToken = useCallback(async () => {
        let token = null;
        await instance
          .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
          .then((resp) => (token = resp.accessToken))
          .catch(() => {
            // auth has expired; force logout the user
            instance.logoutRedirect().catch((e) => {
              console.error(e);
            });
          });
        return token;
      }, [instance, accounts]);

    const submitReview = async (id, approve, comment) => {
        // first, we have to fetch the review
        console.log('Get request sent for review ' + id);
        let token = await getToken();
        let modReview;
    
        let reviewExists = true;
        await axios
          .get(process.env.REACT_APP_API_URL + 'RequisitionReviews/' + id, {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + token
            }
          })
          .then((resp) => {
            console.log('Review loaded: ', resp.data);
            modReview = resp.data;
          })
          .catch((err) => {
            console.error(err);
    
            reviewExists = false;
    
            toast.error(
              'Review unsuccessful. It is likely that this requisition was already rejected by another user since you opened this page. Please refresh to see the latest available reviews.',
              {
                autoClose: false,
                closeOnClick: false,
                theme: 'colored',
                position: 'bottom-center'
              }
            );
          });
    
        if (!reviewExists) return;
    
        // now we update the review
        modReview.isApproved = approve;
        modReview.comments = comment;
    
        if (!approve && !modReview.comments) {
          console.log('Comment required');
          toast.warning('You must enter feedback when rejecting a requisition review.', {
            autoClose: 5000,
            closeOnClick: false,
            theme: 'colored',
            position: 'bottom-center'
          });
          return;
        }
    
        console.log('Put request sent for review', modReview);
    
        // send PUT request
        await axios
          .put(process.env.REACT_APP_API_URL + 'RequisitionReviews/' + id, modReview, {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json; charset=utf-8'
            }
          })
          .then((resp) => {
            console.log(resp);
    
            toast.success('Requisition review submitted.', {
              autoClose: 2500,
              closeOnClick: false,
              position: 'bottom-center'
            });
          })
          .catch((err) => {
            console.error(err);
    
            let errorList = [];
    
            if (typeof err.response.data === 'string') {
              // string error
              errorList.push(err.response.data);
            } else if (err.response.data.length > 0) {
              // array of string errors
              for (const e in err.response.data) {
                errorList.push(err.response.data[e]);
              }
            } else if (err.response.data.errors) {
              // array or object of string errors
              for (const e in err.response.data.errors) {
                errorList.push(err.response.data.errors[e]);
              }
            }
    
            errorList = errorList.map((e, idx) => <li key={idx}>{e}</li>);
    
            toast.error(
              <div>
                The review was unable to be submitted due to the following error(s):
                <ul style={{ paddingLeft: '1em' }}>{errorList}</ul>
              </div>,
              {
                autoClose: false,
                closeOnClick: false,
                theme: 'colored',
                position: 'bottom-center'
              }
            );
          });
    
        // reload cards to fetch any changes
        setFeedback(''); //clear on this card before it is re-used
        updateCall();
      };


      const ReqLink = () => {
        return (
          <div style={{ textAlign: 'left', padding: '5px 0px'}}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href='#' onClick={() => {
              var url = 'details/' + cardData.requisitionNumber + '-' + cardData.revisionNumber;
              window.open(url, url).focus()
          }}>
            {cardData.requisitionNumber}
          </a>
          </div>
        );
      };

    return (
        <React.Fragment>
            <LabelledInput
                width={screenWidth}
                type={'textbox'}
                input={{ set: null, value: cardData.projectNumber }}
                label={'Project'}
                locked={true}
              />
            <LabelledInput
                width={screenWidth}
                type={'textbox'}
                input={{ set: null, value: cardData.jobNumber }}
                label={'Job'}
                locked={true}
              />
            <LabelledInput
                width={screenWidth}
                type={'textbox'}
                input={{ set: null, value: cardData.categoryName }}
                label={'Category'}
                locked={true}
              />
            <LabelledInput
                width={screenWidth}
                type={'textbox'}
                input={{ set: null, value: cardData.priority }}
                label={'Priority'}
                locked={true}
              />

            <div className={'label-container'} style={screenWidth && { width: screenWidth, minWidth: 'unset' }}>
            <div className={'label'}>Requisition #</div>
            <ReqLink /> 
            </div>

            <LabelledInput
                width={screenWidth}
                type={'textbox'}
                input={{ set: null, value: cardData.revisionNumber }}
                label={'Rev #'}
                locked={true}
              />
            <LabelledInput
                width={screenWidth}
                type={'textbox'}
                input={{ set: null, value: cardData.reviewTypeName }}
                label={'Review Type'}
                locked={true}
              />
            <LabelledInput
                width={screenWidth}
                type={'textbox'}
                input={{ set: null, value: cardData.submissionDate }}
                label={'Date Submitted'}
                locked={true}
              />
            <LabelledInput
                width={screenWidth}
                type={'textarea'}
                input={{ set: setFeedback, value: feedback }}
                label={'Review Feedback'}
                locked={false}
              />
            <br />
                <Button className='review-button reject-button' style={{ width: '160px', height: '40px', marginRight: '10px' }} onClick={() => submitReview(cardData.requisitionReviewId, false, feedback)}>
                    Reject
                </Button>
                <Button className='review-button approve-button' style={{ width: '160px', height: '40px', marginLeft : '10px' }} onClick={() => submitReview(cardData.requisitionReviewId, true, feedback)}>
                    Approve
                </Button>
            
            <br />
        </React.Fragment>
    )
  };

  export default RGReviewCard;