import React, { useState } from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';
import { LabelledInput } from '../components/LabelledInput';

function CommodityCodes() {
  const [selectedProject, setSelectedProject] = useState({});

  const colData = {
    widths: [0, 150, 150, 310, 65, 65, 90, 210, 210, 210, 70],
    names: [
      'ID',
      'IDENT*',
      'Commodity Code*',
      'Description*',
      'Size 1*',
      'Size 2*',
      'UoM*',
      'Description 1',
      'Description 2',
      'Description 3',
      'Active'
    ]
  };

  // dropdown options
  const [uomOptions, setUomOptions] = useState([]);
  // collection of API endpoints, property names, data, setData, and whether to fetch only by selected project for each dropdown
  const dropdowns = [['UnitOfMeasures', 'unitOfMeasure', uomOptions, setUomOptions, false]];

  return (
    <div id='CommodityCodes'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects & Commodity Codes</h3>
        </div>
      </div>
      <div className='card-container'>
        <div className='card-component'>
          <LabelledInput
            type={'dropdown'}
            input={{ set: setSelectedProject, value: selectedProject }}
            label={'Project'}
            endpoint={'Projects'}
            field={'dropdownDisplayName'}
            activeOnly={true}
            isAdminPageFilter={true}
          />
        </div>
      </div>
      {!selectedProject.id ? (
        <></>
      ) : (
        <RGHotTable
          endpoint={'CommodityCodes'}
          colData={colData}
          editPermission={'CommodityCode.Edit'}
          dataSchema={{
            isActive: false
          }}
          dropdowns={dropdowns}
          isEditable={selectedProject.jdeProject.isActive}
          projectId={selectedProject.id}
          key={selectedProject.id}
        >
          <HotColumn data={'id'} />
          <HotColumn data={'identCode'} className='htLeft' />
          <HotColumn data={'commodityCode1'} className='htLeft' />
          <HotColumn data={'description'} className='htLeft' />
          <HotColumn data={'size1'} type='numeric' />
          <HotColumn data={'size2'} type='numeric' />
          <HotColumn
            data={'unitOfMeasure.unit'}
            type='dropdown'
            source={uomOptions.filter((o) => o.isActive).map((o) => o.unit)}
            className='htLeft'
          />
          <HotColumn data={'description1'} className='htLeft' readOnly={true} />
          <HotColumn data={'description2'} className='htLeft' readOnly={true} />
          <HotColumn data={'description3'} className='htLeft' readOnly={true} />
          <HotColumn data={'isActive'} type='checkbox' className='htCenter' />
        </RGHotTable>
      )}
    </div>
  );
}

export default CommodityCodes;
