import React from 'react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from '../components/RGHotTable';

function ProjectsSharedMailboxes() {
  const colData = {
    widths: [0, 190, 190, 190, 240, 140],
    names: [
      'ID',
      'JDE Project Number',
      'ReqGen Project Description',
      'ReqGen Project Short Name',
      'Mailboxes* (comma separated)',
      'Notifications Active'
    ]
  };

  return (
    <div id='ProjectsSharedMailboxes'>
      <div>
        <div className='card-container title-card'>
          <h3 className='page-title'>Projects & Shared Mailboxes</h3>
        </div>
      </div>
      <RGHotTable
        endpoint={'Projects'}
        colData={colData}
        addRemoveRows={false}
        editPermission={'Project.SharedMailbox.Edit'}
        activeProperty={'.'}
        constraints={[{ required: ['sharedEmail'], reliant: ['isNotificationActive'] }]}
      >
        <HotColumn data={'id'} />
        <HotColumn data={'jdeProject.project'} className='htLeft' readOnly={true} />
        <HotColumn data={'description'} className='htLeft' readOnly={true} />
        <HotColumn data={'shortName'} className='htLeft' readOnly={true} />
        <HotColumn data={'sharedEmail'} className='htLeft' />
        <HotColumn data={'isNotificationActive'} type='checkbox' />
      </RGHotTable>
    </div>
  );
}

export default ProjectsSharedMailboxes;
