import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { HotTable } from '@handsontable/react';
import { HotColumn } from '@handsontable/react';
import { RGHotTable } from './RGHotTable';
import { Button } from '@progress/kendo-react-buttons';
import { LabelledInput } from './LabelledInput';
import { LoadingCard } from './LoadingCard';

export const AuditTrail = ({ reqNum }) => {
  const { instance, accounts } = useMsal();

  const colData = {
    widths: [0, 140, 250, 250, 200, 140],
    names: ['ID', 'Date Time', 'User', 'Summary', 'Snapshot', 'Changes']
  };

  const [externalHotData, setExternalHotData] = useState(null);
  const [modalTable, setModalTable] = useState(null);
  const [revisions, setRevisions] = useState(null);
  const [selectedRevision, setSelectedRevision] = useState(null);
  const [externalReset, setExternalReset] = useState(false);

  const LocalDateTime = (props) => {
    let modValue = props.value;

    if (typeof modValue === 'string' && modValue.includes(':') && Date.parse(modValue)) {
      // convert UTC time from API to user's local time and format
      let tempDate = new Date(Date.parse(modValue + (props.prop !== 'value' ? ' UTC' : '')));

      let dd = String(tempDate.getDate()).padStart(2, '0');
      let MM = String(tempDate.getMonth() + 1).padStart(2, '0');
      let yyyy = tempDate.getFullYear();
      let hh = String(tempDate.getHours()).padStart(2, '0');
      let mm = String(tempDate.getMinutes()).padStart(2, '0');
      let ss = String(tempDate.getSeconds()).padStart(2, '0');

      modValue =
        yyyy + '-' + MM + '-' + dd + ' ' + (hh > 12 ? hh - 12 : hh) + ':' + mm + ':' + ss + (hh > 12 ? ' PM' : ' AM');
    }

    return <div style={{ textAlign: 'left' }}>{modValue}</div>;
  };

  const getToken = useCallback(async () => {
    let token = null;
    await instance
      .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
      .then((resp) => (token = resp.accessToken))
      .catch(() => {
        // auth has expired; force logout the user
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
      });
    return token;
  }, [instance, accounts]);

  const createTable = async (type, url) => {
    // type: 1 = Snapshot, 2 = Changes

    // set loading modal
    setModalTable(<LoadingCard />);

    // fetch data
    console.log('Get request sent for ' + url);

    let token = await getToken();

    let data = [];
    await axios
      .get(process.env.REACT_APP_API_URL + url, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token
        }
      })
      .then((resp) => {
        console.log('Data loaded for ' + url + ': ', resp.data);

        data = resp.data;
      })
      .catch((err) => {
        console.error(err);
      });

    // create table
    let table;

    if (type === 1) {
      table = (
        <HotTable
          data={data}
          colWidths={[180, 220]}
          colHeaders={['Property Name', 'Property Value']}
          readOnly={true}
          width={400}
          height={window.innerHeight * 0.8 - 10}
          licenseKey='6756b-d5bca-7c732-74c21-79546'
        >
          <HotColumn data={'key'} className={'htLeft'} />
          <HotColumn data={'value'} className={'htLeft'}>
            <LocalDateTime hot-renderer />
          </HotColumn>
        </HotTable>
      );
    } else if (type === 2) {
      table = (
        <HotTable
          data={data}
          colWidths={[180, 210, 210]}
          colHeaders={['Property', 'Previous Value', 'Updated Value']}
          readOnly={true}
          width={600}
          height={window.innerHeight * 0.8 - 10}
          licenseKey='6756b-d5bca-7c732-74c21-79546'
        >
          <HotColumn data={'fieldName'} className={'htLeft'} />
          <HotColumn data={'oldValue'} className={'htLeft'}>
            <LocalDateTime hot-renderer />
          </HotColumn>
          <HotColumn data={'newValue'} className={'htLeft'}>
            <LocalDateTime hot-renderer />
          </HotColumn>
        </HotTable>
      );
    }

    setModalTable(table);
  };

  // fetch revision count
  const fetchRevisions = useCallback(async () => {
    console.log('Get request sent for Revision Count', reqNum);

    let token = await getToken();

    await axios
      .get(process.env.REACT_APP_API_URL + 'Requisitions/revisions/' + reqNum, {
        crossDomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + token
        }
      })
      .then((resp) => {
        console.log('Revisions loaded: ', resp);
        setRevisions(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [getToken, reqNum]);
  useEffect(() => {
    if (revisions === null && reqNum !== null) fetchRevisions();
  }, [fetchRevisions, reqNum, revisions]);
  // reset table when new revision selected
  useEffect(() => {
    setExternalReset(true);
  }, [selectedRevision]);
  // reset externalReset
  useEffect(() => {
    if (externalReset) setExternalReset(false);
  }, [externalReset]);

  const SnapshotLink = (props) => {
    return ModalLink(props, 1);
  };
  const ChangesLink = (props) => {
    return ModalLink(props, 2);
  };
  const ModalLink = (props, type) => {
    if (!externalHotData) return;
    let idx = props.cellProperties.row;
    let url = type === 1 ? externalHotData[idx].link1Url : externalHotData[idx].link2Url;

    return (
      <div style={{ textAlign: 'left' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href='#' onClick={() => createTable(type, url)}>
          {props.value}
        </a>
      </div>
    );
  };

  return (
    <>
      {revisions !== null ? (
        <LabelledInput
          type='dropdown'
          label='Revision'
          options={revisions}
          input={{ set: setSelectedRevision, value: selectedRevision }}
          width='280px'
        />
      ) : (
        <LoadingCard mini={true} />
      )}
      {modalTable && (
        <div className='modal' onClick={() => setModalTable(null)}>
          <div className='modal-card' onClick={(e) => e.stopPropagation()}>
            <Button className='modal-close' onClick={() => setModalTable(null)}>
              &#xD7;
            </Button>
            {modalTable}
          </div>
        </div>
      )}
      {selectedRevision !== null && (
        <RGHotTable
          endpoint={'Requisitions/history'}
          colData={colData}
          reqRevNum={reqNum + '-' + selectedRevision}
          convertDateProps={['eventTime']}
          initialSort={[
            {
              column: 1,
              sortOrder: 'desc'
            }
          ]}
          isEditable={false}
          setExternalHotData={setExternalHotData}
          externalReset={externalReset}
        >
          <HotColumn data={'id'} />
          <HotColumn data={'eventTime'} />
          <HotColumn data={'userEmail'} className='htLeft' />
          <HotColumn data={'eventDescription'} className='htLeft' />
          <HotColumn data={'link1Text'} className='htLeft'>
            <SnapshotLink hot-renderer />
          </HotColumn>
          <HotColumn data={'link2Text'} className='htLeft'>
            <ChangesLink hot-renderer />
          </HotColumn>
        </RGHotTable>
      )}
    </>
  );
};
