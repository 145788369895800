import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DrawerRouterContainer from './components/DrawerRouterContainer.js';

import Home from './pages/Home';
import NotificationSettings from './pages/NotificationSettings';
import NewScratch from './pages/NewScratch';
import NewClone from './pages/NewClone';
import NewTemplate from './pages/NewTemplate';
import ReqMine from './pages/ReqMine';
import ReqOutstanding from './pages/ReqOutstanding';
import ReqReview from './pages/ReqReview';
import ReqLines from './pages/ReqLines';
import ReqAll from './pages/ReqAll';
import Users from './pages/Users';
import Projects from './pages/Projects';
import ProjectsJobs from './pages/ProjectsJobs';
import ProjectsCategories from './pages/ProjectsCategories';
import ProjectsDeliveryLocations from './pages/ProjectsDeliveryLocations';
import ProjectsJobsCategoriesCodes from './pages/ProjectsJobsCategoriesCodes';
import ProjectsUsersRolesNotifications from './pages/ProjectsUsersRolesNotifications';
import ProjectsCategoriesReviewers from './pages/ProjectsCategoriesReviewers';
import LinePurposes from './pages/LinePurposes';
import LinePurposeConstraints from './pages/LinePurposeConstraints';
import ProjectsSharedMailboxes from './pages/ProjectsSharedMailboxes';
import CommodityCodes from './pages/CommodityCodes';
import UoM from './pages/UoM';
import ReasonForPurchase from './pages/ReasonForPurchase';
import PressureRetainingMaterialNotes from './pages/PressureRetainingMaterialNotes';
import ReqDetails from './pages/ReqDetails';

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({ config: {
  connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
  extensions: [reactPlugin]
} });
appInsights.loadAppInsights();
appInsights.trackPageView();
appInsights.trackPageViewPerformance({name: "App", uri: window.location.href});

const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <div className='App'>
        <BrowserRouter>
          <DrawerRouterContainer>
            <Routes>
              <Route exact={true} path='/' element={<Home />} />
              <Route exact={true} path='/notification-settings' element={<NotificationSettings />} />
              <Route exact={true} path='/new-scratch' element={<NewScratch />} />
              <Route exact={true} path='/new-clone' element={<NewClone />} />
              <Route exact={true} path='/new-template' element={<NewTemplate />} />
              <Route exact={true} path='/req-mine' element={<ReqMine />} />
              <Route exact={true} path='/req-outstanding' element={<ReqOutstanding />} />
              <Route exact={true} path='/req-review' element={<ReqReview />} />
              <Route exact={true} path='/req-lines' element={<ReqLines />} />
              <Route exact={true} path='/req-all' element={<ReqAll />} />
              <Route exact={true} path='/users' element={<Users />} />
              <Route exact={true} path='/projects' element={<Projects />} />
              <Route exact={true} path='/projects-jobs' element={<ProjectsJobs />} />
              <Route exact={true} path='/projects-categories' element={<ProjectsCategories />} />
              <Route exact={true} path='/projects-delivery-locations' element={<ProjectsDeliveryLocations />} />
              <Route exact={true} path='/projects-jobs-categories-codes' element={<ProjectsJobsCategoriesCodes />} />
              <Route
                exact={true}
                path='/projects-users-roles-notifications'
                element={<ProjectsUsersRolesNotifications />}
              />
              <Route exact={true} path='/projects-categories-reviewers' element={<ProjectsCategoriesReviewers />} />
              <Route exact={true} path='/line-purposes' element={<LinePurposes />} />
              <Route exact={true} path='/line-purpose-constraints' element={<LinePurposeConstraints />} />
              <Route exact={true} path='/projects-shared-mailboxes' element={<ProjectsSharedMailboxes />} />
              <Route exact={true} path='/commodity-codes' element={<CommodityCodes />} />
              <Route exact={true} path='/uom' element={<UoM />} />
              <Route exact={true} path='/reason-for-purchase' element={<ReasonForPurchase />} />
              <Route
                exact={true}
                path='/pressure-retaining-material-notes'
                element={<PressureRetainingMaterialNotes />}
              />
              <Route path='/details/:reqRevNum' element={<ReqDetails />} />
              <Route path='/details/' element={<ReqDetails />} />
            </Routes>
          </DrawerRouterContainer>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
